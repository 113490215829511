import { Empty } from 'antd'
import { useState, cloneElement } from 'react'
//todo  该组件有问题，好像解决不了echarts渲染丢失问题
const EmptyWrapper = (props) => {
  const { children } = props

  const [showEmpty, setShowEmpty] = useState(false)
  //   const childrenWithProps = React.Children.map(props.children, child => {
  //     return cloneElement(child, { setShowEmpty })
  //   })
  if (showEmpty) {
    return <Empty style={{ marginTop: '16px' }}></Empty>
  } else {
    return (
      <div style={{ display: showEmpty ? 'none' : 'block' }}>
        {cloneElement(children, { setShowEmpty })}
      </div>
    )
  }
}
export default EmptyWrapper

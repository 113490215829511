import Card from '../../components/Card'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styles from './index.module.less'
import { Radio, Select, Tabs } from 'antd'
import CrossFilter from '@/components/CrossFilter'
import { getSum1_2, redfeedsum } from '@/api/home'
import CrossPerformance from '@/view/cross/performance'
import TrendPie from './red-trend-pie'
import TrendDivisionAndYearRed from './trendDivisionAndYear-red'
import TrendLineProd from './trend-line-prod'

import RedBarHor from './Red-bar-hor'
import { themecolor, themecolor_pie1, mediaFormatOption } from '@/common/vars'
import RedTable from './RedTable'
import { setDivision } from '@/redux/actions'
const { wrap } = styles

const cardstyle = {
  // marginTop: '16px',
  position: 'relative',
}
const linestyle = {}

const Red = (props) => {
  const onChange = () => {}
  const { currentYear, division, setDivision, unit, yearsopt } = props

  const [months, setmonths] = useState([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ])
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const [years, setyears] = useState([currentYear])
  const [ysval, setysval] = useState(0)
  const [asset, setAsset] = useState(['all'])
  const [assetOpt, setAssetOpt] = useState([])

  // const [division, setDivision] = useState('all')
  const [product, setProduct] = useState(['all'])

  const [productOpt, setProductOpt] = useState([])

  const [axis, setAxis] = useState(['all'])
  const [axisOpt, setAxisOpt] = useState([])

  const [cag, setcag] = useState(['all'])
  const [cagopt, setCagOpt] = useState([])

  const [mediaformat, setMediaFormat] = useState(['all'])
  // const [subcag, setsubcag] = useState(['all'])
  // const [subcagopt, seSubcagopt] = useState([])

  const [productline, setproductline] = useState(['all'])
  const [productlineopt, setproductlineopt] = useState([])

  const [treeopt, setTreeopt] = useState({})

  const handleYSChange = () => {}
  const [updatetime, setupdatetime] = useState('')
  const [cost, setCost] = useState(0)
  const getUpdateTime = () => {
    getSum1_2({
      title: 'updateTime',
      fields: ['maxyearandmonth'],
      groups: ['year'],
      sorts: [{ field: 'maxyearandmonth', type: 'DESC' }],
    }).then((res) => {
      setupdatetime(res[0].maxyearandmonth)
    })
  }
  const handleChange = (val, setfn) => {
    //全部交互需要编写受控逻辑才能完成  全部与其他互斥
    if (!val.length) return setfn(['all'])
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) return setfn(val.slice(1)) //由默认转为其他
    if (index === val.length - 1 && val.length > 0) return setfn(['all']) //由其他转为默认
    return setfn(val)
  }
  const getopt = () => {
    redfeedsum({
      title: '获取筛选项',
      groups: ['categorycorp', 'productline', 'assets', 'media'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years.includes('all') ? yearsopt : years,
        },
      ],
    }).then((res) => {
      const obj = {}
      res.forEach((i) => {
        if (!obj['categorycorp']) {
          obj['categorycorp'] = []
        }
        obj['categorycorp'].push(i.categorycorp)

        if (!obj['productline']) {
          obj['productline'] = []
        }
        obj['productline'].push(i.productline)

        if (!obj['assets']) {
          obj['assets'] = []
        }
        if (i.media === 'Red') {
          obj['assets'].push(i.assets)
        }
      })
      obj['categorycorp'] = Array.from(new Set(obj['categorycorp']))
      obj['productline'] = Array.from(new Set(obj['productline']))
      obj['assets'] = Array.from(new Set(obj['assets']))
      // debugger
      setTreeopt(obj)
      setCagOpt([
        { label: '全部', value: 'all' },
        ...obj.categorycorp
          .filter((i) => {
            return i !== '' && i != '-'
          })
          .map((i) => ({ label: i, value: i })),
      ])
      setproductlineopt([
        { label: '全部', value: 'all' },
        ...obj.productline
          .filter((i) => {
            return i !== '' && i != '-'
          })
          .map((i) => ({ label: i, value: i })),
      ])
      setAssetOpt([
        { label: '全部', value: 'all' },
        ...obj.assets
          .filter((i) => {
            return i !== '' && i != '-'
          })
          .map((i) => ({ label: i, value: i })),
      ])
    })
  }
  const resetting = (val) => {
    setyears([currentYear])
    setmonths(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])
    setAxis(['all'])
    setProduct(['all'])
    setcag(['all'])
  }
  const getOverviewCost = () => {
    // if (!axisOpt.length) return
    if (!treeopt) return
    redfeedsum({
      fields: ['cost'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years.includes('all') ? yearsopt : years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.includes('all')
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            : months.map((i) => map.indexOf(i)),
        },
        {
          field: 'productline',
          operator: 'IN',
          values: productline.includes('all') ? treeopt['productline'] : productline,
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: cag.includes('all') ? treeopt['categorycorp'] : cag,
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: mediaformat.includes('all')
            ? mediaFormatOption.map((it) => {
                return it.value
              })
            : mediaformat,
        },
        // {
        //   field: ' mediaformat',
        //   operator: 'IN',
        //   values: ['GD Feeds', 'RTB Feeds'],
        // },
        {
          field: ' mediagroup ',
          operator: 'IN',
          values: ['Red'],
        },
      ],
    }).then((res) => {
      // debugger
      const cost = res[0]?.cost
      setCost(cost)
    })
  }
  useEffect(() => {
    // getopt('categorycorp', setAxisOpt)
    // getopt('product', setProductOpt)
    getopt()
    getUpdateTime()
  }, [])
  useEffect(() => {
    getOverviewCost()
  }, [years, months, cag, productline, asset, treeopt, mediaformat])

  return (
    <Tabs defaultActiveKey="1" style={{ backgroundColor: '#fff', padding: '0px 12px' }}>
      <Tabs.TabPane tab="         view" key="1">
        <div className={wrap}>
          <CrossFilter
            updatetime={updatetime}
            cost={cost}
            resetting={resetting}
            division={division}
            setDivision={setDivision}
            months={months}
            setmonths={setmonths}
            setyears={setyears}
            years={years}
            amountname="Cost"
            // setProductLine={setProductLine}
          >
            <div className="toolLine">
              <span className="label">Media Format：</span>
              <Select
                maxTagCount={1}
                options={mediaFormatOption}
                value={mediaformat}
                onChange={(val) => handleChange(val, setMediaFormat)}
                style={{ width: '240px' }}
                mode={'multiple'}
              />
            </div>
            <div className="toolLine">
              <span className="label">Category</span>
              <Select
                maxTagCount={1}
                options={cagopt}
                value={cag}
                onChange={(val) => handleChange(val, setcag)}
                style={{ width: '240px' }}
                mode={'multiple'}
              />
            </div>
            <div className="toolLine">
              <span className="label">Product Line：</span>
              <Select
                maxTagCount={1}
                options={productlineopt}
                value={productline}
                onChange={(val) => handleChange(val, setproductline)}
                style={{ width: '240px' }}
                mode={'multiple'}
              />
            </div>
          </CrossFilter>
          <Card title="Cost" style={cardstyle} headerLine={true}>
            <div className="line" style={linestyle}>
              <div className="line-1-left">
                <TrendPie
                  yname="assettype"
                  unit={unit}
                  title="Cost By Asset Type"
                  division={division}
                  yearsopt={yearsopt}
                  years={years}
                  subcag={productline}
                  mediaformat={mediaformat}
                  cag={cag}
                  treeopt={treeopt}
                  months={months}
                  dataViewHeaderFirstTitle="Asset type"
                  // argsfn={argscreator(pieconfig1)}
                  color={themecolor_pie1}
                  // color1={themecolor_pie11}
                ></TrendPie>
              </div>
              <div className="line-1-right">
                {/* <Card title="Cost By campaign" style={cardstyle}> */}
                <TrendDivisionAndYearRed
                  months={months}
                  unit={unit}
                  title="Cost By Month & Product Line"
                  mediaformat={mediaformat}
                  dataViewHeaderFirstTitle="Month-Year"
                  // formatdatafn={i=>i.p = (i.cost/i.traffic)}
                  division={division}
                  yearsopt={yearsopt}
                  years={years}
                  subcag={productline}
                  cag={cag}
                  treeopt={treeopt}
                ></TrendDivisionAndYearRed>
                {/* </Card> */}
              </div>
            </div>
            {/* <RedTable division={division} years={years} months={months}></RedTable> */}
          </Card>
          <Card title="Asset Type Performance" style={cardstyle} headerLine={true}>
            <div className="line" style={linestyle}>
              <div className="line-1-left">
                <RedBarHor
                  months={months}
                  title="CTR By Asset Type & Post Format"
                  color={themecolor_pie1}
                  fields={['ctr']}
                  groups={['assettype', 'content']}
                  unit={unit}
                  mediaformat={mediaformat}
                  dataViewHeaderFirstTitle={['Post Format', 'Person/Product', 'CTR']}
                  yearsopt={yearsopt}
                  years={years}
                  subcag={productline}
                  cag={cag}
                  treeopt={treeopt}
                  formatpercent={true}
                ></RedBarHor>
              </div>
              <div className="line-1-right">
                <RedBarHor
                  months={months}
                  title="CPE By Asset Type & Post Format"
                  color={themecolor_pie1}
                  fields={['cpe']}
                  mediaformat={mediaformat}
                  groups={['assettype', 'content']}
                  unit={unit}
                  dataViewHeaderFirstTitle={['Post Format', 'Person/Product', 'CPE']}
                  yearsopt={yearsopt}
                  years={years}
                  subcag={productline}
                  cag={cag}
                  treeopt={treeopt}
                ></RedBarHor>
              </div>
            </div>
            <div className="line" style={linestyle}>
              <div className="line-1-left">
                <RedBarHor
                  months={months}
                  title="ER By Asset Type & Post Format"
                  color={themecolor_pie1}
                  fields={['er_post']}
                  mediaformat={mediaformat}
                  groups={['assettype', 'content']}
                  unit={unit}
                  dataViewHeaderFirstTitle={['Post Format', 'Person/Product', 'ER']}
                  years={years}
                  yearsopt={yearsopt}
                  subcag={productline}
                  cag={cag}
                  treeopt={treeopt}
                  formatpercent={true}
                ></RedBarHor>
              </div>
              <div className="line-1-right"></div>
            </div>
          </Card>
          <Card title="Post Performance" style={cardstyle} headerLine={true}>
            <div className="assBtn">
              <span className="label">Asset：</span>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                maxTagCount={1}
                options={assetOpt}
                value={asset}
                onChange={(val) => handleChange(val, setAsset)}
                style={{ width: '250px' }}
                mode={'multiple'}
              />
            </div>
            <div className="line">
              <div className="line-1">
                <RedBarHor
                  sortdate={true}
                  months={months}
                  asset={asset}
                  mediaformat={mediaformat}
                  title="CTR &ER By Campaign& Post Name"
                  // color={themecolor_pie1}
                  fields={['ctr', 'er_post', 'startdate']}
                  groups={['campaignname', 'postname']}
                  // height='1720px'
                  barwidth="30"
                  // dataViewHeaderFirstTitle="Month-Year"
                  years={years}
                  yearsopt={yearsopt}
                  subcag={productline}
                  cag={cag}
                  treeopt={treeopt}
                  formatpercent={true}
                  argcreator={(res) => {
                    const yAxis = [] //yname props
                    const series = {
                      ctr: [],
                      er: [],
                    }
                    res.forEach(({ ctr, er_post, campaignname }) => {
                      yAxis.push(campaignname)
                      series['ctr'].push(ctr)
                      series['er'].push(er_post)
                    })
                    return {
                      yAxis,
                      series,
                      color: themecolor_pie1,
                      dataViewHeaderFirstTitle: ['Campaign', 'CTR', 'ER'],
                      barwidth: 10,
                      scroll: true,
                      formatpercent: true,
                    }
                  }}
                ></RedBarHor>
              </div>
            </div>
            <div className="line" style={linestyle}>
              <div className="line-1-left">
                <TrendLineProd
                  sortdate={true}
                  months={months}
                  asset={asset}
                  title=" Cost & CPE By Post Name"
                  color={themecolor_pie1}
                  fields={['cost', 'cpe', 'startdate', 'assets']}
                  groups={['assets']}
                  unit={unit}
                  mediaformat={mediaformat}
                  dataViewHeaderFirstTitle="Month-Year"
                  years={years}
                  yearsopt={yearsopt}
                  subcag={productline}
                  cag={cag}
                  treeopt={treeopt}
                  argcreator={(res) => {
                    const yAxis = [] //yname props
                    const series = {
                      cost: [],
                      cpe: [],
                    }
                    res.forEach(({ cost, cpe, postname }) => {
                      yAxis.push(postname)
                      series['cost'].push(Number(cost).toFixed(2))
                      series['cpe'].push(cpe)
                    })
                    return {
                      yAxis,
                      series,
                      color: themecolor_pie1,
                      dataViewHeaderFirstTitle: ['Name', 'Cost', 'Cpe'],
                      barwidth: 10,
                      scroll: true,
                    }
                  }}
                ></TrendLineProd>
                {/* <RedBarHor
                  sortdate={true}
                  months={months}
                  title=" Cost & CPE By Post Name"
                  color={themecolor_pie1}
                  fields={['cost', 'cpe', 'startdate']}
                  groups={['postname']}
                  unit={unit}
                  dataViewHeaderFirstTitle="Month-Year"
                  years={years}
                  subcag={productline}
                  cag={cag}
                  treeopt={treeopt}
                  argcreator={(res) => {
                    const yAxis = [] //yname props
                    const series = {
                      cost: [],
                      cpe: [],
                    }
                    res.forEach(({ cost, cpe, postname }) => {
                      yAxis.push(postname)
                      series['cost'].push(Number(cost).toFixed(2))
                      series['cpe'].push(cpe)
                    })
                    return {
                      yAxis,
                      series,
                      color: themecolor_pie1,
                      dataViewHeaderFirstTitle: ['Post Name', 'Cost', 'Cpe'],
                      barwidth: 10,
                      scroll: true,
                    }
                  }}
                ></RedBarHor> */}
              </div>
            </div>
          </Card>
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Overview" key="2">
        <CrossPerformance mark={'GDFeedsRed'} headertitle={'Overview RedFeed'} />
      </Tabs.TabPane>
    </Tabs>
  )
}
export default connect(
  (state) => ({
    currentYear: state.years[state.years.length - 1],
    division: state.division,
    unit: state.unit,
    yearsopt: state.years.slice().reverse(),
    // preYear: state.years[state.years.length - 2],
  }),
  { setDivision }
)(Red)

import React, { useState, useEffect } from 'react'
import { getSum1, downta } from '../../api/home'
import { Table, Button, Tooltip } from 'antd'
import { addtablekey } from './util'
import { VerticalAlignBottomOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { getTimestamp, formatNum, formatTableNum } from '@/common/util'
export default function MomentTable(props) {
  const colums = [
    {
      title: 'TA(定向)',
      dataIndex: 'target1',
      key: 'target1',
      sorter: (a, b) => a.target1.charCodeAt(0) - b.target1.charCodeAt(0),
      width: 300,
    },
    {
      title: (
        <div>
          Cost&nbsp;
          <Tooltip placement="bottom" title="实际广告投放消耗">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'cost',
      key: 'cost',
      width: 100,
      sorter: (a, b) => a.cost - b.cost,
      render(val) {
        if (!val) return 0
        return formatTableNum(formatNum(val, unit, 0))
      },
      align: 'right',
    },
    {
      title: (
        <div>
          CTR&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>Wechat渠道：Click to Landing/Impression</div>
                  <div>Red渠道：Click/Impression</div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'ctr',
      key: 'ctr',
      width: 100,
      sorter: (a, b) => a.ctr - b.ctr,
      render(val) {
        if (!val) return 0
        return val + '%'
      },
      align: 'right',
    },
    {
      title: (
        <div>
          ATC%&nbsp;
          <Tooltip placement="bottom" title="Add to Cart / Click">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'atc',
      key: 'atc',
      width: 100,
      sorter: (a, b) => a.atc - b.atc,
      render(val) {
        if (!val) return 0
        return val + '%'
      },
      align: 'right',
    },
    {
      title: (
        <div>
          OrderROI&nbsp;
          <Tooltip placement="bottom" title="Revenue / Cost">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'roi',
      key: 'roi',
      width: 100,
      sorter: (a, b) => a.roi - b.roi,
      render(val) {
        if (!val) return 0
        return val && val.toFixed(2)
      },
      align: 'right',
    },
  ]
  const [lastres, setlastres] = useState(null)
  const scrollX = colums.reduce((pre, cur) => {
    pre = pre + (cur.with || 150)
    return pre
  }, 0)
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const [data, setData] = useState([])
  const {
    years,
    yearsopt,
    months,
    division,
    axis,
    axisOpt,
    product,
    productOpt,
    cag,
    cagopt,
    ta,
    unit,
    ta2,
  } = props
  useEffect(() => {
    // if(!years.length) return
    if (!axisOpt.length) {
      setData([])
    }
    if (!productOpt.length) {
      setData([])
    }
    getSum1({
      fields: ['cost', 'ctr', 'atc', 'roi', 'ta2'],
      groups: ['target1', 'ta2'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years.includes('all') ? yearsopt : years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.includes('all')
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            : months.map((i) => map.indexOf(i)),
        },
        {
          field: 'adq',
          operator: 'IN',
          values: ['前端'],
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
        },
        {
          field: 'productline',
          operator: 'IN',
          values: product.includes('all') ? productOpt.slice(1).map((i) => i.value) : product,
        },
        {
          field: 'campaignname',
          operator: 'IN',
          values: cag.includes('all') ? cagopt.slice(1).map((i) => i.value) : cag,
        },
        {
          field: 'mediagroup',
          operator: 'IN',
          values: ['Tencent'],
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: ['Moments RTB'],
        },
        // { field: 'division', operator: 'IN', values: division.includes('all') ? divisionOption : division },
      ],
      sorts: [
        // {
        //   field: 'year',
        //   type: 'ASC',
        // },
        {
          field: 'cost',
          type: 'DESC',
        },
      ],
    }).then((res_) => {
      if (res_ && Array.isArray(res_)) {
        let res = res_.sort((a, b) => {
          return a.cost - b.cost
        })

        addtablekey(res)
        setlastres(res)
        res.reverse()
        setData(
          res.filter((i) => {
            if (ta.includes('all') && ta2.includes('all')) {
              return true
            } else if (!ta.includes('all') && ta2.includes('all')) {
              return ta.includes(i.target1)
            } else if (ta.includes('all') && !ta2.includes('all')) {
              return ta2.includes(i.ta2)
            } else {
              return ta2.includes(i.ta2) && ta.includes(i.target1)
            }
          })
        )
      } else {
        setData([])
      }
    })
  }, [years, division, axis, axisOpt, product, productOpt, months, cag, cagopt, ta, ta2, unit])
  const downLoadTable = () => {
    downta(
      {
        fields: ['cost', 'ctr', 'atc', 'roi', 'ta2'],
        groups: ['target1', 'ta2'],
        filters: [
          {
            field: 'year',
            operator: 'IN',
            values: years.includes('all') ? yearsopt : years,
          },
          {
            field: 'month',
            operator: 'IN',
            values: months.includes('all')
              ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
              : months.map((i) => map.indexOf(i)),
          },
          {
            field: 'adq',
            operator: 'IN',
            values: ['前端'],
          },
          {
            field: 'categorycorp',
            operator: 'IN',
            values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
          },
          {
            field: 'productline',
            operator: 'IN',
            values: product.includes('all') ? productOpt.slice(1).map((i) => i.value) : product,
          },
          {
            field: 'campaignname',
            operator: 'IN',
            values: cag.includes('all') ? cagopt.slice(1).map((i) => i.value) : cag,
          },
          {
            field: 'mediagroup',
            operator: 'IN',
            values: ['Tencent'],
          },
          {
            field: 'mediaformat',
            operator: 'IN',
            values: ['Moments RTB'],
          },
          // { field: 'division', operator: 'IN', values: division.includes('all') ? divisionOption : division },
        ],
        sorts: [
          // {
          //   field: 'year',
          //   type: 'ASC',
          // },
          {
            field: 'cost',
            type: 'DESC',
          },
        ],
      },
      { responseType: 'blob' }
    ).then((res) => {
      const data = res.data
      const link = document.createElement('a')
      const contentType = 'application/vnd.ms-excel'
      const blob = new Blob([data], { type: contentType })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', getTimestamp() + `.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }
  useEffect(() => {
    if (!lastres) return
    if (!ta || !ta.length) return

    const filterres = lastres.filter((i) => (ta.includes('all') ? true : ta.includes(i.target1)))
    filterres.reverse()
    setData(filterres)
  }, [ta])

  return (
    <div style={{ marginTop: '0', padding: '12px', minHeight: '300px', maxHeight: '540px' }}>
      <Table
        dataSource={data}
        columns={colums}
        pagination={false}
        // scroll={{ x: scrollX - 300 }}
        scroll={{ y: 450 }}
      ></Table>
      <Button type="text" onClick={downLoadTable} className="downBtn">
        <VerticalAlignBottomOutlined style={{ fontSize: '18px' }} />
      </Button>
    </div>
  )
}

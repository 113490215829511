import styles from './index.module.less'
const Card = (props) => {
  const { wrap } = styles
  const {
    headerLine = false,
    headerFilter = null,
    title,
    children,
    style,
    minTitleWidth = '270px',
    wrappaddingbottom = true,
  } = props
  const borderBottom = headerLine ? '1px solid #F0F0F0' : 'none'
  const paddingBottom = wrappaddingbottom ? '24px' : '0'
  return (
    <div className={wrap} style={{ ...style, paddingBottom }}>
      <div className="header" style={{ borderBottom }}>
        <div className="title" style={{ minWidth: minTitleWidth }}>
          {title}
        </div>
        <div className="headerFilter">{headerFilter}</div>
      </div>
      <div className="content">{children}</div>
    </div>
  )
}
export default Card

import axios from 'axios'
import { message } from 'antd'
import { throttle } from 'lodash-es'
import { store } from '@/redux'

const TIMEOUT = 1000 * 300
// eslint-disable-next-line

export const service = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
  withCredentials: true,
})

service.interceptors.request.use(
  (config) => {
    const token = store.getState().token
    // 如果有token给每个请求设置token
    config.headers['token'] = token

    if (config.setProgress) {
      config.onUploadProgress = (progress) => {
        let p = Math.floor((progress.loaded / progress.total) * 100)
        config.setProgress({ percent: p })
      }
    }

    return config
  },
  (error) => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
const errorfn = (res) => {
  console.log(res)
  if (res) {
    const { status } = res
    if (status === 403) {
      message.warning('登录过期，请重新登录')
      setTimeout(() => {
        window.location.href = '/login'
      }, 3000)
    } else {
      message.error('请求出错了，请稍后再试')
    }
  }
}
const errorHandler = throttle(errorfn, 3000, { trailing: false })

service.interceptors.response.use(
  (res) => {
    if (res.config.responseType === 'blob') {
      //文件流
      return res
    } else if (res.data.status === 0) {
      if (res.config.addData) {
        const addData = res.config.addData
        try {
          const params = JSON.parse(res.config.data)
          addData(params, res.data.data)
        } catch {
          console.log('err')
        }
      }
      return res.data.data
    } else {
      message.error('错误信息：' + res.data.msg)
      return Promise.reject(res.data.msg)
    }
  },
  ({ response }) => {
    errorHandler(response)
  }
)

const get = function (url) {
  return function (params = {}, config) {
    return service.get(url, { params, ...config })
  }
}

const post = function (url) {
  return function (data, config) {
    return service.post(url, data, config)
  }
}

export { get, post }

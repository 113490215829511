import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { redfeedsum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { redbarhoropt, formatmonth, formatMillion, formatNum } from './util'
import { mediaFormatOption } from '@/common/vars'
import { set, update } from 'lodash-es'
import { Empty } from 'antd'

export default function RedBarHor(props) {
  const {
    title,
    unitReady,
    division,
    height,
    color,
    yearsopt,
    years,
    months,
    cag,
    subcag,
    treeopt,
    barwidth,
    groups,
    srcoll,
    fields,
    argcreator,
    unit,
    dataViewHeaderFirstTitle,
    refreshunit,
    style,
    asset,
    formatpercent,
    mediaformat,
    sortdate,
  } = props

  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend)
  const [lastres, setlastres] = useState(null)
  const [average, setAverage] = useState([])
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const fn = argcreator
    ? argcreator
    : (res_) => {
        let res = res_.filter((i) => {
          return i.assettype !== '' && i.assettype !== '-' && i.content !== '' && i.content !== '-'
        })
        const yAxis = [...new Set(res.map((i) => i['assettype']))] //yname props
        const series = res.reduce((pre, cur) => {
          let name = cur['content'] //seriesname    props
          if (!pre[name]) pre[name] = []
          // debugger
          pre[name].push(cur[fields[0]] || 0)
          return pre
        }, {})

        console.log(yAxis, series, '为什么另一个没有数据')
        return { yAxis, series, color, dataViewHeaderFirstTitle, barwidth, formatpercent }
      }
  useEffect(() => {
    if (JSON.stringify(treeopt) === '{}') return
    const config = {
      fields,
      title: 'CTR By Asset Type & Post Format',
      groups,
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years.includes('all') ? yearsopt : years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.includes('all')
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            : months.map((i) => map.indexOf(i)),
        },
        {
          field: 'productline',
          operator: 'IN',
          values: subcag.includes('all') ? treeopt['productline'] : subcag,
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: cag.includes('all') ? treeopt['categorycorp'] : cag,
        },
        {
          field: 'assets',
          operator: 'IN',
          values: (asset || ['all']).includes('all') ? treeopt['assets'] : asset,
        },
        // {
        //   field: ' mediaformat',
        //   operator: 'IN',
        //   values: ['GD Feeds', 'RTB Feeds'],
        // },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: mediaformat.includes('all')
            ? mediaFormatOption.map((it) => {
                return it.value
              })
            : mediaformat,
        },
        {
          field: ' mediagroup ',
          operator: 'IN',
          values: ['Red'],
        },
      ],
    }
    // if (sortdate) {
    //   config.sorts = [{ field: 'startdate', type: 'ASC' }]
    // }
    redfeedsum(config).then((res) => {
      if (!Array.isArray(res)) {
        return setEmpty(true)
      }
      setEmpty(false)
      setlastres(res)
      const config = redbarhoropt(fn(res))

      console.log(config, 'configconfigconfigconfig' + title)
      // debugger
      updateCharts(config)
    })
  }, [years, months, cag, subcag, treeopt, asset, mediaformat])

  // 求平均
  useEffect(() => {
    if (JSON.stringify(treeopt) === '{}') return
    redfeedsum({
      title,
      fields,
      groups: ['year'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years.includes('all') ? yearsopt : years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.includes('all')
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            : months.map((i) => map.indexOf(i)),
        },
        {
          field: 'productline',
          operator: 'IN',
          values: subcag.includes('all') ? treeopt['productline'] : subcag,
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: cag.includes('all') ? treeopt['categorycorp'] : cag,
        },
        {
          field: 'assets',
          operator: 'IN',
          values: (asset || ['all']).includes('all') ? treeopt['assets'] : asset,
        },
        {
          field: ' mediaformat',
          operator: 'IN',
          values: ['GD Feeds', 'RTB Feeds'],
        },
        {
          field: ' mediagroup ',
          operator: 'IN',
          values: ['Red'],
        },
      ],
    }).then((res) => {
      console.log(res, title)
      if (!Array.isArray(res) || res.length === 0) return setAverage([])
      if (fields.indexOf('startdate') !== -1) {
        fields.splice(fields.indexOf('startdate'), 1)
      }
      if (fields.length === 1) {
        const avetext = res.map(
          (i) => `${i.year}YTD均值：${formatpercent ? i[fields[0]] + '%' : i[fields[0]].toFixed(2)}`
        )
        setAverage(avetext)
      }
      if (fields.length === 2) {
        const avetext = res.map(
          (i) => `
        ${i.year} ${fields[0]}YTD均值：${
            formatpercent ? i[fields[0]] + '%' : i[fields[0]].toFixed(2)
          };
        ${fields[1]}YTD均值：${formatpercent ? i[fields[1]] + '%' : i[fields[1]].toFixed(2)}
        `
        )
        setAverage(avetext)
      }
    })
  }, [years, months, cag, subcag, treeopt])
  return (
    <div className="trend-wrap">
      {average.length ? <div className="average">{average.join(',')}</div> : null}
      <div className="title">
        <div>{title}</div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          width: 'calc(100%)',
          height: height ? height : '320px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}

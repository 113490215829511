import { message, Tooltip } from 'antd'
import { store } from '@/redux'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { QuestionCircleOutlined } from '@ant-design/icons'
import fa from '@/view/home/fa'
const contentToOption = function (HTMLDomElement, opt) {
  console.log(HTMLDomElement)
  // debugger
  // const ele = HTMLDomElement.cloneNode(HTMLDomElement)
  // console.log(1)

  //权限放开了？
  // if (!sessionStorage.getItem('channelPerm')) {
  //   return message.warning('权限不够')
  // }
  let et = XLSX.utils.table_to_book(HTMLDomElement.getElementsByTagName('table')[0], { raw: true })
  // debugger
  let etout = XLSX.write(et, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'array',
  })
  try {
    FileSaver.saveAs(
      new Blob([etout], {
        type: 'application/octet-stream',
      }),
      '表格' + '-' + new Date().toLocaleString() + '.xlsx'
    )
  } catch (e) {
    console.log(e)
  }
  return etout
}
const dataViewConfigCreator = (optionToContentConfig) => {
  return {
    // lang: [
    //   '<div style="padding: 0 10px;margin-top:-10px;margin-left:-30px;"></div>',
    //   '<div style="width:15px;position:absolute;height:10px;margin-left:-10px;margin-top:-5px;background:white;"></div>',
    //   'close',
    // ],
    lang: ['数据视图', '关闭', '导出Excel'],
    // buttonColor: '#535fbb',
    // iconStyle: {
    //     color: 'red',
    //     borderWidth: 2,
    //     fontSize: '20px'
    // },
    // readOnly: false,
    // title: ' Data View',
    optionToContent: function (opt) {
      return optionToContentConfig(opt)
    },
    contentToOption,
  }
}

const sleep = (time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

const numberWithCommas = (x = 0) => {
  let y = x.toString().split('.')

  return y[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (y.length > 1 ? '.' + y[1] : '')
}
const formatNum = (cost, unit, fixed = 2) => {
  if (!cost) return 0
  let newCost = 0
  try {
    switch (unit) {
      case 'm':
        newCost = Math.floor((cost / 1000000) * 10) / 10 // (cost/ 1000000).toFixed(4) //parseFloat((cost / 1000000))
        break
      case 'k':
        newCost = Math.floor((cost / 1000) * 10) / 10 //parseFloat(cost / 1000)
        break
      default:
        newCost = Math.floor(cost * 10) / 10
        break
    }
  } catch (e) {
    console.log(e)
  }
  return Number(newCost)
}

const formatNumUnit = (unit) => {
  let u = undefined
  try {
    switch (unit) {
      case 'm':
        u = 'Mil'
        break
      case 'k':
        u = 'K'
        break
      default:
        u = ''
        break
    }
  } catch (e) {
    console.log(e)
  }
  return u
}
const formatNum2 = (cost, unit) => {
  if (!cost) return 0
  let newCost = 0
  try {
    switch (unit) {
      case 'm':
        newCost = Math.floor(cost * 10000) / 10000 // (cost/ 1000000).toFixed(4) //parseFloat((cost / 1000000))
        break
      case 'k':
        newCost = Math.floor(cost * 100) / 100 //parseFloat(cost / 1000)
        break
      default:
        newCost = Math.floor(cost * 100) / 100
        break
    }
  } catch (e) {
    console.log(e)
  }
  return Number(newCost)
}

const formatUnit = (unit) => {
  switch (unit) {
    case 'm':
      unit = 'Mil'
      break
    case 'k':
      unit = 'K'
      break
    case 'n':
      unit = ''
      break
    default:
      unit = ''
      break
  }
  return unit
}
const getTimestamp = () => {
  let myDate = new Date()
  let Date_ = myDate.getFullYear() + fomatDate(myDate.getMonth() + 1) + fomatDate(myDate.getDate())
  let Time_ =
    fomatDate(myDate.getHours()) + fomatDate(myDate.getMinutes()) + fomatDate(myDate.getSeconds())
  return Date_ + '' + Time_
}
function fomatDate(num) {
  return num >= 10 ? num + '' : '0' + num
}
const formatTableNum = (num) => {
  if (!num) return 0
  return numberWithCommas(num)
}

const specialNumber = (fieldName, v, unit) => {
  let v_ = v
  let special1 = ['ctr', 'er_wechat', 'er_post', 'cvr_wechat', 'cvr_search', 'webaddtocart_click'] //保留两位小数带百分比1111
  let special2 = [
    'cpm',
    'cpc',
    'roi',
    't0ysrevenuema',
    't7ysrevenuema',
    't15ysrevenuema',
    'cpe_post',
  ] //保留两位小数
  if (special1.includes(fieldName)) return Number(v_) ? v_.toFixed(2) + '%' : '-'
  else if (special2.includes(fieldName)) return Number(v_) ? v_.toFixed(2) : '-'
  else return unit ? numberWithCommas(formatNum(v_, unit, 0)) : numberWithCommas(v)
}

const preview = {
  title: 'Previewurl',
  dataIndex: 'previewurl',
  key: 'previewurl',
  render(val) {
    // let fun =fu((val)=>{

    // })
    return val ? (
      <a target="_blank" style={{ color: 'blue' }} href={val} rel="noreferrer">
        预览
      </a>
    ) : (
      '--'
    )
    // <span onClick={fun(val)}>预览</span>
  },
  width: 100,
  //sorter: (a, b) => a.assets.charCodeAt(0) - b.assets.charCodeAt(0),
}

const specialColumns = ['startdate', 'enddate', 'previewurl']

const map = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
}
const getMyColumns = (unit = 'n') => {
  return [
    {
      title: 'Media',
      dataIndex: 'media',
      key: 'media',
      width: 200,
      sorter: (a, b) => {
        return a.media.charCodeAt(0) - b.media.charCodeAt(0)
      },
    },
    {
      title: 'Media Format',
      dataIndex: 'mediaformat',
      key: 'mediaformat',
      width: 200,
      sorter: (a, b) => a.mediaformat.charCodeAt(0) - b.mediaformat.charCodeAt(0),
    },
    {
      title: 'Media Channel',
      dataIndex: 'mediachannel',
      key: 'mediachannel',
      width: 200,
      sorter: (a, b) => a.mediaformat.charCodeAt(0) - b.mediaformat.charCodeAt(0),
    },
    {
      title: 'Division',
      dataIndex: 'divisioncorp',
      key: 'divisioncorp',
      width: 200,
      sorter: (a, b) => a.divisioncorp.charCodeAt(0) - b.divisioncorp.charCodeAt(0),
    },
    {
      title: 'Category',
      dataIndex: 'categorycorp',
      key: 'categorycorp',
      width: 200,
      sorter: (a, b) => a.categorycorp.charCodeAt(0) - b.categorycorp.charCodeAt(0),
    },
    {
      title: 'Date',
      dataIndex: 'dateline',
      key: 'dateline',
      width: 200,
      sorter: (a, b) => new Date(a.dateline).getTime() - new Date(b.dateline).getTime(),
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
      width: 100,
      sorter: (a, b) => map[a.month] - map[b.month],
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      width: 200,
      sorter: (a, b) => Number(a.year) - Number(b.year),
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaignname',
      key: 'campaignname',
      width: 200,
      sorter: (a, b) => a.campaignname.charCodeAt(0) - b.campaignname.charCodeAt(0),
    },
    {
      title: 'Product Line',
      dataIndex: 'productline',
      key: 'productline',
      sorter: (a, b) => a.productline.charCodeAt(0) - b.productline.charCodeAt(0),
      width: 200,
    },
    {
      title: 'Assets',
      dataIndex: 'assets',
      key: 'assets',
      width: 100,
      sorter: (a, b) => a.assets.charCodeAt(0) - b.assets.charCodeAt(0),
    },

    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.charCodeAt(0) - b.category.charCodeAt(0),
      width: 200,
    },
    {
      title: 'TA(Core/Re/Po)',
      dataIndex: 'ta2',
      key: 'ta2',
      sorter: (a, b) => a.category.charCodeAt(0) - b.category.charCodeAt(0),
      width: 200,
    },
    {
      title: 'TA(Target 1)',
      dataIndex: 'target1',
      key: 'target1',
      sorter: (a, b) => a.category.charCodeAt(0) - b.category.charCodeAt(0),
      width: 200,
    },
    {
      title: 'Start Date',
      dataIndex: 'startdate',
      key: 'startdate',
      sorter: (a, b) => {
        return new Date(a.startdate) - new Date(b.startdate)
      },
      width: 200,
    },
    {
      title: 'End Date',
      dataIndex: 'enddate',
      key: 'enddate',
      sorter: (a, b) => {
        return new Date(a.startdate) - new Date(b.startdate)
      },
      width: 200,
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      sorter: (a, b) => {
        let a1 = a.period.split('-')
        let b1 = b.period.split('-')
        return new Date(a1[0]) - new Date(b1[0])
        // a.period.charCodeAt(0) - b.period.charCodeAt(0)
      },
      width: 200,
    },
    {
      title: 'Asset type',
      dataIndex: 'assettype',
      key: 'assettype',
      sorter: (a, b) => a.assettype.charCodeAt(0) - b.assettype.charCodeAt(0),
      width: 200,
    },
    // {
    //   title: 'Product Line(SubCategory)',
    //   dataIndex: 'subcategory',
    //   key: 'subcategory',
    //   sorter: (a, b) => a.subcategory.charCodeAt(0) - b.subcategory.charCodeAt(0),
    //   width: 200,
    // },
    {
      title: 'Placement',
      dataIndex: 'placement',
      key: 'placement',
      sorter: (a, b) => a.placement.charCodeAt(0) - b.placement.charCodeAt(0),
      width: 100,
    },
    {
      title: 'Person/Product',
      dataIndex: 'content',
      key: 'content',
      sorter: (a, b) => a.content.charCodeAt(0) - b.content.charCodeAt(0),
      width: 200,
    },
    {
      title: 'Impression',
      dataIndex: 'impression',
      key: 'impression',
      width: 200,
      render(val) {
        return specialNumber('impression', formatNum(val, unit, 0))
      },
      sorter: (a, b) => a.impression - b.impression,
    },
    {
      title: (
        <div>
          Click&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>Wechat渠道定义：Click to Landing</div>
                  <div>其他渠道定义：Click</div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'click',
      key: 'click',
      width: 200,
      render(val) {
        return formatTableNum(formatNum(val, unit, 0))
      },
      sorter: (a, b) => a.click - b.click,
    },
    {
      title: (
        <div>
          Traffic&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>Moments RTB定义：有数Traffic</div>
                  <div>Channel Feeds定义：Click to Landing</div>
                  <div>其他渠道：GA Session</div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'traffic',
      key: 'traffic',
      width: 200,
      render(val) {
        return specialNumber('traffic', formatNum(val, unit, 0))
      },
      sorter: (a, b) => a.traffic - b.traffic,
    },
    {
      title: (
        <div>
          Order&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>Wechat渠道定义：ADQ Order</div>
                  <div>其他渠道：GA Order</div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'order',
      key: 'order',
      width: 200,
      render(val) {
        return specialNumber('order', formatNum(val, unit, 0))
      },
      sorter: (a, b) => a.order - b.order,
    },
    {
      title: (
        <div>
          Revenue&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>Wechat渠道定义：ADQ Order Revenue</div>
                  <div>其他渠道：GA Revenue</div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'revenue',
      key: 'revenue',
      width: 200,
      render(val) {
        return specialNumber('revenue', formatNum(val, unit, 0))
      },
      sorter: (a, b) => a.revenue - b.revenue,
    },
    {
      title: (
        <div>
          CTR&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>Wechat渠道：Click to Landing/Impression</div>
                  <div>Red渠道：Click/Impression</div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'ctr',
      key: 'ctr',
      width: 200,
      render(val) {
        return specialNumber('ctr', val)
      },
      sorter: (a, b) => a.ctr - b.ctr,
    },
    // {
    //   title: 'Budget Amount',
    //   dataIndex: 'budget',
    //   key: 'budget',
    //   width: 200,
    //   render(val) {
    //     return formatTableNum(formatNum(val, unit, 0))
    //   },
    //   sorter: (a, b) => a.budget - b.budget,
    // },
    {
      title: (
        <div>
          Engagement-WeChat&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>Wechat渠道互动次数</div>
                  <div>
                    Moments RTB定义：Like+Comments+Follow+Click to Landing+Click on Profile
                    Image+Click on Nickname
                  </div>
                  <div>
                    Channel Feeds定义：Click to Landing+Like+Comments+Follow+Share+Explore
                    Account+Click Copy/Comment Section(点击展开评论区)
                  </div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'engagementwechat',
      key: 'engagementwechat',
      width: 200,
      render(val) {
        return specialNumber('engagementwechat', formatNum(val, unit, 0))
      },
      sorter: (a, b) => a.engagementwechat - b.engagementwechat,
    },
    {
      title: (
        <div>
          Engagement-Post&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>Red渠道互动次数,公式：Like+Comments+Share+Favorite</div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'engagementpost',
      key: 'engagementpost',
      width: 200,
      render(val) {
        return specialNumber('engagementpost', formatNum(val, unit, 0))
      },
      sorter: (a, b) => a.engagementpost - b.engagementpost,
    },
    {
      title: (
        <div>
          ER-WeChat&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>Wechat渠道互动率，公式：Engagement-Wechat / Impression</div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'er_wechat',
      key: 'er_wechat',
      width: 200,
      render(val) {
        return specialNumber('er_wechat', val)
      },
      sorter: (a, b) => a.er_wechat - b.er_wechat,
    },
    {
      title: (
        <div>
          ER-Post&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>Red渠道互动率，公式：Engagement-Post / Click</div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'er_post',
      key: 'er_post',
      width: 200,
      render(val) {
        return specialNumber('er_post', val)
      },
      sorter: (a, b) => a.er_post - b.er_post,
    },
    {
      title: (
        <div>
          CPM&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>千次展现成本，公式：Cost / Impression *1000</div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'cpm',
      key: 'cpm',
      width: 200,
      render(val) {
        return specialNumber('cpm', val)
      },
      sorter: (a, b) => a.cpm - b.cpm,
    },
    {
      title: (
        <div>
          CPC&nbsp;
          <Tooltip placement="bottom" title="Cost / Click">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'cpc',
      key: 'cpc',
      width: 200,
      render(val) {
        return specialNumber('cpc', val)
      },
      sorter: (a, b) => a.cpc - b.cpc,
    },
    {
      title: (
        <div>
          CVR-Wechat&nbsp;
          <Tooltip placement="bottom" title="Wechat渠道转化率，公式：Order / 加入购物车量">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'cvr_wechat',
      key: 'cvr_wechat',
      width: 200,
      render(val) {
        return specialNumber('cvr_wechat', val)
      },
      sorter: (a, b) => a.wecha - b.wecha,
    },
    {
      title: (
        <div>
          CVR-Search&nbsp;
          <Tooltip placement="bottom" title="Paid Search渠道转化率，公式：Order / Traffic">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'cvr_search',
      key: 'cvr_search',
      width: 200,
      render(val) {
        return specialNumber('cvr_search', val)
      },
      sorter: (a, b) => a.cvr_search - b.cvr_search,
    },
    {
      title: 'Add to Cart',
      dataIndex: 'webaddtocart',
      key: 'webaddtocart',
      width: 200,
      render(val) {
        return specialNumber('webaddtocart', formatNum(val, unit, 0))
      },
      sorter: (a, b) => a.webaddtocart - b.webaddtocart,
    },
    {
      title: '加购率(ATC/CLK)',
      dataIndex: 'webaddtocart_click',
      key: 'webaddtocart_click',
      render(val) {
        return specialNumber('webaddtocart_click', val)
      },
      width: 200,
      sorter: (a, b) => a.webaddtocart_click - b.webaddtocart_click,
    },
    {
      title: (
        <div>
          ROI&nbsp;
          <Tooltip placement="bottom" title="Revenue / Cost">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'roi',
      key: 'roi',
      width: 200,
      render(val) {
        return specialNumber('roi', val)
      },
      sorter: (a, b) => a.roi - b.roi,
    },
    {
      title: (
        <div>
          COST&nbsp;
          <Tooltip placement="bottom" title="实际广告投放消耗">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'cost',
      key: 'cost',
      width: 200,
      render(val) {
        return specialNumber('cost', formatNum(val, unit, 0))
      },
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: (
        <div>
          T+0 ROI&nbsp;
          <Tooltip placement="bottom" title="有数T+0 Revenue / Cost">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 't0ysrevenuema',
      key: 't0ysrevenuema',
      width: 200,
      render(val) {
        return specialNumber('t0ysrevenuema', val)
      },
      sorter: (a, b) => a.t0ysrevenuema - b.t0ysrevenuema,
    },
    {
      title: (
        <div>
          T+7 ROI&nbsp;
          <Tooltip placement="bottom" title="有数T+7 Revenue / Cost">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 't7ysrevenuema',
      key: 't7ysrevenuema',
      width: 200,
      render(val) {
        return specialNumber('t7ysrevenuema', val)
      },
      sorter: (a, b) => a.t7ysrevenuema - b.t7ysrevenuema,
    },
    {
      title: (
        <div>
          T+15 ROI&nbsp;
          <Tooltip placement="bottom" title="有数T+15 Revenue / Cost">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 't15ysrevenuema',
      key: 't15ysrevenuema',
      render(val) {
        return specialNumber('t15ysrevenuema', val)
      },
      width: 200,
      sorter: (a, b) => a.t15ysrevenuema - b.t15ysrevenuema,
    },
    {
      title: 'Click to Landing',
      dataIndex: 'clicktolanding',
      key: 'clicktolanding',
      width: 200,
      render(val) {
        return specialNumber('clicktolanding', formatNum(val, unit, 0))
      },
      sorter: (a, b) => a.clicktolanding - b.clicktolanding,
    },
    {
      title: 'ECTR',
      dataIndex: 'ectr',
      key: 'ectr',
      width: 200,
      render(val) {
        return specialNumber('ectr', formatNum(val, unit, 2))
      },
      sorter: (a, b) => a.ectr - b.ectr,
    },
    {
      title: (
        <div>
          CPE-Post&nbsp;
          <Tooltip
            placement="bottom"
            title={() => {
              return (
                <div>
                  <div>Red渠道平均互动成本，公式：Cost / Engagement-Post</div>
                </div>
              )
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'cpe_post',
      key: 'cpe_post',
      width: 200,
      render(val) {
        return specialNumber('cpe_post', val)
      },
      sorter: (a, b) => a.cpe_post - b.cpe_post,
    },
    {
      title: 'CPE-WeChat',
      dataIndex: 'cpe_wechat',
      key: 'cpe_wechat',
      width: 200,
      render(val) {
        return specialNumber('cpe_wechat', formatNum(val, unit, 2))
      },
      sorter: (a, b) => a.cpe_wechat - b.cpe_wechat,
    },
    {
      title: 'Collection',
      dataIndex: 'collection',
      key: 'collection',
      render(val) {
        return specialNumber('collection', formatNum(val, unit, 0))
      },
      sorter: (a, b) => a.collection - b.collection,
      width: 200,
    },
  ]
}

const getLegendSelected = (data, checkAllVal) => {
  let legend = {}
  data.forEach((i) => {
    legend[i] = checkAllVal ? true : false
  })

  return legend
}

export {
  dataViewConfigCreator,
  contentToOption,
  sleep,
  getTimestamp,
  formatNum,
  formatUnit,
  formatTableNum,
  getMyColumns,
  preview,
  specialNumber,
  specialColumns,
  getLegendSelected,
  numberWithCommas,
  formatNum2,
  formatNumUnit,
  map,
}

import Card from '../../components/Card'
import { useState } from 'react'
import TrendPieHor from './trend-bar-hor'
import CombineDate from './combineDate'
import TrendDivisionAndMonth from './trendDivisionAndMonth'
import TrendPie from './trend-pie'
import { Select } from 'antd'
import { connect } from 'react-redux'
import styles from './index.module.less'
import { useNavigate } from 'react-router-dom'

import { pieconfig8, argscreator } from './util'
import { themecolor, themecolor1, themecolor_2 } from '@/common/vars'
const { wrap } = styles
const Fa = (props) => {
  const navigate = useNavigate()
  const { currentYear, preYear, yearsOpt, unit } = props
  // todo 这里先写死  后面redux提供
  const [years, setYears] = useState([currentYear, preYear])
  const [year, setYear] = useState(currentYear)

  // const options = years.map((i) => ({ label: i, value: i }))

  return (
    <div className={wrap}>
      <div className="breadcrumb">
        <span className="root" onClick={() => navigate('/home/index')}>
          Finance Spending
        </span>{' '}
        / <span className="current">Spending by FA</span>
      </div>
      <div style={{ display: 'flex' }}>
        <Card
          style={{ flex: 1 }}
          title="FA Trend"
          headerFilter={
            <>
              Selected Period：
              <Select
                getPopupContainer={(n) => n.parentNode}
                style={{ width: '120px' }}
                options={yearsOpt}
                onChange={(val) => setYear(val)}
                defaultValue={year}
              />
            </>
          }
        >
          <TrendDivisionAndMonth
            year={year}
            unit={unit}
            type="FA"
            style={{ width: '100%' }}
            color={['#DBC3A5']}
          ></TrendDivisionAndMonth>
        </Card>
        <Card
          minTitleWidth="100px"
          style={{ flex: 1, marginLeft: '16px' }}
          title="By Product"
          headerFilter={<CombineDate onChange={(val) => setYears(val)} val={years} />}
        >
          <TrendPie
            years={years}
            argsfn={argscreator(pieconfig8)}
            color={themecolor_2}
            unit={unit}
            color1={themecolor_2}
          ></TrendPie>
        </Card>
      </div>
    </div>
  )
}
export default connect((state) => ({
  currentYear: state.years[state.years.length - 1],
  preYear: state.years[state.years.length - 2],
  yearsOpt: state.years.map((i) => ({ label: i, value: i })),
  unit: state.unit,
}))(Fa)

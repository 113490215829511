import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Radio } from 'antd'
import { getLegendSelected } from '@/common/util'
import { getBudgetSum3 } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendPieChartsOption3, formatmonth, formatMillion } from './util'
import { formatNum } from '@/common/util'
import { set } from 'lodash-es'
import { Empty } from 'antd'
export default function Crossbar2(props) {
  const {
    division,
    years,

    months,
    productLine,
    sort,
    unit,

    mediaChannel,

    media,

    mediaFormat,

    title = '',

    mediaGroup,
    get,
    setDivision,

    categorycorp,
    setcategorycorp,
    campaign,
    yearsopt,
  } = props

  const radioOption = [
    {
      label: 'Division',
      value: 'division',
    },
    {
      label: 'Category',
      value: 'categorycorp',
    },
  ]
  const [group, setGroup] = useState(radioOption[0].value)

  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)

  let checkGrouop = undefined
  const changePieName = (val) => {
    let g = localStorage.getItem('pie2Group')
    checkGrouop = val === checkGrouop ? undefined : val
    if (g === 'division') setDivision(checkGrouop ? [checkGrouop] : [])
    else setcategorycorp(checkGrouop ? [checkGrouop] : [])
  }

  const { updateCharts } = useCharts(trend, { changePieName })
  const [legendselect, setlegendselect] = useState([])
  const [legendCount, setLegendCount] = useState(0)
  const [checkAllVal, setcheckAllVal] = useState(1)
  const onChange = ({ target: { value } }) => {
    setcheckAllVal(value)
  }
  const onChange2 = ({ target: { value } }) => {
    localStorage.setItem('pie2Group', value)
    setGroup(value)
  }

  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const init = () => {
    let filters = []
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      })

    if (!mediaChannel.includes('all'))
      filters.push({
        field: 'mediaChannel',
        operator: 'IN',
        values: mediaChannel,
      })

    if (!mediaFormat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaFormat,
      })
    if (!mediaGroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })

    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })

    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })

    if (!campaign.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaign,
      })

    if (!division.includes('all'))
      filters.push({
        field: 'division',
        operator: 'IN',
        values: division,
      })

    if (!categorycorp.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: categorycorp,
      })

    getBudgetSum3({
      title: 'Budget Amount By ' + group,
      fields: ['budget', 'minyear', 'minmonth', 'months'],
      groups: [group],
      sorts: sort,
      // sorts: [
      //   {
      //     field: 'budgetamount',
      //     type: 'ASC',
      //   },
      // ],
      filters,
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      const groups = radioOption.map((i) => {
        return i.value
      })
      for (let key in res[0]) {
        if (groups.includes(key)) {
          let data = getTrendPieChartsOption3(
            res,
            {
              xname: key,
              years: years.includes('all') ? yearsopt : years,
            },
            unit
          )
          setLegendCount(data.series[0].data.length)
          let selected = getLegendSelected(
            data.series[0].data.map((i) => {
              return i.name
            }),
            checkAllVal
          )

          data.legend.selected = selected

          updateCharts(data)
        }
      }
    })
  }
  useEffect(() => {
    localStorage.setItem('pie2Group', group)
    init()
  }, [years, months, get, unit, group])
  useEffect(() => {
    if (checkAllVal < 2) init()
  }, [checkAllVal])

  const [first, setFirst] = useState(true)
  useEffect(() => {
    if (!first) {
      if (legendselect.length === 0) {
        setcheckAllVal(0)
      } else if (legendselect.length != legendCount) {
        setcheckAllVal(2)
      } else {
        setcheckAllVal(1)
      }
    }
    setFirst(false)
  }, [legendselect])
  return (
    <div className="trend-wrap">
      <div className="title">{title + (group === 'division' ? ' Division' : 'Category')}</div>
      <div className="tool">
        <div style={{ marginRight: '24px' }}>
          <Radio.Group
            options={radioOption}
            onChange={onChange2}
            size="small"
            value={group}
            optionType="button"
          />
        </div>
        {/* <div style={{ marginRight: '24px' }}>
          <Radio.Group
          size="small"
            options={legendOptions}
            onChange={onChange}
            value={checkAllVal}
            optionType="button"
          />
        </div> */}
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          width: 'calc(100%)',
          height: '320px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}

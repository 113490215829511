import React from 'react'
import Router from './router'
import './App.css'
import { useEffect } from 'react'
import { watermark } from './common/watermark'
import { store } from '@/redux'
import { useLocation } from 'react-router-dom'
const App = () => {
  let location = useLocation()
  useEffect(() => {
    const isLogin = location.pathname === '/login'
    setTimeout(() => {
      watermark({ content: isLogin ? '' : store.getState().auth.username })
    })
  }, [location])
  return (
    <div className="App">
      <div className="watermark"></div>
      <Router></Router>
    </div>
  )
}
export default App

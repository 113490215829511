import { Button, Checkbox, Form, Input, message } from 'antd'
import React, { useEffect } from 'react'
import styles from './index.module.less'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { login } from '../../api/home'
import { setToken, requestYears, setAuth } from '../../redux/actions'
import { connect } from 'react-redux'
import { store } from '@/redux'
const { wrap } = styles
const Login = (props) => {
  console.log(props)
  const navigate = useNavigate()
  useEffect(() => {
    const auth = store.getState().auth
    if (auth.autologin) {
      onFinish({
        username: auth.username,
        password: auth.password,
        autologin: auth.autologin,
      })
    }
  }, [])

  const onFinish = ({ username, password, autologin }) => {
    login({
      username: username,
      userpwd: password,
      pid: 31,
    }).then(async (res) => {
      if (
        !res.customers.length ||
        !res.customers.filter((i) => {
          return i.cid === 17195
        }).length
      ) {
        return message.warning('该账号没有客户权限，请联系管理员!')
      }
      props.setToken(res.usertoken)
      props.setAuth({
        autologin,
        username,
        password,
        roleId: res.customers.filter((i) => {
          return i.cid === 17195
        })[0]?.roleId,
        name: res.customers.filter((i) => {
          return i.cid === 17195
        })[0]?.name,
      })
      await props.requestYears('crossbudget')
      navigate('/cross/budget')
    })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className={wrap}>
      <div className="title">Dashboard</div>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          autologin: store.getState().auth.autologin,
          // autologin: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input className="input" placeholder="账户" prefix={<UserOutlined />} size="large" />
        </Form.Item>

        <Form.Item
          className="input"
          name="password"
          style={{ marginBottom: '16px' }}
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password
            className="input"
            placeholder="密码"
            prefix={<LockOutlined />}
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="autologin"
          style={{ marginBottom: '16px' }}
          valuePropName="checked"
          wrapperCol={{
            // offset: 2,
            span: 16,
          }}
        >
          <Checkbox className="rem">自动登录</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="btn">
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default connect(null, { setToken, requestYears, setAuth })(Login)

import { getBudgetSum } from '@/api/home.js'
import { useEffect, useState } from 'react'
// import SingleDate from './singleDate'
import { Select } from 'antd'
import { connect } from 'react-redux'
const CombineDate = (props) => {
  const { Option } = Select
  const { val, onChange, years } = props

  const handleChange1 = (v) => {
    // 改第二个选择框的值以及选择框的范围
    console.log('year[0] change!')
    const index = years.findIndex((i) => i === v)
    props.onChange([v, years[index - 1]]) //这是不是改用默认第一项
  }
  const handleChange2 = (v) => {
    onChange([val[0], v])
  }
  const selectOpt1 = years.map((i, index) => {
    return index === 0 ? (
      <Option value={i} key={i} disabled>
        {i}
      </Option>
    ) : (
      <Option value={i} key={i}>
        {i}
      </Option>
    )
  })
  const selectOpt2 = years
    .filter((y) => y < val[0])
    .map((i) => {
      return (
        <Option value={i} key={i}>
          {i}
        </Option>
      )
    })
  return (
    <div style={{ display: 'flex' }}>
      <div>
        <div>Selected Period</div>
        <Select
          value={val[0]}
          style={{ width: 120, marginRight: '8px' }}
          onChange={handleChange1}
          getPopupContainer={(n) => n.parentNode}
        >
          {selectOpt1}
        </Select>
      </div>
      <div>
        <div> Compared Period</div>
        <Select
          value={val[1]}
          style={{ width: 120, marginRight: '8px' }}
          onChange={handleChange2}
          getPopupContainer={(n) => n.parentNode}
        >
          {selectOpt2}
        </Select>
      </div>
    </div>
  )
}
export default connect((state) => ({ years: state.years }), null)(CombineDate)

import { connect } from 'react-redux'
import { useEffect, useState, useImperativeHandle, useMemo } from 'react'
import { getIndustrySum, getIndustryProduct } from '@/api/home'
import CombineDate from '@/components/combineDate'
import { Divider, Checkbox, Select, Space, Button } from 'antd'
import { formatmonth } from './util'
import { divisionOption } from '@/common/vars'
const Filters = (props) => {
  const {
    onChange,
    val,
    currentYear,
    yearsOpt,
    product,
    preYear,
    defaultOpt,
    getRank,
    defaultSelectAllProd,
  } = props
  let curdivision = val.division
  let curaxis = val.category
  const [brandopt, setBrandopt] = useState([])
  const [productopt, setProductopt] = useState([])
  const [axisOpt, setAxisOpt] = useState([{ label: 'FA', value: 'FA' }])
  const divisionData = divisionOption
  const monthopt = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => ({
    value: i,
    label: formatmonth(i),
  }))
  const axisData = {
    CORPORATE: ['CORPORATE'],
    FA: ['FA'],
    FBP: ['FRA', 'MU', 'SK'],
    WFJ: ['JEWERY', 'WATCH'],
  }

  const getbrandopt = (axis, init = false) => {
    onChange('brand', [])
    if (axis.length === 0) return
    return getIndustrySum({
      groups: ['brand'],
      sorts: [
        {
          field: 'brand',
          type: 'ASC',
        },
      ],
      filters: axis
        ? [
            {
              field: 'axis',
              operator: 'IN',
              values: axis,
            },
          ]
        : undefined,
    }).then((res) => {
      setBrandopt(res.map((i) => ({ label: i.brand, value: i.brand })))
      setTimeout(() => {
        let newbrand = res.map((i) => i.brand)
        if (init) newbrand = ['CHANEL']
        handleBrandChange(newbrand)
      })
    })
  }
  const getproductopt = (brand) => {
    onChange('product', [])
    if (brand.length === 0) return
    return getIndustryProduct({
      groups: ['product'],
      filters: [
        {
          field: 'brand',
          operator: 'IN',
          values: brand,
        },
        {
          field: 'division',
          operator: 'IN',
          values: [curdivision],
        },
        {
          field: 'axis',
          operator: 'IN',
          values: curaxis,
        },
      ],
      sorts: [
        {
          field: 'product',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      setProductopt(res.map((i) => ({ label: i.product, value: i.product })))
      // 这里做下特殊处理因为种类太多了 默认全部选上
      if (defaultSelectAllProd) {
        onChange(
          'product',
          res.map((i) => i.product)
        )
      }
    })
  }
  const selectallbrand = (e) => {
    if (e.target.checked) {
      handleBrandChange(brandopt.map((i) => i.value))
      // onChange('brand', brandopt.map(i=>i.value))
    } else {
      // onChange('brand', [])
      handleBrandChange([])
    }
  }
  const selectallprod = (e) => {
    if (e.target.checked) {
      onChange(
        'product',
        productopt.map((i) => i.value)
      )
    } else {
      onChange('product', [])
    }
  }
  // 初始化 brand列表
  useEffect(() => {
    getbrandopt(['FA'], true)
    getproductopt(['CHANEL'], 'FA', ['FA'])
  }, [])

  const getAxisOpt = (division) => {
    onChange('category', [])
    setTimeout(() => {
      // onChange('category', axisData[division])
      handleCategoryChange(axisData[division])
    })
    return axisData[division].map((i) => {
      return {
        value: i,
        label: i,
      }
    })
  }

  const handleBrandChange = (brand) => {
    onChange('brand', brand) //通知父组件更改brand
    getproductopt(brand)
  }
  const handleProdChange = (product) => {
    onChange('product', product)
  }
  const handleDivisionChange = (division) => {
    onChange('division', division) //通知父组件更改division
    setAxisOpt(getAxisOpt(division))
    curdivision = division
  }
  const handleCategoryChange = async (category) => {
    onChange('category', category) //通知父组件更改category
    curaxis = category
    getbrandopt(category)
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', height: '56px' }}>
        <Select
          getPopupContainer={(n) => n.parentNode}
          showArrow
          style={{ marginRight: '8px', minWidth: '120px' }}
          options={yearsOpt}
          mode="multiple"
          value={val.year}
          onChange={(year) => onChange('year', year)}
        ></Select>

        {val.month ? (
          <Select
            getPopupContainer={(n) => n.parentNode}
            maxTagCount={0}
            showArrow
            style={{ marginRight: '8px', minWidth: '120px' }}
            options={monthopt}
            mode="multiple"
            value={val.month}
            onChange={(month) => onChange('month', month)}
          ></Select>
        ) : null}
        <Select
          getPopupContainer={(n) => n.parentNode}
          style={{ width: '150px' }}
          options={divisionData.map((i) => ({ label: i, value: i }))}
          value={val.division}
          onChange={handleDivisionChange}
        ></Select>
        <Select
          getPopupContainer={(n) => n.parentNode}
          allowClear
          style={{ marginLeft: '8px', minWidth: '150px' }}
          showArrow
          maxTagCount={1}
          options={axisOpt}
          mode="multiple"
          value={val.category}
          onChange={handleCategoryChange}
        ></Select>
        <Select
          getPopupContainer={(n) => n.parentNode}
          allowClear
          showArrow
          maxTagCount={0}
          options={brandopt}
          mode="multiple"
          value={val.brand}
          style={{ minWidth: '150px', marginLeft: '8px' }}
          onChange={handleBrandChange}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: '2px 0',
                }}
              />
              <Checkbox
                checked={val.brand.length === brandopt.length}
                onChange={selectallbrand}
                style={{ marginLeft: '12px' }}
              >
                全选
              </Checkbox>
            </>
          )}
        ></Select>
        <Select
          getPopupContainer={(n) => n.parentNode}
          allowClear
          showArrow
          maxTagCount={0}
          options={productopt}
          mode="multiple"
          value={val.product}
          style={{ minWidth: '150px', marginLeft: '8px' }}
          onChange={handleProdChange}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: '2px 0',
                }}
              />
              <Checkbox
                checked={val.product.length === productopt.length}
                onChange={selectallprod}
                style={{ marginLeft: '12px' }}
              >
                全选
              </Checkbox>
            </>
          )}
        ></Select>
      </div>
    </>
  )
}
export default connect((state) => ({
  yearsOpt: state.years.map((i) => ({ label: i, value: i })),
  currentYear: state.years[state.years.length - 1],
  preYear: state.years[state.years.length - 2],
}))(Filters)

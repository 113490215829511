import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import SeSelector from './seSelector'
import { redfeedsum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendPieChartsOption, formatMillion } from './util'
import { mediaFormatOption } from '@/common/vars'
import { Empty } from 'antd'

export default function TrendPie(props) {
  const {
    title,
    division,
    yearsopt,
    years,
    subcag,
    cag,
    yname,
    treeopt,
    productOpt,
    mediaformat,
    months,
    dataViewHeaderFirstTitle,
    unit,
    // argsfn,
    color,
    // color1,
  } = props
  const trend = useRef(null)
  const { updateCharts } = useCharts(trend)
  const [empty, setEmpty] = useState(false)
  const [lastres, setlastres] = useState(null)
  const formatPieData = (data, key) => {
    const res = {}
    data.forEach((i) => {
      const name = i[key]
      if (!res[name]) res[name] = { year: [], budget: [] }
      if (i.year === years[0] && res[name].year.length === 0) {
        // 补一下前年没值的问题
        res[name].year.push(years[1])
        res[name].budget.push(0)
      }
      res[name].year.push(i.year)
      res[name].budget.push(formatMillion(i.budget))
    })
    return res
  }
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const fn = (res) => {
    const keyname = ['assettype'] //可能的keyname
    for (let key in res[0]) {
      if (keyname.includes(key)) {
        return {
          dataViewHeaderFirstTitle,
          color,
          unit,
          yname,
        }
      }
    }
  }
  useEffect(() => {
    if (JSON.stringify(treeopt) === '{}') return
    // debugger
    redfeedsum({
      //division
      fields: ['cost'],
      groups: ['assettype'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years.includes('all') ? yearsopt : years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.includes('all')
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            : months.map((i) => map.indexOf(i)),
        },
        {
          field: 'productline',
          operator: 'IN',
          values: subcag.includes('all') ? treeopt['productline'] : subcag,
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: cag.includes('all') ? treeopt['categorycorp'] : cag,
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: mediaformat.includes('all')
            ? mediaFormatOption.map((it) => {
                return it.value
              })
            : mediaformat,
        },
        {
          field: 'mediagroup',
          operator: 'IN',
          values: ['Red'],
        },
        // {
        //   field: 'mediaformat',
        //   operator: 'IN',
        //   values: ['RTB Feeds', 'GD Feeds'],
        // },
      ],
      // sorts: [
      //   {
      //     field: 'year',
      //     type: 'ASC',
      //   },
      //   {
      //     field: 'division',
      //     type: 'ASC',
      //   },
      // ],
    }).then((res) => {
      // debugger
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      setlastres(res)
      updateCharts(getTrendPieChartsOption(res, fn(res)))
    })
  }, [years, months, cag, subcag, treeopt, mediaformat])
  useEffect(() => {
    if (!lastres) return
    // console.log('2.2 第二个图 unit changge rerender',unit)
    const newopt = getTrendPieChartsOption(lastres, fn(lastres))
    console.log(newopt)
    updateCharts(newopt)
  }, [unit])
  return (
    <div className="trend-wrap">
      <div className="title">
        <div>{title}</div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          position: 'relative',
          width: '100%',
          height: '320px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}

import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Empty, Radio } from 'antd'
import { getSum1 } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendBarChartsOption, formatMillion } from './util'
import { legendOptions } from '@/common/vars'
import { formatNum, getLegendSelected } from '@/common/util'
export default function TrendDivisionAndYear(props) {
  const {
    mafield,
    fields = 'cost',
    ysval,
    title,
    setShowEmpty,
    division,
    yearsopt,
    years,
    months,
    axis,
    axisOpt,
    product,
    unit,
    valname,
    productOpt,
    dataViewHeaderFirstTitle,
    formatdatafn,
    formatpercent,
    checkProductLine,
  } = props
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend)
  const [lastres, setlastres] = useState(null)
  const [average, setAverage] = useState([])
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  /**
   * catogeryname 区分的类别名  比如 fa fbp
   * valname 具体显示的值 比如budget
   */

  const formatP = (val) => {
    return val
  }
  const formatBarData = (data, catogeryname, valname) => {
    // debugger
    const years2 = [...new Set(data.map((i) => i.year))]
    years2.sort((a, b) => a - b)

    const xData = [...new Set(data.map((i) => i.month))] // [1 ,2]
    const yData = []

    years2.forEach((year) => {
      const res = []
      xData.forEach((name) => {
        let item = data.filter((i) => i[catogeryname] === name && i.year === year)[0]
        res.push(item ? (formatdatafn ? item[valname] : formatNum(item[valname], unit, 0)) : 0)
      })
      yData.push(res)
    })

    const xData1 = []
    years2.forEach((y) => {
      xData1.push(...xData.map((i) => `${i}-${y.toString().slice(2)}`))
    })

    // const ynames = []
    // years.forEach((y) => {
    //   ynames.push(...months.map((i) => `${map[i]}-${y.toString().slice(2)}`))
    // })
    // debugger
    const ynames = ['汇总']
    return {
      xData: xData1, //[3,2]      【m1,m2,n3】
      yData, // [[2000,2500],[500,700],[[300,200]]] [[2000,500,300] [...],[],[]] //4个yname的xdata值
      ynames, //[n1 m2 m3]  [2-23,3-23，2-21，3-21]
      dataViewHeaderFirstTitle,
    }
  }

  useEffect(() => {
    if (!axisOpt.length) {
      return setAverage([])
    }
    if (!productOpt.length) {
      return setAverage([])
    }

    let filters = [
      {
        field: 'year',
        operator: 'IN',
        values: years.includes('all') ? yearsopt : years,
      },
      {
        field: 'month',
        operator: 'IN',
        values: months.includes('all')
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
          : months.map((i) => map.indexOf(i)),
      },

      {
        field: 'categorycorp',
        operator: 'IN',
        values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
      },
      {
        field: 'mediagroup',
        operator: 'IN',
        values: ['Tencent'],
      },
      {
        field: 'mediaformat',
        operator: 'IN',
        values: ['Moments RTB'],
      },
    ]
    if (checkProductLine && checkProductLine.length > 0) {
      filters.push({
        field: 'productline',
        operator: 'IN',
        values: [checkProductLine],
      })
    } else {
      filters.push({
        field: 'productline',
        operator: 'IN',
        values: product.includes('all') ? productOpt.slice(1).map((i) => i.value) : product,
      })
    }
    getSum1({
      fields: fields.includes(',') ? fields.split(',') : [fields],
      groups: ['month', 'year'],
      title,
      filters,
      sorts: [
        {
          field: 'month',
          type: 'ASC',
        },
        // {
        //   field: 'division',
        //   type: 'ASC',
        // },
      ],
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      // const res1 = JSON.parse(JSON.stringify(res))
      // res1.forEach((i) => {
      //   i.cost = i.cost * 1.3
      //   i.month = 3
      // })
      // res = [...res1, ...res]
      // const res2 = JSON.parse(JSON.stringify(res))
      // res2.forEach((i) => {
      //   i.cost = i.cost * 1.1
      //   i.year = 2021
      // })
      // res = [...res2, ...res]
      // debugger
      if (formatdatafn) {
        res.map(formatdatafn)
      }

      const formatdata = formatBarData(res, 'month', formatdatafn ? 'p' : valname) //p是格式化的变量名字 上行代码提供
      formatdata.stack = false
      formatdata.formatpercent = formatpercent

      let data = getTrendBarChartsOption(formatdata, unit)

      updateCharts(data)
    })
  }, [years, months, division, axis, axisOpt, product, productOpt, ysval, unit, checkProductLine])

  useEffect(() => {
    if (!lastres) return

    const formatdata = formatBarData(lastres, 'month', formatdatafn ? 'p' : valname)
    formatdata.stack = false
    formatdata.formatpercent = true
    let data = getTrendBarChartsOption(formatdata, unit)
    updateCharts(data)
  }, [unit, checkProductLine])

  //求平均值
  useEffect(() => {
    if (!axisOpt.length) return
    if (!productOpt.length) return
    if (!mafield) return
    let filters = [
      {
        field: 'year',
        operator: 'IN',
        values: years.includes('all') ? yearsopt : years,
      },
      {
        field: 'month',
        operator: 'IN',
        values: months.includes('all')
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
          : months.map((i) => map.indexOf(i)),
      },

      {
        field: 'categorycorp',
        operator: 'IN',
        values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
      },
      {
        field: 'mediagroup',
        operator: 'IN',
        values: ['Tencent'],
      },
      {
        field: 'mediaformat',
        operator: 'IN',
        values: ['Moments RTB'],
      },
    ]
    if (checkProductLine && checkProductLine.length > 0) {
      filters.push({
        field: 'productline',
        operator: 'IN',
        values: [checkProductLine],
      })
    } else {
      filters.push({
        field: 'productline',
        operator: 'IN',
        values: product.includes('all') ? productOpt.slice(1).map((i) => i.value) : product,
      })
    }
    getSum1({
      fields: mafield,
      groups: ['year'],
      filters,
    }).then((res) => {
      if (!res || !Array.isArray(res) || !res.length) {
        return setAverage([])
      }
      // console.log('平均值',res)
      // if(mafield.length===1){
      const avetext = res.map(
        (i) =>
          `${i.year}YTD均值：${
            formatpercent ? i[mafield[0]] * 100 + '%' : i[mafield[0]].toFixed(2)
          }`
      )
      setAverage(avetext)
    })
  }, [years, months, division, axis, axisOpt, product, productOpt, ysval, checkProductLine])

  return (
    // <div className="trend-wrap">
    //   <div ref={trend} style={{ width: '100%', height: '320px' }}></div>
    // </div>
    <div className="trend-wrap">
      <div className="title">
        <div>
          {title} <span style={{ fontSize: '10px', color: '#333' }}>{average.join(',')}</span>
        </div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          position: 'relative',
          width: '100%',
          height: '360px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}

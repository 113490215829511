import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Empty, Radio } from 'antd'
import { getSum1 } from '../../api/home'
import { legendOptions } from '@/common/vars'
import { useCharts } from '@/hooks/useCharts'
import { getTrendBarChartsOption, formatMillion } from './util'
import { formatNum, getLegendSelected } from '@/common/util'
export default function TrendDivisionAndYear(props) {
  const {
    fields = 'cost',
    title,
    setShowEmpty,
    division,
    years,
    yearsopt,
    months,
    axis,
    axisOpt,
    product,
    ysval,
    productOpt,
    formatpercent = false,
    unit,
    setLegendProductLine,
    dataViewHeaderFirstTitle,
  } = props
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const [legendselect, setlegendselect] = useState([])
  const [legendCount, setLegendCount] = useState(0)
  const { updateCharts } = useCharts(trend, {
    setClickbrand: setLegendProductLine,
    legendselectchanged: setlegendselect,
  })
  const [lastres, setlastres] = useState(null)
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  /**
   * catogeryname 区分的类别名  比如 fa fbp
   * valname 具体显示的值 比如budget
   */
  const formatBarData = (data, catogeryname, valname, formatpercent) => {
    const years2 = [...new Set(data.map((i) => i.year))] // [2021 ,2022]
    years2.sort((a, b) => a - b)
    const xData = [...new Set(data.map((i) => i.month))] // [1 ,2]
    const ynames = [...new Set(data.map((i) => i[catogeryname]))] // [fa,fbp]
    const yData = [] //[[fa-21-budget,fa-22-budget,name:fa],[fbp-21-budget,fbp-22-budget,name:fbp]]
    const yData2 = []
    ynames.forEach((name) => {
      const res = []
      const res2 = []
      years2.forEach((year) => {
        xData.forEach((month) => {
          let item = data.filter(
            (i) => i.month === month && i[catogeryname] === name && i.year === year
          )[0]
          res2.push(item)
          res.push(item ? Number(formatNum(item[valname], unit, 0)) : 0)
        })
      })

      yData.push(res)
      yData2.push(res2)
    })

    // })
    const xData1 = []
    years2.forEach((y) => {
      xData1.push(...xData.map((i) => `${i}-${y.toString().slice(2)}`))
    })

    if (formatpercent) {
      const sum = []
      const len = yData[0].length
      for (let i = 0; i < len; i++) {
        sum[i] = yData.reduce((pre, cur) => {
          pre = pre + cur[i]

          return pre
        }, 0)
      }

      yData.forEach((i) => {
        sum.forEach((s, index) => {
          i[index] = ((i[index] / s) * 100).toFixed(2)
        })
      })
    }

    // debugger
    return {
      xData: xData1,
      yData,
      ynames,
      formatpercent,
      dataViewHeaderFirstTitle,
    }
  }

  const [checkAllVal, setcheckAllVal] = useState(1)
  const onChange = ({ target: { value } }) => {
    setcheckAllVal(value)
  }

  const init = () => {
    if (!axisOpt.length) return
    if (!productOpt.length) return
    getSum1({
      fields: fields.includes(',') ? fields.split(',') : [fields],
      title,
      groups: ['month', 'productline', 'year'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years.includes('all') ? yearsopt : years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.includes('all')
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            : months.map((i) => map.indexOf(i)),
        },
        {
          field: 'productline',
          operator: 'IN',
          values: product.includes('all') ? productOpt.slice(1).map((i) => i.value) : product,
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
        },
        {
          field: 'mediagroup',
          operator: 'IN',
          values: ['Tencent'],
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: ['Moments RTB'],
        },
      ],
      sorts: [
        {
          field: 'month',
          type: 'ASC',
        },
        // {
        //   field: 'division',
        //   type: 'ASC',
        // },
      ],
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      setlastres(res)
      // debugger
      const formatdata = formatBarData(res, 'productline', fields, formatpercent)
      let data = getTrendBarChartsOption(
        formatdata,
        title === 'Cost share By Month & Productline' ? undefined : unit
      )
      setLegendCount(data.series.length)
      let selected = getLegendSelected(
        data.series.map((i) => {
          return i.name
        }),
        checkAllVal
      )

      data.legend.selected = selected
      // if(setLegendProductLine){
      // let temp=[]
      // for(var i in selected){
      //    if(selected[i])
      //    {
      //     temp.push(i)
      //    }

      //   }

      // setLegendProductLine(temp)
      // }
      updateCharts(data)
    })
  }
  useEffect(() => {
    init()
  }, [years, months, division, axis, axisOpt, product, productOpt, ysval])

  useEffect(() => {
    if (!lastres) return //没有res的时候应该要等一个res再执行,将res放入依赖中
    // console.log('2.2 第二个图 unit changge rerender',unit)
    const newopt = getTrendBarChartsOption(
      formatBarData(lastres, 'productline', fields, formatpercent),
      title === 'Cost share By Month & Productline' ? undefined : unit
    )
    let selected = getLegendSelected(
      newopt.series.map((i) => {
        return i.name
      }),
      checkAllVal
    )
    setLegendCount(newopt.series.length)
    newopt.legend.selected = selected

    updateCharts(newopt)
  }, [unit, lastres])

  useEffect(() => {
    if (checkAllVal < 2) init()
  }, [checkAllVal])

  const [first, setFirst] = useState(true)
  useEffect(() => {
    if (!first) {
      if (legendselect.length === 0) {
        setcheckAllVal(0)
      } else if (legendselect.length != legendCount) {
        setcheckAllVal(2)
      } else {
        setcheckAllVal(1)
      }
    }
    setFirst(false)
  }, [legendselect])

  // useEffect(() => {
  //   if (!echartsData) return
  //   let selected = getLegendSelected(
  //     echartsData.series.map((i) => {
  //       return i.name
  //     }),
  //     checkAllVal
  //   )
  //   let echartsdata = JSON.parse(JSON.stringify(echartsData))
  //   echartsdata.legend.selected = selected
  //   updateCharts(echartsdata)
  // }, [checkAllVal])
  return (
    // <div className="trend-wrap">
    //   <div ref={trend} style={{ width: '100%', height: '320px' }}></div>
    // </div>
    <div className="trend-wrap">
      <div className="title">
        <div>{title}</div>
        <div style={{ marginRight: '24px' }}>
          <Radio.Group
            options={legendOptions}
            onChange={onChange}
            value={checkAllVal}
            optionType="button"
          />
        </div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          position: 'relative',
          width: '100%',
          marginRight: '24px',
          height: '360px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}

import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

// import { getIndustrySum, getIndustryProduct } from '../../api/home'
import { getPerformanceSum2 } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendLineOption2 } from './util'
import axios from 'axios'
import { Empty } from 'antd'
export default function TrendLineProd(props) {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const { title, years, product, month, field, formatpercent, channel, channelOpt } = props
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend)
  const [average, setAverage] = useState([])
  const getma = async () => {
    return getPerformanceSum2({
      fields: [field],
      groups: ['year'],
      title,
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: month.includes('all') ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] : month,
        },
        {
          field: 'channel',
          operator: 'IN',
          // values: division,
          values: channel.includes('all')
            ? channelOpt.map((i) => {
                return i.value
              })
            : channel,
        },
      ],
    })
  }
  useEffect(() => {
    // if (!brand) return
    if (!product || !product.length || !channelOpt.length) {
      setAverage([])
      return setEmpty(true)
    }
    getPerformanceSum2({
      fields: [field],
      groups: ['year', 'month'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: month.includes('all') ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] : month,
        },
        {
          field: 'channel',
          operator: 'IN',
          // values: division,
          values: channel.includes('all')
            ? channelOpt.map((i) => {
                return i.value
              })
            : channel,
        },
      ],
      sorts: [
        {
          field: 'year',
          type: 'ASC',
        },
        {
          field: 'month',
          type: 'DESC',
        },
      ],
    }).then(async (res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      const ma = await getma()

      const avetext = ma.map(
        (i) => `
        ${i.year} ${field.toLocaleUpperCase()}  YTD均值：${
          formatpercent ? i[field] + '%' : i[field]
        }
        `
      )
      setAverage(avetext)

      const preyear = years[1]
      const curyear = years[0]

      const curyeardata = res.filter((i) => i.year === curyear)
      curyeardata.sort((a, b) => a.month - b.month)
      const preyeardata = res.filter((i) => i.year === preyear)
      preyeardata.sort((a, b) => a.month - b.month)

      // const preyeardata = _preyeardata.slice(0, curyeardata.length)
      updateCharts(
        getTrendLineOption2({ preyeardata, curyeardata, preyear, curyear, field, formatpercent })
      )
    })
    return () => {
      console.log('Operation canceled by the user')
      source.cancel('Operation canceled by the user')
    }
  }, [years, month, channel, channelOpt])

  return (
    <div className="trend-wrap" style={{ position: 'relative' }}>
      {average.length ? (
        <div
          className="average"
          style={{
            position: 'absolute',
            top: '20px',
            left: '15px',
            fontSize: '10px',
            color: '#333',
          }}
        >
          {average.join(',')}
        </div>
      ) : null}
      <div className="title">
        <div>{title}</div>
      </div>

      {empty ? <Empty style={{ marginTop: '2px' }}></Empty> : null}
      <div
        ref={trend}
        style={{ display: empty ? 'none' : 'block', width: '100%', height: '270px' }}
      ></div>
    </div>
  )
}

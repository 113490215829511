import Card from '@/components/Card'
import CombineDate from '@/components/combineDate'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import TrendPerformanceMonth from './trendPerformanceMonth'
import TrendPerformanceMonth2 from './trendPerformanceMonth2'
import TrendLineProd from './trend-line-prod'
import { getPerformanceSum } from '@/api/home'
import TrendDivisionAndYear from './trendDivisionAndYear'
import { Select, Divider } from 'antd'
import { formatmonth } from './util'
import styles from './index.module.less'
import DivisionTable from './divisionTable'
import { getIndustrySum } from '@/api/home'

const Division = (props) => {
  const { currentYear, yearsOpt, preYear, division, unit } = props
  const { title } = styles
  // const [divisionOpts, setDivisionOpts] = useState([])
  // 这里产品要求写死 左边3 右边2 不走请求

  const monthopt = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => ({
    label: formatmonth(i),
    value: i,
  }))
  // const [division,setDivision] = useState('click')
  const { Option } = Select
  const [years, setYears] = useState([currentYear, preYear])
  // const [division, setDivision] = useState(['FA', 'FBP', 'WFJ', 'CORPORATE'])
  const [months, setMonths] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])

  const createopt = (date) => {
    const curmonth = new Date(date).getMonth() + 1
    const opt = []
    for (let i = curmonth; i >= 1; i--) {
      opt.push({
        label: `${formatmonth(i)}-${currentYear.toString().slice(2)}`,
        value: `${i}-${currentYear}`,
      })
    }
    for (let i = 12; i > 0; i--) {
      opt.push({
        label: `${formatmonth(i)}-${(currentYear - 1).toString().slice(2)}`,
        value: `${i}-${currentYear - 1}`,
      })
    }
    for (let i = 12; i > curmonth; i--) {
      opt.push({
        label: `${formatmonth(i)}-${(currentYear - 2).toString().slice(2)}`,
        value: `${i}-${currentYear - 2}`,
      })
    }
    return opt
  }

  const [clicksession, setClicksession] = useState('click')
  const [ymopt, setymopt] = useState([])
  // useEffect(() => setym(ymopt[0].value), [])
  const [ym, setym] = useState()
  const [updatetime, setupdatetime] = useState('')

  const getUpdateTime = () => {
    getIndustrySum({
      title: 'updateTime',
      fields: ['maxyearandmonth'],
      groups: ['year'],
      sorts: [{ field: 'maxyearandmonth', type: 'DESC' }],
    }).then((res_) => {
      let res = res_.sort((a, b) => {
        return b.year - a.year
      })
      let opt = createopt(res[0].maxyearandmonth)
      setupdatetime(res[0].maxyearandmonth)
      setymopt(opt)
      setym(opt[0].value)
    })
  }

  const getmediaopt = () => {
    return getIndustrySum({
      groups: ['media'],
      sorts: [
        {
          field: 'media',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      setMediaopt(res.map((i) => ({ label: i.media, value: i.media })))
      setTimeout(() => {
        const newMedia = res.map((i) => i.media)
        handleMediaChange(newMedia[0])
      })
    })
  }

  useEffect(() => {
    getmediaopt()
    getUpdateTime()
  }, [])
  const [mediaopt, setMediaopt] = useState([])
  const [media, setMedia] = useState([])
  const handleMediaChange = (media) => {
    setMedia([media])
  }
  return (
    <>
      <div>
        <Card
          wrappaddingbottom={false}
          title={
            <div style={{ display: 'flex', margin: '8px 0', alignItems: 'center' }}>
              <div>
                <span className="label" style={{ width: '130px' }}>
                  Media：
                </span>
                <Select
                  getPopupContainer={(n) => n.parentNode}
                  allowClear
                  style={{ margin: '8px', minWidth: '250px' }}
                  showArrow
                  maxTagCount={1}
                  options={mediaopt}
                  value={media}
                  onChange={handleMediaChange}
                ></Select>
              </div>
              <div style={{ marginLeft: '12px' }}>
                <span className="label" style={{ width: '130px' }}>
                  Month：
                </span>
                <Select options={ymopt} value={ym} onChange={setym} style={{ width: '240px' }} />
              </div>
            </div>
          }
          headerLine={true}
          headerFilter={<div>Date update to: {updatetime}</div>}
          // style={{ marginTop: '16px' }}
        ></Card>
        <Card title="FA" wrappaddingbottom={false}>
          <div>
            <div>
              <TrendDivisionAndYear
                axis="FA"
                media={media}
                unit={unit}
                ym={ym}
                division={division}
                style={{}}
              ></TrendDivisionAndYear>
              <DivisionTable
                style={{ marginTop: '12px' }}
                axis="FA"
                unit={unit}
                media={media}
                ym={ym}
                division={['FA']}
              ></DivisionTable>
            </div>
            <Divider unit={unit} style={{ marginBottom: 0 }}></Divider>
          </div>
        </Card>
        <Card title="WFJ" wrappaddingbottom={false}>
          <div>
            <div>
              <TrendDivisionAndYear
                axis="FJ"
                unit={unit}
                media={media}
                ym={ym}
                division={division}
                style={{}}
              ></TrendDivisionAndYear>
              <DivisionTable
                style={{ marginTop: '12px' }}
                axis="FJ"
                media={media}
                ym={ym}
                unit={unit}
                division={['WFJ']}
              ></DivisionTable>
            </div>
            <Divider unit={unit} style={{ marginBottom: 0 }}></Divider>
            <div>
              <TrendDivisionAndYear
                axis="WAT"
                media={media}
                ym={ym}
                unit={unit}
                division={division}
                style={{}}
              ></TrendDivisionAndYear>
              <DivisionTable
                style={{ marginTop: '12px' }}
                axis="WAT"
                media={media}
                ym={ym}
                unit={unit}
                division={['WFJ']}
              ></DivisionTable>
            </div>
            <Divider unit={unit} style={{ marginBottom: 0 }}></Divider>
          </div>
        </Card>

        <Card title="FBP" headerLine={true}>
          <div>
            <DivisionTable
              style={{ marginTop: '12px' }}
              media={media}
              axis="FR"
              unit={unit}
              ym={ym}
              division={['FBP']}
            ></DivisionTable>
          </div>
          <div style={{ marginTop: '24px' }}>
            <DivisionTable
              media={media}
              unit={unit}
              style={{ marginTop: '12px' }}
              axis="MU"
              ym={ym}
              division={['FBP']}
            ></DivisionTable>
          </div>
          <div style={{ marginTop: '24px' }}>
            <DivisionTable
              unit={unit}
              media={media}
              style={{ marginTop: '12px' }}
              axis="SK"
              ym={ym}
              division={['FBP']}
            ></DivisionTable>
          </div>
        </Card>
      </div>
    </>
  )
}

export default connect((state) => ({
  yearsOpt: state.years.map((i) => ({ label: i, value: i })),
  currentYear: state.years[state.years.length - 1],
  preYear: state.years[state.years.length - 2],
  prepreYear: state.years[state.years.length - 3],
  division: state.division,
  unit: state.unit,
}))(Division)

import { dataViewConfigCreator, numberWithCommas } from '@/common/util'
import { themecolor, themecolor_two, themecolor_2 } from '@/common/vars'

export const formatmonth = (i) => {
  const monthname = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return monthname[i]
}
export const unformatmonth = (str) => {
  const monthname = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return monthname.indexOf(str) + 1
}
export const getMonthTrendBarChartsOption = ({ xAxisData, series }) => {
  let seriesData = []
  for (let keyname in series) {
    seriesData.push({
      name: keyname,
      type: 'bar',
      //   stack: 'ad',
      barWidth: '12',
      emphasis: {
        focus: 'series',
      },
      data: series[keyname],
    })
  }
  return {
    legend: { x: 'right' }, //颜色提示
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
      {
        type: 'value',
      },
    ],
    series: seriesData,
  }
}

export const getMonthTrendBarChartsOption2 = ({ xAxisData, barseries, lineseries }) => {
  let seriesData1 = []
  let seriesData2 = []
  for (let keyname in barseries) {
    seriesData1.push({
      name: keyname,
      type: 'bar',
      stack: 'ad',
      barWidth: '12',
      emphasis: {
        focus: 'series',
      },
      data: barseries[keyname],
    })
  }
  for (let keyname in lineseries) {
    seriesData1.push({
      name: keyname,
      type: 'line',
      yAxisIndex: 1,
      emphasis: {
        focus: 'series',
      },
      data: lineseries[keyname],
    })
  }
  return {
    legend: { x: 'right' }, //颜色提示
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
      },
    ],
    yAxis: [
      {
        type: 'value',
        // name: 'Precipitation',
        axisLabel: {
          formatter: '{value} ',
        },
      },
      {
        type: 'value',
        // name: 'Temperature',
        axisLabel: {
          //   formatter: '{value} %'
          formatter: '{value}',
        },
      },
    ],
    series: seriesData1,
  }
}

export const getTrendBarChartsOption_hor = ({ sd1, sd2, preyear, curyear }, config) => {
  const { diffRankMap, curRank, valuename = 'brand' } = config

  const yAxisData = sd2.map((i) => i[valuename])
  // debugger
  const seriesData = []

  seriesData[1] = sd2.map((i) => i.searchindex) // 这个是按y轴去的值 是对的
  //这个应该根据名字去取21的值
  seriesData[0] = yAxisData.reduce((pre, cur) => {
    pre.push(sd1.filter((i) => i[valuename] === cur)[0]?.searchindex)
    return pre
  }, [])
  const desCreator = (name) => {
    const rank = diffRankMap[name]
    if (rank === 0) return '无变化'
    if (rank > 0) return `上升${rank}位`
    if (rank < 0) return `下降${-rank}位`
  }
  return {
    color: themecolor_2,
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const masker0 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${themecolor_two[0]};"></span>`
        const masker1 = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${themecolor_two[1]};"></span>`

        const [
          { name, seriesName: seriesName1, value: value1 },
          { seriesName: seriesName2, value: value2 },
        ] = params
        if (!desCreator(name)) {
          return `${name}: ${seriesName1}排名:${curRank[name] || '--'}位 </br>
          ${masker0} ${seriesName1}: ${numberWithCommas(
            Number(value1 || 0)
          )}</br>${masker1} ${seriesName2}: ${numberWithCommas(Number(value2 || 0))}
          `
        }
        return `${name}: ${seriesName1}排名:${
          curRank[name] || '--'
        }位，较${seriesName2}${desCreator(name)}</br>
        ${masker0} ${seriesName1}: ${numberWithCommas(
          Number(value1 || 0)
        )}</br>${masker1} ${seriesName2}:  ${numberWithCommas(Number(value2 || 0))}
        `
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {},
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          console.log(opt)
          var axisData = opt.yAxis[0].data
          var series = opt.series
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Brand</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series.map((j) => `<td>${numberWithCommas(j.data[i] || 0)}</td>`).join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
      axisLabel: {
        //  X 坐标轴标签相关设置
        interval: 0,
        rotate: '45',
      },
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
    },
    series: [
      {
        name: curyear,
        type: 'bar',
        // barWidth: '12',
        barMinWidth: 6,
        barMaxWidth: 16,
        data: seriesData[1],
      },
      {
        name: preyear,
        type: 'bar',
        // barWidth: '12',
        barMaxWidth: 16,
        barMinWidth: 6,
        data: seriesData[0],
      },
    ],
    dataZoom: [
      {
        type: 'slider',
        maxValueSpan: 9, //显示数据的条数(默认显示10个)
        minValueSpan: 9, //显示数据的条数(默认显示10个)
        show: true,
        yAxisIndex: [0],
        left: '93%', //滑动条位置
        start: 100, //默认为0
        end: 90, //默认为100
        orient: 'vertical',
        filterMode: 'empty',
        // zoomLock:true,
      },
      {
        type: 'inside', //内置滑动，随鼠标滚轮展示
        yAxisIndex: [0],
        zoomOnMouseWheel: false, //如何触发缩放。可选值为：true：表示不按任何功能键，鼠标滚轮能触发缩放。false：表示鼠标滚轮不能触发缩放。'shift'：表示按住 shift 和鼠标滚轮能触发缩放。'ctrl'：表示按住 ctrl 和鼠标滚轮能触发缩放。'alt'：表示按住 alt 和鼠标滚轮能触发缩放。。
        moveOnMouseMove: true,
        moveOnMouseWheel: true, //鼠标滚轮实现移动
      },
    ],
  }
}
export const getTrendLineOption = ({ preyeardata, curyeardata, preyear, curyear }) => {
  // const series1 = preyeardata.map((i) => i.searchindex)
  const series1 = new Array(12).fill(0)
  preyeardata.forEach((i) => {
    const index = i.month - 1
    series1[index] = i.searchindex
  })

  // debugger
  const series2 = curyeardata.map((i) => i.searchindex)

  const xAxisData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => formatmonth(i))

  return {
    color: themecolor_2,
    tooltip: {
      trigger: 'axis',
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          console.log(opt)
          var axisData = opt.xAxis[0].data
          var series = opt.series
          console.log(axisData, series)
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Month</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series.map((j) => `<td>${numberWithCommas(j.data[i] || 0)}</td>`).join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: {
      data: [preyear + '', curyear + ''],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    // toolbox: {
    // feature: {
    //   saveAsImage: {},
    // },
    // },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: curyear,
        type: 'line',
        data: series2,
        smooth: true,
      },
      {
        name: preyear,
        type: 'line',
        smooth: true,
        data: series1,
      },
    ],
  }
}

export const getTrendLineOption1 = ({ xAxisData, seriesData, brand }) => {
  const series = Object.keys(seriesData).map((name) => ({
    name,
    type: 'line',
    smooth: true,
    data: seriesData[name].map((i) => i.searchindex),
  }))

  const res = {
    tooltip: {
      trigger: 'axis',
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          console.log(opt)
          var axisData = opt.xAxis[0].data
          var series = opt.series
          console.log(axisData, series)
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Month</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series.map((j) => `<td>${numberWithCommas(j.data[i] || 0)}</td>`).join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: {
      // type: 'scroll', // 图例优化
      //   orient: 'vertical',
      // right: 10,
      // top: 20,
      // bottom: 20,
      // data: data.legendData,
      // selected: data.selected // 只配置部分图例，配置部分选中的
      // data: series.map((i) => i.name),
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
    },
    series,
  }
  if (brand) {
    res.title = {
      subtext: `Brand: ${brand}`,
      left: 'left',
    }
  }
  if (series.length > 8) {
    delete res['legend']
  }
  return res
}

export const getTrendPercentOpt = ({ seriesData }) => {
  return {
    series: [
      {
        type: 'treemap',
        roam: 'false',
        nodeClick: 'false',
        data: seriesData,
        label: {
          show: true,
          formatter: '{b}: {c}%',
        },
        breadcrumb: {
          show: false,
        },
      },
    ],

    tooltip: {
      formatter: '{b}: {c}%',
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          console.log(opt)
          var data = opt.series[0].data
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>品类</td>' +
            '<td>占比</td>' +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = data.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              data[i].name +
              '</td>' +
              '<td>' +
              numberWithCommas(data[i].value || 0) +
              '%</td>' +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
  }
}

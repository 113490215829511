import { post, get } from './index'

export const login = post('/api/platform/auth/login')

// se选项
// export const getSeSelectOpt = post('/api/dv/budget/sum')
// Budget by division + 年趋势
//2.1.1
export const getBudgetSum = post('/api/dv/newbudget/sum') //post('/api/dv/budget/sum')
//1.1
export const getBudgetSum3 = post('/api/dv/newbudget/summonths') //post('/api/dv/budget/sum')
// v2 求年份
//export const getYear = (apiname) => post(`/api/dv/${apiname}/sum`)
export const getYear = (apiname) => post(`/api/dv/feed/sum`)
// v2 getsum cross
export const getSum = post(`/api/dv/newbudget/sum`)
export const summonths2 = post(`/api/dv/feed/summonths`) //1.1加投放月份
export const downTable = post(`/api/dv/newbudget/down`) //透视表下载
// v2 getsum cross
export const getSum2 = post(`/api/dv/newbudget/sum`)
export const getSum1_2 = post(`/api/dv/feed/sum`)
export const getSumTable = post(`/api/dv/feed/sumex`)
export const downTable1_2 = post(`/api/dv/feed/down`)
// v2 getsum performance
//export const getSum3 = post(`/api/dv/crossperformance/sum`)
export const getSum3 = post(`/api/dv/feed/sum`)

// v2 getsum product
export const getSum1 = post(`/api/dv/feed/sum`)
export const getSum1Traffic = post(`/api/dv/wechatrtb/sumcostandtraffic`)

// v2 cross budget table
export const getTableBudgetSum = post(`/api/dv/newbudget/getsumbudgets`)
// v2 corss performance table
export const getTablePerformanceSum = post(`/api/dv/crossperformance/getoverviewsum`)

// performance
export const getPerformanceSum = post('/api/dv/feed/sum')
export const getPerformanceSumx = post('/api/dv/sku/sumx')

export const getPerformanceSum2 = post('/api/dv/newperformance/sum')

// upload
export const upload = post('/api/dv/data/upload')

// industry
// product选项
// export const getIndustryProduct = post('/api/dv/industry2/sum')
// export const getIndustrySum = post('/api/dv/industry/sum')

export const getIndustryProduct = post('/api/dv/newindustry/sum')
export const getIndustrySum = post('/api/dv/newindustry/sum')

export const getIndustrySum2 = post('/api/dv/newindustry/sumbrandsearch')
//  division
export const getDivisionSumByBrand = post('/api/dv/newindustry/sumbybrand')

// v3 wechatfeed
export const wechatsum = post(`/api/dv/feed/sum`)
export const wechatstack = post('/api/dv/wechatfeed/stack')

// v3 red

export const redfeedsum = post('/api/dv/feed/sum')
export const redstack = post('/api/dv/redfeed/stack')
export const downta = post('/api/dv/feed/downta')

//4.0版本新加折柱图
export const sumex = post('/api/dv/newindustry/sumex')

//2.3 cost by campaign
export const feedsumex = post('/api/dv/feed/summonths')

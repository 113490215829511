import {
  dataViewConfigCreator,
  formatUnit,
  formatNum,
  numberWithCommas,
  formatNum2,
} from '@/common/util'
import { divisionOption } from '@/common/vars'
import {
  themecolor,
  themecolor_3,
  themecolor_pie,
  themecolor_pie1,
  themecolor_2,
} from '@/common/vars'
export const formatMillion = (num) => {
  // debugger
  const res = (num / (1000 * 1000))?.toFixed(1)
  return parseFloat(res)
}
export const formatmonth = (i) => {
  const monthname = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return monthname[i]
}

export const addtablekey = (tree) => {
  const fn = (node, lv = 1) => {
    node.key = lv
    if (!node.children) return
    if (!node.children.length) return
    node.children.forEach((i, index) => {
      fn(i, lv + `-${index}`)
    })
  }
  tree.forEach((i, index) => {
    fn(i, index)
  })
}

export const getTrendBarChartsOption = ({ xData, yData, ynames }, unit) => {
  let seriesData = yData.map((datai, index) => {
    return {
      name: ynames[index],
      type: 'bar',
      stack: 'ad',
      barWidth: '16',
      emphasis: {
        focus: 'series',
      },
      data: datai,
    }
  })

  return {
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = opt.xAxis[0].data
          var series = opt.series
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${'Year'}</td>` +
            axisData.map((i) => `<td>${i} Budegt</td><td>${i} Share</td>`).join('') +
            '</tr>'
          // 表格题  先name再data

          const curyearsum = axisData.map((i, index) => {
            let s = series.map((j) => j.data[index])

            return series
              .map((j) => Number(j.data[index]))
              .reduce((pre, cur) => (pre += Number(cur)))
          })

          // debugger
          let totalsum = 0
          for (var i = 0, l = series.length; i < l; i++) {
            const sum = series[i].data.reduce((pre, cur) => (pre += cur))
            totalsum += sum
            // const curyearsum = series.map(j=>j.data[i]).reduce((pre,cur)=>pre+=cur)
            table +=
              '<tr>' +
              '<td>' +
              series[i].name +
              '</td>' +
              series[i].data
                .map(
                  (j, jindex) =>
                    `<td>${numberWithCommas(j)}</td> <td>${
                      (j * 100) / curyearsum[jindex]
                        ? numberWithCommas(Number(((j * 100) / curyearsum[jindex]).toFixed(1)))
                        : 0
                    }%</td>`
                )
                .join('') +
              '</tr>'
          }
          table +=
            '<tr>' +
            '<td>' +
            'Total' +
            '</td>' +
            curyearsum.map((i) => `<td>${formatNum2(i, unit)}</td> <td>100%</td>`).join('') +
            '</tr>'

          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: { right: '50' }, //颜色提示
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const year = params[0].axisValueLabel
        let res = `${year}<br/>`
        let sum = 0
        params.forEach((i) => {
          const { seriesName, value, marker } = i
          sum += Number(value)
          //"<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>"
          res += `<div style="display:flex;width:220px;align-item:center;justify-content:space-between"><span>${marker}${seriesName}: </span><span style="display:inline-block;text-align:right;">${numberWithCommas(
            value
          )}</span> </div>`
        })
        res += `<div style="display:flex;width:100px;align-item:center;justify-content:space-between"><span style="font-weight: 700">Total: </span>${numberWithCommas(
          formatNum2(sum, unit)
        )}</div>`

        return res
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
      },
    ],
    yAxis: [
      {
        name: formatUnit(unit) + '(RMB)',
        type: 'value',
      },
    ],
    series: seriesData,
    color: themecolor_3,
  }
}

export const getMonthTrendBarChartsOption = ({ xAxisData, series, color }, unit) => {
  let seriesData = []
  for (let keyname in series) {
    seriesData.push({
      name: keyname,
      type: 'bar',
      stack: 'ad',
      barWidth: '16',
      emphasis: {
        focus: 'series',
      },
      data: series[keyname],
    })
  }
  return {
    color: color,
    legend: { right: '50' }, //颜色提示
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        const year = params[0].axisValueLabel
        let res = `${year}<br/>`
        let sum = 0
        params.forEach((i) => {
          const { seriesName, value, marker } = i
          sum += value
          //"<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>"
          res += `<div style="display:flex;width:100px;align-item:center;justify-content:space-between"><span>${marker}${seriesName}: </span><span style="display:inline-block;text-align:right;">${value}</span> </div>`
        })
        res += `<div style="display:flex;width:100px;align-item:center;justify-content:space-between"><span style="font-weight: 700">Total: </span>${sum?.toFixed(
          0
        )}</div>`

        return res
      },
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          // console.log(opt)
          // var axisData = opt.xAxis[0].data
          // var series = opt.series
          // console.log(axisData, series)
          // // 表格头  先name再data
          // var table =
          //   '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
          //   '<td>时间</td>' +
          //   series.map((i) => `<td>${i.name}</td>`).join('') +
          //   '</tr>'
          // // 表格题  先name再data
          // for (var i = 0, l = axisData.length; i < l; i++) {
          //   table +=
          //     '<tr>' +
          //     '<td>' +
          //     axisData[i] +
          //     '</td>' +
          //     series.map((j) => `<td>${j.data[i]}</td>`).join('') +
          //     '</tr>'
          // }
          // table += '</tbody></table>'
          // return table
          var axisData = opt.xAxis[0].data
          var series = opt.series
          console.log(axisData, series)
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${'Division'}</td>` +
            axisData.map((i) => `<td>${i} Budget</td><td>${i} Share</td>`).join('') +
            // `<td>总计</td>` +
            '</tr>'
          // 表格题  先name再data
          const curyearsum = axisData.map((i, index) => {
            return series
              .map((j) => j.data[index])
              .reduce((pre, cur) => (pre += cur))
              ?.toFixed(1)
          })
          console.log(curyearsum)
          // debugger
          let totalsum = 0
          for (var i = 0, l = series.length; i < l; i++) {
            const sum = series[i].data.reduce((pre, cur) => (pre += cur))
            totalsum += sum
            // const curyearsum = series.map(j=>j.data[i]).reduce((pre,cur)=>pre+=cur)
            console.log(curyearsum)
            table +=
              '<tr>' +
              '<td>' +
              series[i].name +
              '</td>' +
              series[i].data
                .map(
                  (j, jindex) =>
                    `<td>${j}</td> <td>${((j * 100) / curyearsum[jindex])?.toFixed(1)}%</td>`
                )
                .join('') +
              // `<td>${sum.toFixed(1)}</td>` +
              '</tr>'
          }
          table +=
            '<tr>' +
            '<td>' +
            'Total' +
            '</td>' +
            curyearsum.map((i) => `<td>${i}</td> <td>100%</td>`).join('') +
            // `<td>${totalsum.toFixed(1)}</td>` +
            '</tr>'

          table += '</tbody></table>'
          return table
        }),
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
      },
    ],
    yAxis: [
      {
        name: formatUnit(unit) + '(RMB)',
        type: 'value',
      },
    ],
    series: seriesData,
  }
}

export const getTrendPieChartsOption = (data, config, unit) => {
  const { color, color1, outlabel, inlabel, dataViewHeaderFirstTitle = 'Division' } = config //todo 外圈设定年限文案

  //内圈是大年 2022  外圈是小年 2021
  let seriesData1 = [],
    seriesData2 = []
  for (let divisionName in data) {
    seriesData1.push({ name: divisionName, value: data[divisionName].budgetamount[1] })
    seriesData2.push({ name: divisionName, value: data[divisionName].budgetamount[0] })
  }
  const getInlabeldata = (name) => {
    const budgetamount = data[name].budgetamount
    let sum = 0
    for (let i in data) {
      sum = sum + Number(data[i].budgetamount[1])
    }
    const percent = (Number(budgetamount[1]) / sum) * 100
    const value = Number(budgetamount[1])
    // debugger
    return {
      percent,
      value,
    }
  }
  return {
    color: color,
    animation: false,
    title: {
      subtext: formatUnit(unit) + '(RMB)',
      left: 'left',
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var series = opt.series
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${dataViewHeaderFirstTitle}</td>` +
            `<td>${series[0].name} Budget</td>` +
            `<td>${series[0].name + ' Share'}</td>` +
            `<td>${series[1].name} Budget</td>` +
            `<td>${series[1].name + ' Share'}</td>` +
            '</tr>'
          // 表格题  先name再data
          const namearr = series[0].data.map((i) => i.name)
          const sum22 = series[0].data.reduce((pre, cur) => {
            pre += Number(cur.value)
            return pre
          }, 0)
          const sum21 = series[1].data.reduce((pre, cur) => {
            pre += Number(cur.value)
            return pre
          }, 0)
          for (var i = 0, l = namearr.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              namearr[i] +
              '</td>' +
              `<td>${numberWithCommas(series[0].data[i].value)}</td>` +
              `<td>${
                sum22
                  ? numberWithCommas(Number(((series[0].data[i].value / sum22) * 100).toFixed(2)))
                  : 0
              }%</td>` +
              `<td>${numberWithCommas(series[1].data[i].value)}</td>` +
              `<td>${
                sum21
                  ? numberWithCommas(Number((series[1].data[i].value / sum21) * 100).toFixed(2))
                  : 0
              }%</td>` +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    // tooltip: {
    //   trigger: 'item',
    //   formatter: '{a}: <br /> {b}: {c} ({d}%)',
    // },
    legend: {
      // x: 'bottom',
      bottom: 0,
      animation: false,
    },
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        let { name, value, percent } = params

        return `${name} : ${numberWithCommas(value)}  (${percent}%)`
      },
      //'{a} <br/>{b}: {c} ({d}%)',
    },
    // tooltip: {

    //   formatter: (params,params2) => {
    //     // const year = params[0].axisValueLabel
    //     // let res = `${year}<br/>`
    //     // let sum = 0
    //     // params.forEach((i) => {
    //     //   const { seriesName, value, marker } = i
    //     //   sum += Number(value)
    //     //   //"<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>"
    //     //   res += `<div style="display:flex;width:100px;align-item:center;justify-content:space-between"><span>${marker}${seriesName}: </span><span style="display:inline-block;text-align:right;">${value}</span> </div>`
    //     // })
    //     // res += `<div style="display:flex;width:100px;align-item:center;justify-content:space-between"><span style="font-weight: 700">Total: </span>${sum}</div>`
    //     // console.log(params)
    //      let {value,seriesName,percent,name} =params
    //      console.log(params,params2)
    //     return    `${name} ${seriesName}:${value}  ${percent}%`+<br/>
    //   },
    //   axisPointer: {
    //     type: 'shadow',
    //   },
    // },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
      // tooltip: {
      //   axisPointer: {
      //     animation: false,
      //   },
      // },
    },
    series: [
      {
        // silent: true,
        itemStyle: {},
        emphasis: {
          disabled: false,
          scale: false,
        },

        legendHoverLink: false,
        name: inlabel,
        type: 'pie',
        selectedMode: 'single',
        radius: [0, '40%'],
        label: {
          position: 'inner',
          fontSize: 10,
          overflow: 'none',
          width: 200,
        },
        labelLine: {
          show: false,
        },
        data: seriesData1,
      },
      {
        silent: true,
        emphasis: {
          disabled: false,
          scale: false,
          // itemStyle:{
          //   color:['#F1F1F1','#F00'],
          // }
        },
        // color: ['#557bd5','#99e086','#ffdc6d'],
        itemStyle: {
          color: (args) => {
            const { seriesIndex, dataIndex } = args
            const arr1 = color1
              ? color1
              : [
                  'rgba(84,112,198,0.6)',
                  'rgba(145,204,117,0.6)',
                  'rgba(250,200,88,0.6)',
                  'rgba(238,102,102,0.6)',
                  'rbga(115,192,222,0.6)',
                ]
            return arr1[dataIndex]
          },
        },
        legendHoverLink: false,
        name: outlabel,
        type: 'pie',
        radius: ['50%', '60%'],
        labelLine: {
          length: 2,
        },
        align: 'left',
        label: {
          // formatter: `{outlabel|${outlabel}:} {b|{b} }{c}  {per|{d}%} \n {outlabel|${inlabel}:}{b|{b} }`,
          formatter: function (params) {
            const { name, percent, value } = params
            let value1 = Number(value || 0)

            return `{outlabel_t|${outlabel} :  ${numberWithCommas(
              Number(value1?.toFixed(0) || 0)
            )}  ${percent?.toFixed(2)}%}\n{innerlabel_t|${inlabel} :  ${numberWithCommas(
              Number(getInlabeldata(name).value?.toFixed(0) || 0)
            )}  ${getInlabeldata(name).percent?.toFixed(2)}%}\n`
          },
          rich: {
            outlabel_t: {
              minWidth: 250,
              backgroundColor: themecolor[1],
              color: 'white',
              align: 'left',
              fontSize: 12,
              overflow: 'breakAll',

              fontWeight: 'bold',
              padding: [1, 2],
            },
            innerlabel_t: {
              minWidth: 250,

              overflow: 'breakAll',
              backgroundColor: themecolor[0],
              color: 'white',
              align: 'left',
              fontSize: 12,
              // color: '#4C5058',
              fontWeight: 'bold',
              padding: [1, 2],
            },
            outlabel: {
              color: themecolor[1],
              align: 'left',
              fontSize: 14,
              // color: '#4C5058',
              fontWeight: 'bold',
              padding: [1, 2],
            },
            inlabel: {
              color: themecolor[1],
              align: 'left',
              fontSize: 14,
              // color: '#4C5058',
              fontWeight: 'bold',
              // padding: [0, 4],
            },
            name: {
              color: '#4C5058',
              fontSize: 14,
              // fontWeight: 'bold',
              // lineHeight: 33,
            },
            outper: {
              color: '#fff',
              backgroundColor: themecolor[1],
              padding: [2, 2],
              borderRadius: 4,
            },
            innerper: {
              color: '#fff',
              backgroundColor: themecolor[0],
              padding: [2, 2],
              borderRadius: 4,
            },
          },
        },
        data: seriesData2,
      },
    ],
  }
}

export const getTrendBarChartsOption_hor = (data, config, unit) => {
  const { year } = config //todo 外圈设定年限文案
  //内圈是大年 2022  外圈是小年 2021
  const [curyear, preyear] = config.years
  const yAxisData = data.filter((i) => i.year === curyear).map((i) => i.categorycorp)
  const seriesData = []

  // seriesData[0] = data.filter((i) => i.year === preyear).map((i) => formatMillion(i.budget))
  seriesData[1] = data
    .filter((i) => i.year === curyear)
    .map((i) => formatNum(i.budgetamount, unit, 0))
  seriesData[0] = new Array(yAxisData.length).fill(0)
  data
    .filter((i) => i.year === preyear)
    .forEach((i) => {
      const index = yAxisData.indexOf(i.categorycorp)
      seriesData[0][index] = formatNum(i.budgetamount, unit, 0)
    })
  return {
    color: themecolor_2,
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var series = opt.series
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Year</td>' +
            `<td>${series[0].name} Budget</td>` +
            `<td>${series[0].name + ' Share'}</td>` +
            `<td>${series[1].name} Budget</td>` +
            `<td>${series[1].name + ' Share'}</td>` +
            '</tr>'
          // 表格题  先name再data
          const namearr = opt.yAxis[0].data
          const sum22 = series[0].data.reduce((pre, cur) => {
            pre += Number(cur)
            return pre
          }, 0)
          const sum21 = series[1].data.reduce((pre, cur) => {
            pre += Number(cur)
            return pre
          }, 0)
          for (var i = 0, l = namearr.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              namearr[i] +
              '</td>' +
              `<td>${numberWithCommas(series[0].data[i])}</td>` +
              `<td>${sum22 ? ((series[0].data[i] / sum22) * 100)?.toFixed(1) : 0}%</td>` +
              `<td>${numberWithCommas(series[1].data[i])}</td>` +
              `<td>${sum21 ? ((series[1].data[i] / sum21) * 100)?.toFixed(1) : 0}%</td>` +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    title: {
      subtext: formatUnit(unit) + '(RMB)',
      left: 'left',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      right: '50',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
    },
    series: [
      {
        name: curyear,
        type: 'bar',
        barWidth: '16',
        data: seriesData[1],
      },
      {
        name: preyear,
        type: 'bar',
        barWidth: '16',
        data: seriesData[0],
      },
    ],
  }
}

export const createargs = (args) => {
  const { groups, filters } = args
  return {
    fields: ['budget'],
    groups,
    filters,
    sorts: [
      {
        field: 'year',
        type: 'ASC',
      },
      {
        field: 'divisioncorp',
        type: 'ASC',
      },
    ],
  }
}

export const argscreator = (baseconfig) => {
  return ({ yearVal, division, se }) => {
    const filters = baseconfig.filters

    if (yearVal) {
      const index = filters.findIndex((i) => i.field === 'year')
      filters[index].values = yearVal
    }
    if (division) {
      const index = filters.findIndex((i) => i.field === 'division')
      filters[index].values = division
    }
    if (se) {
      const index = filters.findIndex((i) => i.field === 'media')

      if (Array.isArray(se)) {
        filters[index].values = se
      } else {
        filters[index].values = [se]
      }
    }
    return baseconfig
  }
}

export const pieconfig1 = {
  //divisioncorp
  fields: ['budget'],
  groups: ['year', 'division'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'mediachannel',
      operator: 'IN',
      values: ['Paid Search'],
    },
    {
      field: 'mediaformat',
      operator: 'IN',
      values: ['PPC', 'Brandzone'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'division',
      type: 'ASC',
    },
  ],
}
export const pieconfig2 = {
  //se
  fields: ['budget'],
  groups: ['year', 'media'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'division',
      operator: 'IN',
      values: divisionOption,
    },
    {
      field: 'mediachannel',
      operator: 'IN',
      values: ['Paid Search'],
    },
    {
      field: 'mediaformat',
      operator: 'IN',
      values: ['PPC', 'Brandzone'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'media',
      type: 'ASC',
    },
  ],
}
export const pieconfig3 = {
  //product
  fields: ['budget'],
  groups: ['year', 'mediaformat'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'mediaformat',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'media',
      operator: 'EQUALS',
      values: ['BAIDU'],
    },
    {
      field: 'division',
      operator: 'IN',
      values: divisionOption,
    },
    {
      field: 'mediachannel',
      operator: 'IN',
      values: ['Paid Search'],
    },
    {
      field: 'mediaformat',
      operator: 'IN',
      values: ['PPC', 'Brandzone'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'mediaformat',
      type: 'ASC',
    },
  ],
}

export const pieconfig4 = {
  //fbp - axios
  fields: ['budget'],
  groups: ['year', 'categorycorp'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'categorycorp',
      operator: 'EQUALS',
      values: ['FBP'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'categorycorp',
      type: 'ASC',
    },
  ],
}
export const pieconfig9 = {
  //fbp - axios
  fields: ['budget'],
  groups: ['year', 'categorycorp'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'division',
      operator: 'EQUALS',
      values: ['FBP'],
    },
    {
      field: 'mediachannel',
      operator: 'IN',
      values: ['Paid Search'],
    },
    {
      field: 'mediaformat',
      operator: 'IN',
      values: ['PPC', 'Brandzone'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'categorycorp',
      type: 'ASC',
    },
  ],
}
export const pieconfig5 = {
  //fbp - mediaformat
  fields: ['budget'],
  groups: ['year', 'mediaformat'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'mediaformat',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'mediachannel',
      operator: 'IN',
      values: ['Paid Search'],
    },
    {
      field: 'mediaformat',
      operator: 'IN',
      values: ['PPC', 'Brandzone'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'mediaformat',
      type: 'ASC',
    },
  ],
}
export const pieconfig6 = {
  //wfj - axis
  fields: ['budget'],
  groups: ['year', 'categorycorp'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'division',
      operator: 'EQUALS',
      values: ['WFJ'],
    },
    {
      field: 'mediachannel',
      operator: 'IN',
      values: ['Paid Search'],
    },
    {
      field: 'mediaformat',
      operator: 'IN',
      values: ['PPC', 'Brandzone'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'categorycorp',
      type: 'ASC',
    },
  ],
}
export const pieconfig7 = {
  //wfj - mediaformat
  fields: ['budget'],
  groups: ['year', 'mediaformat'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'mediaformat',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'division',
      operator: 'EQUALS',
      values: ['WFJ'],
    },
    {
      field: 'mediachannel',
      operator: 'IN',
      values: ['Paid Search'],
    },
    {
      field: 'mediaformat',
      operator: 'IN',
      values: ['PPC', 'Brandzone'],
    },
    // {
    //   field: 'mediaformat',
    //   operator: 'EQUALS',
    //   values: ['WFJ'],
    // },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'mediaformat',
      type: 'ASC',
    },
  ],
}

export const pieconfig8 = {
  //fa
  fields: ['budget'],
  groups: ['year', 'mediaformat'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'mediaformat',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'division',
      operator: 'EQUALS',
      values: ['FA'],
    },
    {
      field: 'mediachannel',
      operator: 'IN',
      values: ['Paid Search'],
    },
    {
      field: 'mediaformat',
      operator: 'IN',
      values: ['PPC', 'Brandzone'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'mediaformat',
      type: 'ASC',
    },
  ],
}
export const barhorconfig = {
  // axios
  fields: ['budget'],
  groups: ['year', 'categorycorp'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'mediachannel',
      operator: 'IN',
      values: ['Paid Search'],
    },
    {
      field: 'mediaformat',
      operator: 'IN',
      values: ['PPC', 'Brandzone'],
    },
    // {
    //   field: 'divisioncorp',
    //   operator: 'EQUALS',
    //   values: ['FBP'],
    // },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'categorycorp',
      type: 'ASC',
    },
  ],
}

import React, { useState, useEffect } from 'react'
import { getDivisionSumByBrand } from '../../api/home'
import { divisionOption } from '@/common/vars'
import { formatTableNum } from '@/common/util'
import { Table } from 'antd'
import { addtablekey } from './util'
export default function DivisionTable(props) {
  const colums = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      onCell: (_, index) => {
        if (index === 0) {
          return {
            rowSpan: 4,
          }
        }
        return { rowSpan: 0 }
      },
    },
    {
      title: 'Brands',
      dataIndex: 'brands',
      key: 'brands',
    },
    {
      title: 'Index',
      dataIndex: 'index',
      key: 'index',
      align: 'right',
      render: (val) => formatTableNum(val),
    },
    {
      title: 'MoM -/+',
      dataIndex: 'mom',
      key: 'mom',
      align: 'right',
      render: (val) => val.toFixed(2) + '%',
    },
    {
      title: 'YOY -/+',
      dataIndex: 'yoy',
      key: 'yoy',
      align: 'right',
      render: (val) => val.toFixed(2) + '%',
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      ellipsis: true,
    },
  ]
  const [data, setData] = useState([])
  const { ym, division, axis, media, style } = props

  useEffect(() => {
    if (!ym) return
    const [month, year] = ym.split('-')
    if (!year || !month) return
    getDivisionSumByBrand({
      fields: ['year', 'month', 'division', 'category', 'brand', 'searchindex'],

      groups: ['year', 'month', 'division', 'category', 'brand'],
      filters: [
        { field: 'year', operator: 'IN', values: [+year] },
        { field: 'month', operator: 'IN', values: [+month] },
        { field: 'category', operator: 'EQUALS', values: [axis] },
        {
          field: 'division',
          operator: 'IN',
          values: division.includes('all') ? divisionOption : division,
        },
        {
          field: 'media',
          operator: 'IN',
          values: media,
        },
      ],
      //   sorts: [
      //     {
      //       field: 'year',
      //       type: 'ASC',
      //     },
      //     {
      //       field: 'month',
      //       type: 'ASC',
      //     },
      //   ],
    }).then((res) => {
      if (res) {
        // console.log(res)
        addtablekey(res)
        setData(res)
      }
    })
  }, [ym, media, division])
  return (
    <div>
      <Table style={style} dataSource={data} columns={colums} pagination={false}></Table>
    </div>
  )
}

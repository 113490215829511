import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { getIndustrySum, getIndustryProduct } from '@/api/home'
import CombineDate from '@/components/combineDate'

import { Select } from 'antd'
const Filters = (props) => {
  const { onChange, val, currentYear, yearsOpt } = props
  const [brandopt, setBrandopt] = useState([])
  const [categoryopt, setCategoryopt] = useState([])
  const [divisionopt, setDivisionopt] = useState([])
  const [productopt, setProductopt] = useState([])
  //TODO 筛选节流
  // 获取brand
  useEffect(() => {
    getIndustrySum({
      groups: ['brand'],
      sorts: [
        {
          field: 'brand',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      setBrandopt(res.map((i) => ({ label: i.brand, value: i.brand })))
      // onChange('brand', res.map((i) => i.brand))
    })
  }, [])
  // 获取division
  useEffect(() => {
    if (!val.brand.length) return
    getIndustrySum({
      groups: ['division'],
      filters: [
        {
          field: 'brand',
          operator: 'IN',
          values: val.brand,
        },
      ],
      sorts: [
        {
          field: 'division',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      setDivisionopt(res.map((i) => ({ label: i.division, value: i.division })))
      onChange(
        'division',
        res.map((i) => i.division)
      )
    })
  }, [val.brand])
  // 获取category
  useEffect(() => {
    if (!val.division.length) return
    if (!val.brand.length) return
    getIndustrySum({
      groups: ['category'],
      filters: [
        {
          field: 'brand',
          operator: 'IN',
          values: val.brand,
        },
        {
          field: 'division',
          operator: 'IN',
          values: val.division,
        },
      ],
      sorts: [
        {
          field: 'category',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      res = res.filter((i) => i.category)
      setCategoryopt(res.map((i) => ({ label: i.category, value: i.category })))
      onChange('category', res[0].category)
    })
  }, [val.division])
  // 获取product
  useEffect(() => {
    if (!val.division.length) return
    if (!val.brand.length) return
    if (!val.category.length) return
    getIndustryProduct({
      groups: ['product'],
      filters: [
        {
          field: 'brand',
          operator: 'IN',
          values: val.brand,
        },
        {
          field: 'division',
          operator: 'IN',
          values: val.division,
        },
        {
          field: 'category',
          operator: 'IN',
          values: [val.category],
        },
      ],
      sorts: [
        {
          field: 'product',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      setProductopt(res.map((i) => ({ label: i.product, value: i.product })))
      onChange(
        'product',
        res.map((i) => i.product)
      )
    })
  }, [val.category])

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', height: '56px' }}>
        {val.year === undefined ? null : (
          <Select
            getPopupContainer={(n) => n.parentNode}
            showArrow
            style={{ marginRight: '8px' }}
            options={yearsOpt}
            mode="multiple"
            value={val.year}
            onChange={(year) => onChange('year', year)}
          ></Select>
        )}

        <Select
          getPopupContainer={(n) => n.parentNode}
          showArrow
          maxTagCount={0}
          style={{}}
          options={brandopt}
          mode="multiple"
          value={val.brand}
          onChange={(brand) => onChange('brand', brand)}
        ></Select>

        <Select
          getPopupContainer={(n) => n.parentNode}
          style={{ marginLeft: '8px' }}
          options={divisionopt}
          mode="multiple"
          showArrow
          maxTagCount={0}
          value={val.division}
          onChange={(division) => onChange('division', division)}
        ></Select>
        <Select
          getPopupContainer={(n) => n.parentNode}
          style={{ width: '160px', marginLeft: '8px' }}
          options={categoryopt}
          value={val.category}
          onChange={(category) => onChange('category', category)}
        ></Select>
        <Select
          getPopupContainer={(n) => n.parentNode}
          style={{ marginLeft: '8px' }}
          options={productopt}
          mode="multiple"
          showArrow
          maxTagCount={0}
          value={val.product}
          onChange={(product) => onChange('product', product)}
        ></Select>
      </div>
    </>
  )
}
export default connect((state) => ({
  yearsOpt: state.years.map((i) => ({ label: i, value: i })),
  currentYear: state.years[state.years.length - 1],
}))(Filters)

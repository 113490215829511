import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { feedsumex } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getMonthTrendBarChartsOption, formatmonth, formatMillion } from './util'
import { set, update } from 'lodash-es'
import { Empty } from 'antd'
import { formatNum } from '@/common/util'
export default function Crossbar2(props) {
  const {
    title,
    unitReady,
    division,
    color,
    years,
    yearsopt,
    months,
    axis,
    axisOpt,
    product,
    productOpt,
    xname,
    yname,
    unit,
    barwidth,
    dataViewHeaderFirstTitle,
    ta,
    setTaOpt,
    refreshunit,
    style,
    hor = false,
    scroll,
    cag,
    cagopt,
  } = props
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend)
  const [lastres, setlastres] = useState(null)
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const fn = (res) => {
    const xAxisData = [...new Set(res.map((i) => i[xname]))]
    const series = res.reduce((pre, cur) => {
      let name = xname
      if (!pre[name]) pre[name] = []
      if (!pre['months']) pre['months'] = []
      // debugger
      pre[name].push(formatNum(cur[yname], unit, 0))
      pre['months'].push(cur['months'] || '--')
      return pre
    }, {})
    return { xAxisData, series, color, dataViewHeaderFirstTitle, hor, barwidth, scroll }
  }
  useEffect(() => {
    if (!axisOpt.length) return
    if (!productOpt.length) return
    const filters = [
      {
        field: 'mediagroup',
        operator: 'IN',
        values: ['Tencent'],
      },
      {
        field: 'mediaformat',
        operator: 'IN',
        values: ['Moments RTB'],
      },
      {
        field: 'year',
        operator: 'IN',
        values: years.includes('all') ? yearsopt : years,
      },
      {
        field: 'month',
        operator: 'IN',
        values: months.includes('all')
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
          : months.map((i) => map.indexOf(i)),
      },
      {
        field: 'productline',
        operator: 'IN',
        values: product.includes('all') ? productOpt.slice(1).map((i) => i.value) : product,
      },
      {
        field: 'categorycorp',
        operator: 'IN',
        values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
      },
      {
        field: 'mediagroup',
        operator: 'IN',
        values: ['Tencent'],
      },
      {
        field: 'mediaformat',
        operator: 'IN',
        values: ['Moments RTB'],
      },

      // {
      //   field: 'division',
      //   operator: 'IN',
      //   values: division === 'all' ? divisionOption : [division],
      // },
    ]
    if (cag) {
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: cag.includes('all') ? cagopt.slice(1).map((i) => i.value) : cag,
      })
    }
    feedsumex({
      title: 'cost by Campaign',
      fields: ['cost', 'months', 'minmonth'],
      groups: ['campaignname'],
      sorts: [
        {
          field: 'minmonth',
          type: 'ASC',
        },
      ],
      filters,
    }).then((res) => {
      if (!Array.isArray(res)) {
        return setEmpty(true)
      }
      setEmpty(false)
      setlastres(res)
      setTaOpt &&
        setTaOpt([
          { label: '全部', value: 'all' },
          ...res.map((i) => ({ value: i.target1, label: i.target1 })),
        ])

      let chart = getMonthTrendBarChartsOption(
        ta
          ? fn(
              res.filter((i) => {
                if (ta.includes('all')) {
                  return true
                } else {
                  return ta.includes(i.target1)
                }
              })
            )
          : fn(res),
        unit
      )

      updateCharts(chart)
    })
  }, [years, months, division, axis, axisOpt, product, productOpt, cag, cagopt, unit])
  useEffect(() => {
    if (!lastres) return
    // console.log('2.2 第二个图 unit changge rerender',unit)
    const newopt = getMonthTrendBarChartsOption(fn(lastres))
    console.log(newopt)
    updateCharts(newopt)
  }, [unit])
  useEffect(() => {
    if (!lastres) return
    if (!ta || !ta.length) return

    const filterres = lastres.filter((i) => (ta.includes('all') ? true : ta.includes(i.target1)))
    const newopt = getMonthTrendBarChartsOption(fn(filterres))
    updateCharts(newopt)
  }, [ta])

  return (
    <div className="trend-wrap">
      <div className="title">
        <div style={{ width: '100%' }}>{title}</div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          width: 'calc(100%)',
          height: hor ? '700px' : '320px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}

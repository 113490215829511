import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Empty } from 'antd'
import { getSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { Radio } from 'antd'
import { legendOptions } from '@/common/vars'
import { getTrendBarChartsOption2 } from './util'
import { divisionOption } from '@/common/vars'
import { formatUnit, formatNum, getLegendSelected } from '@/common/util'
export default function TrendDivisionAndYear(props) {
  const radioOption = [
    {
      label: 'Month',
      value: 'month',
    },
    {
      label: 'Year',
      value: 'year',
    },
  ]
  const [group, setGroup] = useState(radioOption[0].value)
  const {
    title,
    division,
    years,
    months,
    productLine,
    mediaChannel,
    media,
    mediaFormat,
    unit,
    mediaGroup,
    categorycorp,
    campaign,
    setmonths,
    setyears,
    yearsopt,
    get,
  } = props

  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const [legendselect, setlegendselect] = useState([])
  const [legendCount, setLegendCount] = useState(0)
  const map = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let checkGrouop
  const changePieName = (val) => {
    let ym = val.split('-')
    checkGrouop = val === checkGrouop ? undefined : val
    if (ym.length > 1) {
      setyears([checkGrouop ? Number('20' + ym[1]) : yearsopt[0]])
      setmonths([checkGrouop ? ym[0] : 'all'])
    } else setyears([checkGrouop ? Number(val) : yearsopt[0]])
  }
  const { updateCharts } = useCharts(trend, { legendselectchanged: setlegendselect, changePieName })
  const [echartsData, setechartsData] = useState()

  const [checkAllVal, setcheckAllVal] = useState(1)
  const onChange2 = ({ target: { value } }) => {
    localStorage.setItem('barGroup', value)
    setGroup(value)
  }
  /**
   * catogeryname 区分的类别名  比如 fa fbp
   * valname 具体显示的值 比如budget
   */
  const formatBarData = (data, catogeryname, valname) => {
    const years = [...new Set(data.map((i) => i.year))] // [2021 ,2022]
    years.sort((a, b) => a - b)
    let x = []
    if (group === 'month') {
      years.forEach((y) => {
        map.forEach((m) => {
          x.push(m + '-' + (y - 2000))
        })
      })
    } else {
      x = years
    }
    const xData = x
    //[...new Set(data.map((i) => i.month))] // [1 ,2, 3]

    const ynames = [...new Set(data.map((i) => i[catogeryname]))] // [fa,fbp]
    const yData = []

    ynames.forEach((name) => {
      let s_data = []
      years.forEach((year) => {
        let item = data.filter((i) => i[catogeryname] === name && i.year === year)
        if (group === 'month') {
          s_data = s_data.concat(
            map.map((t, i) => {
              let val = item.filter((t2) => {
                return t2.month === i + 1
              })[0]
              return val ? formatNum(val[valname], unit, 0) : 0
            })
          )
        } else {
          s_data = s_data.concat([item[0] ? formatNum(item[0][valname], unit, 0) : 0])
        }
      })
      yData.push({ year: 1, name, data: s_data })
    })
    return {
      xData,
      yData,
      checkAllVal,
    }
  }

  const init = () => {
    let filters = []
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i) + 1),
      })

    if (!mediaChannel.includes('all'))
      filters.push({
        field: 'mediaChannel',
        operator: 'IN',
        values: mediaChannel,
      })

    if (!mediaFormat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaFormat,
      })
    if (!mediaGroup.includes('all'))
      filters.push({
        field: 'mediagroup',
        operator: 'IN',
        values: mediaGroup,
      })

    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })

    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })

    if (!campaign.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaign,
      })

    if (!division.includes('all'))
      filters.push({
        field: 'division',
        operator: 'IN',
        values: division,
      })

    if (!categorycorp.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: categorycorp,
      })

    getSum({
      fields: ['budget'],
      // groups: ['month', 'mediagroup', 'year'],
      groups: group === 'month' ? ['month', 'status', 'year'] : ['status', 'year'],
      title,
      filters,
      sorts: [
        {
          field: group,
          type: 'ASC',
        },
      ],
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }

      const formatdata = formatBarData(res, 'status', 'budgetamount')

      const data = getTrendBarChartsOption2(formatdata, unit, group === 'month' ? 'Month' : 'Year')
      let selected = getLegendSelected(
        data.series.map((i) => {
          return i.name
        }),
        checkAllVal
      )
      setLegendCount(data.series.length)
      data.legend.selected = selected

      updateCharts(data)
    })
  }

  useEffect(() => {
    localStorage.setItem('barGroup', group)
    init()
  }, [years, unit, months, get, group])
  useEffect(() => {
    if (checkAllVal < 2) init()
  }, [checkAllVal])

  const [first, setFirst] = useState(true)
  useEffect(() => {
    if (!first) {
      if (legendselect.length === 0) {
        setcheckAllVal(0)
      } else if (legendselect.length != legendCount) {
        setcheckAllVal(2)
      } else {
        setcheckAllVal(1)
      }
    }
    setFirst(false)
  }, [legendselect])
  // useEffect(() => {
  //   if(!echartsData) return
  //    console.log(echartsData,'////')
  //    let selected =getLegendSelected(echartsData.series.map(i=>{ return i.name}),checkAllVal)
  //    let echartsdata=JSON.parse(JSON.stringify(echartsData))
  //    echartsdata.legend.selected=selected

  //    updateCharts(
  //     echartsdata
  //   )
  // },[checkAllVal])
  return (
    <div className="trend-wrap">
      <div className="title">{title}</div>
      <div className="tool">
        <div></div>
        <div style={{ marginRight: '24px' }}>
          <Radio.Group
            options={radioOption}
            onChange={onChange2}
            value={group}
            optionType="button"
            size="small"
          />
        </div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          position: 'relative',
          width: '100%',
          height: '430px',
          marginRight: '24px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}

import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import LayoutMenu from './layoutMenu'
import LayoutHeader from './header'
import './index.less'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
const LayoutIndex = (props) => {
  const { Sider, Content } = Layout
  const { isCollapse, updateCollapse, setAuthButtons, collapsed, setCollapsed } = props
  const location = useLocation()
  useEffect(() => {
    if (document) {
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0
      }
      if (document.getElementsByClassName('ant-layout-content')?.[0]) {
        // 找你自己框架主体样式
        document.getElementsByClassName('ant-layout-content')[0].scrollTop = 0
      }
    }
  }, [location?.pathname])

  return (
    // 这里不用 Layout 组件原因是切换页面时样式会先错乱然后在正常显示，造成页面闪屏效果
    <section className="container">
      <Layout>
        <LayoutHeader setCollapsed={setCollapsed} collapsed={collapsed}></LayoutHeader>
        <Layout collapsed={collapsed}>
          {/* <LayoutHeader></LayoutHeader> */}
          <Sider trigger={null} collapsed={collapsed} width={256} theme="light" collapsible>
            <LayoutMenu setCollapsed={setCollapsed} collapsed={collapsed}></LayoutMenu>
          </Sider>
          <Content
            style={{
              padding: 0,
              backgroundColor: '#F2F3F5',
              boxShadow: 'none',
            }}
          >
            {/* TransitionGroup 会导致 useEffect 加载两次 && 使用路由懒加载第一次进入没有动画，所以暂时不用过渡动画了 */}
            {/* <TransitionGroup className="content"> */}
            {/* exit：表示退出当前页面的时候是否有动画 */}
            {/* <CSSTransition key={pathname} timeout={200} classNames="fade" exit={false}> */}
            <Outlet></Outlet>
            {/* </CSSTransition> */}
            {/* </TransitionGroup> */}
          </Content>
        </Layout>
      </Layout>
    </section>
  )
}

export default connect((state) => ({
  collapsed: state.collapsed,
}))(LayoutIndex)

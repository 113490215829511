import * as echarts from 'echarts'
import React, { useEffect, useRef, useState } from 'react'

import { getPerformanceSum2 } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { Radio } from 'antd'
import { getMonthTrendBarChartsOption2, formatmonth } from './util'
import { Empty } from 'antd'
import { divisionOption } from '@/common/vars'
import { formatNum } from '@/common/util'
export default function TrendPerformanceMonth2(props) {
  const {
    year,
    title,
    month,
    clickjump,
    setClickbrand,
    unit,

    division,
    channel,
    channelOpt,
  } = props
  const trend = useRef(null)
  const [type, setType] = useState('session')
  const [empty, setEmpty] = useState(false)

  let { updateCharts } = useCharts(trend, {
    clickjump2: clickjump,
  })

  const radioOption = [
    {
      label: 'Session',
      value: 'session',
    },

    {
      label: 'Revenue',
      value: 'revenue',
    },
  ]
  useEffect(() => {
    localStorage.setItem('typeGroup', 'session')
  }, [])
  const onChange2 = ({ target: { value } }) => {
    setType(value)
    localStorage.setItem('typeGroup', value)
  }

  useEffect(() => {
    getPerformanceSum2({
      fields: [type],
      title,
      groups: ['year', 'month', 'channel'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: year,
        },
        {
          field: 'month',
          operator: 'IN',
          values: month.includes('all') ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] : month,
        },
        // {
        //   field: 'channel',
        //   operator: 'IN',
        //   // values: division,
        //   values: channel.includes('all')
        //     ? channelOpt.slice(1).map((i) => {
        //         return i.value
        //       })
        //     : channel,
        // },
      ],
      sorts: [
        {
          field: 'year',
          type: 'ASC',
        },
        {
          field: 'month',
          type: 'ASC',
        },
        {
          field: 'channel',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      if (res) {
        if (res?.length) {
          setEmpty(false)
        } else {
          return setEmpty(true)
        }
        // debugger
        const linesum = []

        const names = [...new Set(res.filter((i) => i.year === year[0]).map((i) => i.channel))]
        const curData = res.filter((i) => i.year === year[0])
        const totalPrePatchData = []
        for (let k = year.length - 1; k > 0; k--) {
          const preData = res.filter((i) => i.year === year[k])
          console.log(preData)
          // debugger
          const preExistMonth = [...new Set(preData.map((i) => i.month))]
          const prePatchData = preExistMonth.reduce((pre, cur) => {
            names.forEach((channel) => {
              const temp = { month: cur, year: year[k], channel }
              temp[type] = 0
              pre.push(temp)
            })
            return pre
          }, [])
          preData.forEach((pre) => {
            const index = prePatchData.findIndex((i) => {
              return i.month === pre.month && i.year === pre.year && i.channel === pre.channel
            })
            prePatchData[index][type] = pre[type]
          })
          totalPrePatchData.push(...prePatchData)
        }
        // debugger
        res = [...totalPrePatchData, ...curData]
        const linedata = res.filter((i) => i.channel === 'Paid Search')
        console.log(res)
        const name0 = res[0].channel
        const data0 = res.filter((i) => i.channel === name0) //随便找一个channel
        const xAxisData = []
        const monthyear = []
        data0.forEach((i) => {
          xAxisData.push(`${formatmonth(i.month)}-${(i.year + '').slice(2)}`)
          monthyear.push({ y: i.year, m: i.month })
        })
        // debugger
        monthyear.forEach(({ y, m }) => {
          const monthsum = res
            .filter((i) => {
              return i.year === y && i.month === m
            })
            .reduce((pre, cur) => {
              pre = pre + cur[type]
              return pre
            }, 0)
          linesum.push(monthsum)
        })
        // debugger
        const barseries = res.reduce((pre, cur) => {
          let name = cur.channel
          if (!pre[name]) pre[name] = []
          pre[name].push(formatNum(cur[type], unit, 0))
          return pre
        }, {})
        const lineseries = linedata.reduce((pre, cur, curIndex) => {
          let name = cur.channel
          if (!pre[name]) pre[name] = []
          pre[name].push(((cur[type] / linesum[curIndex]) * 100)?.toFixed(1))
          return pre
        }, {})
        // debugger
        updateCharts(
          getMonthTrendBarChartsOption2({ xAxisData, barseries, lineseries, type }, unit)
        )
      }
    })
  }, [year, month, unit, channel, type, channelOpt])

  const style = {
    title: {
      height: '48px',
      lineHeight: '48px',
      fontSize: '14px',
      fontFamily: 'PingFangSC-Medium, PingFang SC',
      fontWeight: '500',
      color: '#262626',
      display: 'flex',
    },
    filterWrap: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }
  return (
    <div className="trend-wrap">
      <div className="tool2">
        <div style={style.title}>
          .COM {type === 'session' ? 'Session' : 'Revenue'} Trend & Paid Search Share
        </div>
        <div>
          <Radio.Group
            options={radioOption}
            onChange={onChange2}
            value={type}
            size="small"
            optionType="button"
          />
        </div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          marginTop: '8px',
          width: 'calc(100%)',
          height: '320px',
          display: empty ? 'none' : 'block',
          ...style,
        }}
      ></div>
    </div>
  )
}

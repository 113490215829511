import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { getPerformanceSum } from '@/api/home'
import { Table } from 'antd'
import { addtablekey } from './util'
import { formatNum, numberWithCommas } from '@/common/util'
import { divisionOption } from '@/common/vars'
import { CheckOutlined } from '@ant-design/icons'
import baidu from '@/assets/mediaImg/baidu.png'
import red from '@/assets/mediaImg/red.png'
import union from '@/assets/mediaImg/union.png'
import wechat from '@/assets/mediaImg/wechat.png'
import baidu_c from '@/assets/mediaImg/baidu_c.png'
import red_c from '@/assets/mediaImg/red_c.png'
import union_c from '@/assets/mediaImg/union_c.png'
import wechat_c from '@/assets/mediaImg/wechat_c.png'
import WeChatMomensRTB_c from '@/assets/mediaImg/WeChatMomensRTB_c.png'
import WeChatMomensRTB from '@/assets/mediaImg/WeChatMomensRTB.png'

import WeChatChannelFeeds_c from '@/assets/mediaImg/WeChatChannelFeeds_c.png'
import WeChatChannelFeeds from '@/assets/mediaImg/WeChatChannelFeeds.png'

import { VerticalAlignBottomOutlined, QuestionCircleOutlined } from '@ant-design/icons'

export default function TreeTable(props) {
  const {
    get,
    division,
    years,
    yearsopt,
    category,
    productLine,
    campaignname,
    unit,
    setChannel,
    channel,
    media,
    mediaformat,
    asset,
    months,
  } = props

  const handelCheckChannel = (val) => {
    setChannel(channel[0] !== val ? [val] : [])
  }
  const [data, setData] = useState([])
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const mediaImgList = {
    'WeChat Moments RTB': { img: WeChatMomensRTB, img_c: WeChatMomensRTB_c },
    'WeChat Channel Feeds': { img: WeChatChannelFeeds, img_c: WeChatChannelFeeds_c },
    'Red Feeds': { img: red, img_c: red_c },
    'Union Pay Programmatic': { img: union, img_c: union_c },
    'Baidu App Opening Page': { img: baidu, img_c: baidu_c },
  }
  const fieldList = {
    Cost: 'cost',
    Impression: 'imp',
    Click: 'click',
    CPM: 'cpm',
    CTR: 'ctr',
    ROI: 'roi',
    AOV: 'aov',
    CVR: 'cvr',
  }
  const columns = () => {
    let c = [
      {
        title: 'Media Channel',
        dataIndex: 'mediachannel',
        key: 'mediachannel',
        width: 140,
        render(val) {
          return (
            <div
              onClick={() => {
                handelCheckChannel(val)
              }}
            >
              {mediaImgList[val]['img_c'] ? (
                <div style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
                  <img
                    src={mediaImgList[val]['img_c']}
                    alt=""
                    style={{ width: '48px' }}
                    title={val}
                  />{' '}
                </div>
              ) : (
                val
              )}
            </div>
          )
        },
      },
    ]
    for (let key in fieldList) {
      let i = 1
      c.push({
        title: key,
        dataIndex: fieldList[key],
        align: 'right',
        key: fieldList[key],
      })
      i++
    }
    return c
  }

  useEffect(() => {
    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Paid Search'],
      },
    ]
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      })
    if (!mediaformat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })

    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!channel.includes('all'))
      filters.push({
        field: 'mediachannel',
        operator: 'IN',
        values: channel,
      })
    if (!productLine.includes('all'))
      filters.push({
        field: 'productLine',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all'))
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    if (!asset.includes('all'))
      filters.push({
        field: 'assets',
        operator: 'IN',
        values: asset,
      })

    getPerformanceSum({
      fields: ['mediachannel', 'cost', 'imp', 'click', 'cpm', 'ctr', 'roi', 'aov', 'order'],
      groups: ['mediachannel'],
      title: '卡片图',
      filters,
      sorts: [
        {
          field: 'mediachannel',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      let data = handleData(res)
      setData(data)
    })
  }, [division, years, months, get, unit])

  const handleData = (res) => {
    let list = []
    for (let key in mediaImgList) {
      let citem = res.filter((i) => {
        return i.mediachannel.indexOf(key) >= 0
      })

      let item = { mediachannel: key }
      for (let key2 in fieldList) {
        let finame = fieldList[key2]
        item[finame] = !citem[0]
          ? 0
          : ['ctr', 'cpm', 'roi', 'aov'].includes(finame)
          ? numberWithCommas(
              finame === 'cpm' || finame === 'aov'
                ? Math.floor(citem[0][finame])
                : citem[0][finame].toFixed(2)
            ) + (finame === 'ctr' ? '%' : '')
          : numberWithCommas(formatNum(citem[0][finame], unit, 0))
      }

      item.cvr = (((citem[0]?.order || 0) * 100) / (citem[0]?.click || 1)).toFixed(2) + '%'
      list.push(item)
    }

    return list
  }
  return (
    <div className="trend-wrap">
      <Table columns={columns()} dataSource={data} pagination={false} />
    </div>
  )
}

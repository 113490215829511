function watermark({
  width = '200px',
  height = '150px',
  textAlign = 'center',
  textBaseline = 'middle',
  font = '12px microsoft yahei',
  fillStyle = 'rgba(184, 184, 184, 0.3)',
  content = '请勿外传',
  rotate = '-15',
} = {}) {
  var args = arguments[0]
  var canvas = document.createElement('canvas')

  canvas.setAttribute('width', width)
  canvas.setAttribute('height', height)
  var ctx = canvas.getContext('2d')

  ctx.textAlign = textAlign
  ctx.textBaseline = textBaseline
  ctx.font = font
  ctx.fillStyle = fillStyle
  ctx.rotate((Math.PI / 180) * rotate)
  ctx.fillText(content, parseFloat(width) / 2, parseFloat(height) / 2)

  var base64Url = canvas.toDataURL()
  const watermarkDiv = document.querySelector('.watermark')
  watermarkDiv.setAttribute(
    'style',
    `
      background-repeat:repeat;
      background-image:url('${base64Url}')`
  )
}

export { watermark }

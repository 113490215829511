import { connect } from 'react-redux'
import { useEffect, useState, useImperativeHandle, useMemo } from 'react'
import { getIndustrySum, getIndustryProduct } from '@/api/home'
import CombineDate from '@/components/combineDate'
import { Divider, Checkbox, Select, Space, Button, message } from 'antd'
const Filters = (props) => {
  const { onChange, val, currentYear, yearsOpt, preYear, defaultOpt, getRank, months, setmonths } =
    props
  const [brandopt, setBrandopt] = useState([])
  const [mediaopt, setMediaopt] = useState([])
  const [axisOpt, setAxisOpt] = useState([{ label: 'FA', value: 'FA' }])
  const divisionData = ['Corp', 'FA', 'FBP', 'WFJ']
  const axisData = {
    Corp: ['Corp'],
    FBP: ['FR', 'MU', 'SK'],
    WFJ: ['FJ', 'WAT'],
    FA: ['FA'],
  }
  const monthOptions = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const onMonthChange = (val) => {
    if (val.length) {
      const index = val.indexOf('all')
      if (index === 0 && val.length > 0) return setmonths(val.slice(1)) //由默认转为其他
      if (index === val.length - 1 && val.length > 0) return setmonths(['all']) //由其他转为默认
      setmonths(val)
      //setCheckAll(checkedValues.length === monthOptions.length)
    } else {
      message.info('至少保留一个月份')
    }
  }
  const getmediaopt = (axis) => {
    onChange('brand', [])
    if (axis.length === 0) return
    return getIndustrySum({
      groups: ['media'],
      sorts: [
        {
          field: 'media',
          type: 'ASC',
        },
      ],
      filters: axis
        ? [
            {
              field: 'category',
              operator: 'IN',
              values: axis,
            },
          ]
        : undefined,
    }).then((res) => {
      setMediaopt(res.map((i) => ({ label: i.media, value: i.media })))
      setTimeout(() => {
        const newMedia = res.map((i) => i.media)
        // onChange('brand', newbrand)
        handleMediaChange(newMedia[0])
      })
    })
  }

  const getbrandopt = (axis, media) => {
    onChange('brand', [])
    if (axis.length === 0 || media.length === 0) return

    let filters = []
    if (!val.media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (axis)
      filters.push({
        field: 'category',
        operator: 'IN',
        values: axis,
      })
    return getIndustrySum({
      groups: ['brand'],
      sorts: [
        {
          field: 'brand',
          type: 'ASC',
        },
      ],
      filters,
    }).then((res) => {
      setBrandopt(res.map((i) => ({ label: i.brand, value: i.brand })))
      setTimeout(() => {
        const newbrand = res.map((i) => i.brand)
        // onChange('brand', newbrand)
        handleBrandChange(newbrand)
      })
    })
  }
  const selectallbrand = (e) => {
    if (e.target.checked) {
      onChange(
        'brand',
        brandopt.map((i) => i.value)
      )
    } else {
      onChange('brand', [])
    }
  }
  // 初始化 brand列表
  useEffect(() => {
    getmediaopt([axisData[divisionData[1]][0]])
  }, [])
  useEffect(() => {
    if (mediaopt.length > 0) {
      getbrandopt([axisData[divisionData[1]][0]], [mediaopt[0].value])
    }
  }, [mediaopt])
  const getAxisOpt = (division) => {
    //异步全选,带出brand
    onChange('category', [])
    setTimeout(() => {
      // onChange('category', axisData[division])
      handleCategoryChange(axisData[division])
    })
    return axisData[division].map((i) => {
      return {
        value: i,
        label: i,
      }
    })
  }

  const handleBrandChange = (brand) => {
    onChange('brand', brand) //通知父组件更改brand
  }
  const handleMediaChange = (media) => {
    onChange('media', [media]) //通知父组件更改brand

    getbrandopt(val.category, [media])
  }

  const handleDivisionChange = (division) => {
    onChange('division', division) //通知父组件更改division
    setAxisOpt(getAxisOpt(division))
    // onChange('category', [])
    // onChange('brand', [])
  }
  const handleCategoryChange = async (category) => {
    // if (getRank) {
    //   await getRank([val.division], category, val.years)
    // }

    onChange('category', category) //通知父组件更改category
    getbrandopt(category, val.media)
    // onChange('brand', [])
  }
  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',

          padding: '24px 24px 36px 24px',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'left' }}>
          <CombineDate
            onChange={(years) => {
              onChange('years', years)
              if (getRank) {
                getRank(val.division, val.category, years, val.media)
              }
            }}
            val={val.years}
          />

          <div className="searchItem">
            <div className="spantitle">Month</div>
            <Select
              maxTagCount={3}
              options={[{ label: '全部', value: 'all' }].concat(
                monthOptions.map((i) => {
                  return { label: i, value: i }
                })
              )}
              value={months}
              onChange={onMonthChange}
              style={{ width: '240px' }}
              mode={'multiple'}
            />
          </div>

          <div className="searchItem">
            <div className="spantitle">Media</div>
            <Select
              getPopupContainer={(n) => n.parentNode}
              allowClear
              style={{ minWidth: '240px' }}
              showArrow
              maxTagCount={1}
              options={mediaopt}
              value={val.media}
              onChange={handleMediaChange}
            ></Select>
          </div>
          <div className="searchItem">
            <span className="spantitle">Division</span>

            <Select
              getPopupContainer={(n) => n.parentNode}
              style={{ width: '240px' }}
              options={divisionData.map((i) => ({ label: i, value: i }))}
              value={val.division}
              onChange={handleDivisionChange}
            ></Select>
          </div>

          <div className="searchItem">
            <div className="spantitle">Category</div>
            <Select
              getPopupContainer={(n) => n.parentNode}
              allowClear
              style={{ minWidth: '240px' }}
              showArrow
              maxTagCount={1}
              options={axisOpt}
              mode="multiple"
              value={val.category}
              onChange={handleCategoryChange}
            ></Select>
          </div>
          <div className="searchItem">
            <div className="spantitle">Brand</div>
            <Select
              getPopupContainer={(n) => n.parentNode}
              allowClear
              showArrow
              maxTagCount={0}
              options={brandopt}
              mode="multiple"
              value={val.brand}
              style={{ minWidth: '240px' }}
              onChange={handleBrandChange}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '2px 0',
                    }}
                  />
                  <Checkbox
                    checked={val.brand.length === brandopt.length}
                    onChange={selectallbrand}
                    style={{ marginLeft: '12px' }}
                  >
                    全选
                  </Checkbox>
                </>
              )}
            ></Select>
          </div>
        </div>
      </div>
    </>
  )
}
export default connect((state) => ({
  yearsOpt: state.years.map((i) => ({ label: i, value: i })),
  currentYear: state.years[state.years.length - 1],
  preYear: state.years[state.years.length - 2],
}))(Filters)

import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import SeSelector from './seSelector'
import { getSum1 } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendPieChartsOption, formatMillion } from './util'

import { Empty } from 'antd'

export default function TrendPie(props) {
  const {
    title,
    division,
    years,
    months,
    axis,
    axisOpt,
    product,
    productOpt,
    dataViewHeaderFirstTitle,
    unit,
    yearsopt,
    refreshunit,
    yname,
    // argsfn,
    color,
    // color1,
  } = props
  const trend = useRef(null)
  const { updateCharts } = useCharts(trend)
  const [empty, setEmpty] = useState(false)
  const [lastres, setlastres] = useState(null)
  const formatPieData = (data, key) => {
    const res = {}
    data.forEach((i) => {
      const name = i[key]
      if (!res[name]) res[name] = { year: [], budget: [] }
      if (i.year === years[0] && res[name].year.length === 0) {
        // 补一下前年没值的问题
        res[name].year.push(years[1])
        res[name].budget.push(0)
      }
      res[name].year.push(i.year)
      res[name].budget.push(formatMillion(i.budget))
    })
    return res
  }
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const fn = (res) => {
    const keyname = ['axis', 'categorycorp'] //可能的keyname
    for (let key in res[0]) {
      if (keyname.includes(key)) {
        return {
          dataViewHeaderFirstTitle,
          color,
          unit,
          yname,
        }
      }
    }
  }
  useEffect(() => {
    if (!axisOpt.length) return
    // debugger
    getSum1({
      //division
      title: '饼图',
      fields: ['cost'],
      groups: ['categorycorp'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years.includes('all') ? yearsopt : years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.includes('all')
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            : months.map((i) => map.indexOf(i)),
        },
        // {
        //   field: 'categorycorp',
        //   operator: 'IN',
        //   values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
        // },
        {
          field: 'mediagroup',
          operator: 'IN',
          values: ['Tencent'],
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: ['Moments RTB'],
        },
      ],
      // sorts: [
      //   {
      //     field: 'year',
      //     type: 'ASC',
      //   },
      //   {
      //     field: 'division',
      //     type: 'ASC',
      //   },
      // ],
    }).then((res) => {
      // debugger
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      setlastres(res)
      let newopt = getTrendPieChartsOption(res, fn(res))
      newopt.legend = {
        show: false,
      }
      updateCharts(newopt)
    })
  }, [years, months, product, productOpt, refreshunit]) //
  useEffect(() => {
    if (!lastres) return
    // console.log('2.2 第二个图 unit changge rerender',unit)
    const newopt = getTrendPieChartsOption(lastres, fn(lastres))
    newopt.legend = {
      show: false,
    }
    updateCharts(newopt)
  }, [unit])
  return (
    <div className="trend-wrap">
      {/* <div className="title">
        <div>{title}</div>
      </div> */}
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        className="pie2"
        ref={trend}
        style={{
          position: 'relative',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}

import {
  formatUnit,
  formatNum,
  dataViewConfigCreator,
  contentToOption,
  numberWithCommas,
} from '@/common/util'
import {
  themecolor,
  themecolor_pie,
  themecolor_pie1,
  themecolor_two,
  divisionOption,
} from '@/common/vars'
import fa from '../home/fa'
export const formatMillion = (num) => {
  let res
  if (num < 1000000000) {
    res = (num / (1000 * 1000))?.toFixed(3)
  } else {
    res = (num / (1000 * 1000))?.toFixed(1)
  }
  // debugger

  return parseFloat(res)
}
export const addtablekey = (tree) => {
  const fn = (node, lv = 1) => {
    node.key = lv
    if (!node.children) return
    if (!node.children.length) return
    node.children.forEach((i, index) => {
      fn(i, lv + `-${index}`)
    })
  }
  tree.forEach((i, index) => {
    fn(i, index)
  })
}

export const formatmonth = (i) => {
  const monthname = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return monthname[i]
}
export const getTrendBarChartsOption = (
  { xData, yData, ynames, formatpercent, dataViewHeaderFirstTitle, stack = true },
  unit
) => {
  let seriesData = yData.map((datai, index) => {
    return {
      name: ynames[index],
      type: 'bar',
      stack: stack ? 'ad' : undefined,
      barMaxWidth: '16',
      emphasis: {
        focus: 'series',
      },
      data: datai,
    }
  })
  const res = {
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = opt.xAxis[0].data // [1,2,3,4,5...12]
          var series = opt.series // [tencent,red,baidu,non-baidu]
          // 表格头  先name再data
          var table =
            '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${dataViewHeaderFirstTitle ? dataViewHeaderFirstTitle : 'Month-Year/Cost'}</td>` +
            // axisData.map((i) => `<td>${i} Budegt</td>`).join('') +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          // const curyearsum = axisData.map((i, index) => {
          //   return series
          //     .map((j) => j.data[index])
          //     .reduce((pre, cur) => (pre += cur))
          //     ?.toFixed(1)
          // })
          // console.log(curyearsum)
          // debugger
          // let totalsum = 0
          for (var i = 0, l = axisData.length; i < l; i++) {
            // const sum = series[i].data.reduce((pre, cur) => (pre += cur))
            // totalsum += sum
            // const curyearsum = series.map(j=>j.data[i]).reduce((pre,cur)=>pre+=cur)
            // debugger
            table +=
              '<tr>' +
                '<td>' +
                numberWithCommas(axisData[i]) +
                '</td>' +
                series
                  .map(
                    (j, jindex) =>
                      // `<td>${j}</td> <td>${((j * 100) / curyearsum[jindex])?.toFixed(1)}%</td>`
                      `<td> ${
                        !isFinite(j.data[i])
                          ? 0.0
                          : numberWithCommas(j.data[i]) + (formatpercent ? '%' : '')
                      }</td>`
                  )
                  .join('') || 0 + '</tr>'
          }
          // table +=
          //   '<tr>' +
          //   '<td>' +
          //   'Total' +
          //   '</td>' +
          //   curyearsum.map((i) => `<td>${i}</td>`).join('') +
          //   '</tr>'

          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: { right: '50', type: 'scroll' }, //颜色提示
    // legend: {
    //   type: 'scroll',
    //   orient: 'vertical',
    //   right: 20,
    //   top: 0,
    //   bottom: 20,
    //   // data: data.legendData,

    //  }, //颜色提示
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const year = params[0].axisValueLabel
        let res = `${year}<br/>`
        let sum = 0
        params.forEach((i) => {
          const { seriesName, value, marker } = i
          sum += value
          //"<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>"
          res += `<div style="display:flex;max-width:250px;align-item:left;">${marker}${seriesName}:
          <span style="display:inline-block;text-align:left;">${numberWithCommas(
            value
          )}</span> </div>`
        })
        // res += `<div style="display:flex;width:150px;align-item:center;justify-content:space-between"><span style="font-weight: 700">Total: </span>${sum?.toFixed(
        //   2
        // )}</div>`

        return res
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
        axisLabel: { interval: 0, rotate: '45' },
      },
    ],
    yAxis: [
      {
        name: unit ? formatUnit(unit) + '(RMB)' : '',
        type: 'value',
        axisLabel: {
          formatter: formatpercent ? '{value} %' : '{value}',
        },
        max: formatpercent ? 100 : undefined,
      },
    ],
    series: seriesData,
    color: themecolor_pie1,
  }
  if (formatpercent) {
    res.tooltip = {
      valueFormatter: function (value) {
        return value + ' %'
      },
    }
  }
  return res
}

export const getScattersOption = (origindata, [averageX, averageY], unit) => {
  // debugger
  const data = [
    origindata.map((i) => {
      return [i.roi, i.atc, formatNum(i.cost, unit, 0), i.campaignname]
    }),
  ]

  let seriesData = [
    {
      // name: '1990',
      data: data[0],
      type: 'scatter',
      symbolSize: function (data) {
        return Math.sqrt(data[2]) / 5e1
      },
      // emphasis: {
      //   focus: 'self',
      // },
      markLine: {
        symbol: ['none', 'none'],
        data: [{ xAxis: averageX }, { yAxis: averageY }],
        label: {
          show: false,
        },
      },

      label: {
        show: true,
        formatter: function (param) {
          return param.data[3]
        },
        position: 'right',
        minMargin: 2,
      },
    },
  ]

  const res = {
    backgroundColor: '#FFF',
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        name: 'Order ROI',
        nameLocation: 'center',
        nameGap: 24,
        splitLine: { show: false },
        // axisLabel: {
        //   formatter: (val)=>{
        //     return parseInt(val*100) + '%'
        //   }
        // },
      },
    ],
    yAxis: [
      {
        name: 'ATC%(ATC/CLK)',
        splitLine: { show: false },
        scale: true,
        axisLabel: {
          formatter: (val) => {
            return val + '%'
          },
        },
      },
    ],
    series: seriesData,
    color: themecolor_pie1,
    tooltip: {
      formatter: (params) => {
        console.log(params)
        const { marker, componentType, data } = params
        if (componentType === 'markLine') {
          if (data.yAxis) {
            return `<div><span>${marker}ATC: ${data.value}%</span> </div>`
          } else {
            return `<div><span>${marker}ROI: ${numberWithCommas(data.value)} </span> </div>`
          }
        }
        const [x, y, cost, campaignname] = params.data

        let res = ``
        res += `<div><span>${marker}Name: ${campaignname} </span> </div>`
        res += `<div><span>${marker}ROI: ${x}</span> </div>`
        res += `<div><span>${marker}ATC: ${y + '%'} </span> </div>`
        res += `<div><span>${marker}Cost: ${numberWithCommas(Number(cost))} </span> </div>`
        // res += `<div"><span>${marker}${x},${y+'%'} </span> </div>`

        return res
      },
      // position: 'top'
      // trigger: 'axis',
      // axisPointer: {
      //   type: 'shadow',
      // },
    },
  }

  return res
}

export const getMonthTrendBarChartsOption = (
  { barwidth = '16', xAxisData, series, color, dataViewHeaderFirstTitle, hor, scroll },
  unit
) => {
  let seriesData = []
  for (let keyname in series) {
    if (keyname != 'months') {
      seriesData.push({
        name: keyname,
        type: 'bar',
        barMaxWidth: '12',
        stack: 'ad',
        barWidth: barwidth,
        emphasis: {
          focus: 'series',
        },
        data: series[keyname],
      })
    }
  }
  let months = series['months']
  const config = {
    color: color,
    legend: { right: '50' }, //颜色提示
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        const year = params[0].axisValueLabel
        let res = ``
        let sum = 0

        params.forEach((i) => {
          let ind = i.dataIndex
          const { seriesName, value, marker } = i
          sum += value
          //"<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>"
          res += `<div style="display:flex;width:250px;align-item:center;justify-content:space-between"><span>${year}: </span><span style="display:inline-block;text-align:right;">${numberWithCommas(
            value
          )}</span> </div>`
          res += `<div style="display:flex;width:250px;align-item:center;justify-content:space-between"><span>投放月份: 
          </span><span style="display:inline-block;text-align:right;">${months[ind]}</span>   
          </div>`
        })
        // res += `<div style="display:flex;width:250px;align-item:center;justify-content:space-between"><span style="font-weight: 700">Total: </span>${sum?.toFixed(
        //   2
        // )}</div>`

        return res
      },
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = hor ? opt.yAxis[0].data : opt.xAxis[0].data //['SubCore', 'RCC', ..]
          var series = opt.series //
          const seriesData = series[0].data
          // 表格头  先name再data
          var table =
            '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${dataViewHeaderFirstTitle}</td>` +
            `<td>Cost</td>` +
            // `<td>总计</td>` +
            '</tr>'
          // 表格题  先name再data

          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              '<td>' +
              numberWithCommas(seriesData[i]) +
              '</td>' +
              '</tr>'
          }

          table += '</tbody></table>'
          return table
        }),
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: hor
      ? [
          {
            // name: 'Mil(RMB)',
            type: 'value',
            nameLocation: 'left',
            nameGap: 25,
            axisLabel: { interval: 0, rotate: '45' },
          },
        ]
      : [
          {
            type: 'category',
            data: xAxisData,
            axisLabel: { interval: 0, rotate: '45' },
          },
        ],
    yAxis: hor
      ? [
          {
            type: 'category',
            data: xAxisData,
          },
        ]
      : [
          {
            name: formatUnit(unit) + '(RMB)',
            type: 'value',
          },
        ],
    series: seriesData,
  }
  if (scroll) {
    config.dataZoom = [
      {
        type: 'slider',
        maxValueSpan: 9, //显示数据的条数(默认显示10个)
        minValueSpan: 9, //显示数据的条数(默认显示10个)
        show: true,
        yAxisIndex: [0],
        left: '93%', //滑动条位置
        start: 100, //默认为0
        end: 90, //默认为100
        orient: 'vertical',
        filterMode: 'empty',
        // zoomLock:true,
      },
      {
        type: 'inside', //内置滑动，随鼠标滚轮展示
        yAxisIndex: [0],
        zoomOnMouseWheel: false, //如何触发缩放。可选值为：true：表示不按任何功能键，鼠标滚轮能触发缩放。false：表示鼠标滚轮不能触发缩放。'shift'：表示按住 shift 和鼠标滚轮能触发缩放。'ctrl'：表示按住 ctrl 和鼠标滚轮能触发缩放。'alt'：表示按住 alt 和鼠标滚轮能触发缩放。。
        moveOnMouseMove: true,
        moveOnMouseWheel: true, //鼠标滚轮实现移动
      },
    ]
  }
  return config
}

export const getTrendLineOption = ({ predata, curdata, pre, cur, formatpercent, xAxisData }) => {
  // const series1 = preyeardata.map((i) => i.si)

  // debugger
  const series1 = predata
  const series2 = curdata
  return {
    color: themecolor_pie1,
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const year = params[0].axisValueLabel
        let res = `${year}<br/>`
        let sum = 0
        params.forEach((i) => {
          const { seriesName, value, marker } = i
          res += `<div style="display:flex;width:100px;align-item:center;justify-content:space-between"><span>${marker}${seriesName}: </span><span style="display:inline-block;text-align:right;">${
            formatpercent ? numberWithCommas(value) + '%' : numberWithCommas(value)
          }</span> </div>`
        })
        return res
      },
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          console.log(opt)
          var axisData = opt.xAxis[0].data
          var series = opt.series

          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Post Name</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series
                .map(
                  (j) =>
                    `<td>${
                      formatpercent
                        ? numberWithCommas(j.data[i] || 0) + '%'
                        : numberWithCommas(j.data[i] || 0)
                    }</td>`
                )
                .join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: {
      data: [pre + '', cur + ''],
      right: 25,
      top: 5,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    // toolbox: {
    // feature: {
    //   saveAsImage: {},
    // },
    // },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
      axisLabel: { interval: 0, rotate: '45' },
    },
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: (val) => {
            return formatpercent ? val + '%' : val
          },
        },
      },
      {
        type: 'value',
        axisLabel: {
          formatter: (val) => {
            return formatpercent ? val + '%' : val
          },
        },
      },
    ],
    series: [
      {
        name: pre,
        type: 'bar',
        barWidth: '16',
        smooth: true,
        yAxisIndex: 0,
        data: series1,
      },
      {
        name: cur,
        type: 'line',
        yAxisIndex: 1,
        data: series2,
        smooth: true,
      },
    ],
  }
}

export const getMonthTrendBarChartsOption_wechat = ({
  barwidth = '16',
  xAxisData,
  series,
  color,
  dataViewHeaderFirstTitle,
  hor,
}) => {
  let seriesData = []

  for (let keyname in series) {
    if (keyname != 'months') {
      seriesData.push({
        name: keyname,
        type: 'bar',
        barMaxWidth: '12',
        stack: 'ad',
        barWidth: barwidth,
        emphasis: {
          focus: 'series',
        },

        data: series[keyname],
      })
    }
  }
  let months = series['months']
  return {
    color: color,
    legend: { right: '50' }, //颜色提示
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params, index) => {
        const year = params[0].axisValueLabel

        // let res = `${year}<br/>`
        let res = ``
        let sum = 0
        params.forEach((i) => {
          let ind = i.dataIndex
          const { seriesName, value, marker } = i
          sum += value
          //"<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>"
          res += `<div style="display:flex;width:250px;align-item:center;justify-content:space-between"><span>${marker}${year}: 
          </span><span style="display:inline-block;text-align:right;">${numberWithCommas(
            Number(value)
          )}</span>   
          </div>`
          res += `<div style="display:flex;width:250px;align-item:center;justify-content:space-between"><span>投放月份: 
          </span><span style="display:inline-block;text-align:right;">${months[ind]}</span>   
          </div>`
        })

        return res
      },
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = hor ? opt.yAxis[0].data : opt.xAxis[0].data //['SubCore', 'RCC', ..]
          var series = opt.series //
          const seriesData = series[0].data
          console.log(axisData, series)
          // 表格头  先name再data
          var table =
            '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${dataViewHeaderFirstTitle}</td>` +
            `<td>Cost</td>` +
            // `<td>总计</td>` +
            '</tr>'
          // 表格题  先name再data

          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              '<td>' +
              numberWithCommas(Number(seriesData[i])) +
              '</td>' +
              '</tr>'
          }

          table += '</tbody></table>'
          return table
        }),
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: hor
      ? [
          {
            // name: 'Mil(RMB)',
            type: 'value',
            nameLocation: 'middle',
            nameGap: 25,
          },
        ]
      : [
          {
            type: 'category',
            data: xAxisData,
          },
        ],
    yAxis: hor
      ? [
          {
            type: 'category',
            data: xAxisData,
          },
        ]
      : [
          {
            // name: 'Mil(RMB)',
            type: 'value',
          },
        ],
    series: seriesData,
  }
}

export const redbarhoropt = ({
  yAxis,
  series,
  color,
  scroll,
  barwidth = '16',
  dataViewHeaderFirstTitle,
  formatpercent,
}) => {
  let seriesData = []
  for (let keyname in series) {
    seriesData.push({
      name: keyname,
      type: 'bar',
      barWidth: barwidth,
      emphasis: {
        focus: 'series',
      },
      data: series[keyname],
    })
  }
  const config = {
    color: color,
    legend: { right: '50' }, //颜色提示
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) => {
        const year = params[0].axisValueLabel
        let res = `${year}<br/>`
        let sum = 0
        params.forEach((i) => {
          const { seriesName, value, marker } = i

          sum += value
          //"<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>"
          res += `<div style="display:flex;width:250px;align-item:center;justify-content:space-between"><span>${marker}${seriesName}: </span><span style="display:inline-block;text-align:right;">${
            formatpercent ? value + '%' : value
          }</span> </div>`
        })
        // res += `<div style="display:flex;width:250px;align-item:center;justify-content:space-between"><span style="font-weight: 700">Total: </span>${sum?.toFixed(
        //   2
        // )}</div>`

        return res
      },
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          console.log(opt)
          var axisData = opt.yAxis[0].data //['SubCore', 'RCC', ..]
          var series = opt.series //
          console.log(axisData, series)
          // 表格头  先name再data

          const header = dataViewHeaderFirstTitle.map((i) => `<td>${i}</td>`)
          var table =
            '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            header.join('') +
            '</tr>'
          // 表格题  先name再data
          if (
            series.length === 2 &&
            ['ctr', 'er'].includes(series[0].name) &&
            ['ctr', 'er'].includes(series[1].name)
          ) {
            //适配CTR &ER By Campaign& Post Name表格
            for (var i = 0; i < axisData.length; i++) {
              table +=
                '<tr>' +
                '<td>' +
                axisData[i] +
                '</td>' +
                '<td>' +
                series[0].data[i] +
                '%' +
                '</td>' +
                '<td>' +
                series[1].data[i] +
                '%' +
                '</td>' +
                '</tr>'
            }
          } else {
            for (var i = 0; i < axisData.length; i++) {
              for (var j = 0; j < series.length; j++) {
                if (series.length === 1) {
                  table +=
                    '<tr>' +
                    '<td>' +
                    axisData[i] +
                    '</td>' +
                    '<td>' +
                    (formatpercent ? series[j].data[i] + '%' : series[j].data[i]) +
                    '</td>' +
                    '</tr>'
                } else if (series.length === 2) {
                  table +=
                    '<tr>' +
                    '<td>' +
                    axisData[i] +
                    '</td>' +
                    '<td>' +
                    series[j].name +
                    '</td>' +
                    '<td>' +
                    (formatpercent ? series[j].data[i] + '%' : series[j].data[i]) +
                    '</td>' +
                    '</tr>'
                }
              }
            }
          }

          table += '</tbody></table>'
          return table
        }),
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        // name: 'Mil(RMB)',
        type: 'value',
        axisLabel: {
          formatter: (val) => {
            return formatpercent ? val + '%' : val
          },
        },
        // nameLocation: 'middle',
        // nameGap: 25,
      },
    ],
    yAxis: [
      {
        type: 'category',
        data: yAxis,
      },
    ],
    series: seriesData,
  }
  if (scroll) {
    config.dataZoom = [
      {
        type: 'slider',
        maxValueSpan: 9, //显示数据的条数(默认显示10个)
        minValueSpan: 9, //显示数据的条数(默认显示10个)
        show: true,
        yAxisIndex: [0],
        left: '93%', //滑动条位置
        start: 100, //默认为0
        end: 90, //默认为100
        orient: 'vertical',
        filterMode: 'empty',
        // zoomLock:true,
      },
      {
        type: 'inside', //内置滑动，随鼠标滚轮展示
        yAxisIndex: [0],
        zoomOnMouseWheel: false, //如何触发缩放。可选值为：true：表示不按任何功能键，鼠标滚轮能触发缩放。false：表示鼠标滚轮不能触发缩放。'shift'：表示按住 shift 和鼠标滚轮能触发缩放。'ctrl'：表示按住 ctrl 和鼠标滚轮能触发缩放。'alt'：表示按住 alt 和鼠标滚轮能触发缩放。。
        moveOnMouseMove: true,
        moveOnMouseWheel: true, //鼠标滚轮实现移动
      },
    ]
  }
  return config
}

export const getTrendPieChartsOption = (data, config) => {
  const {
    color,
    color1,
    outlabel,
    inlabel,
    dataViewHeaderFirstTitle = 'Division',
    unit,
    yname,
  } = config //todo 外圈设定年限文案

  let seriesData1 = []
  // for (let divisionName in data) {
  //   seriesData1.push({ name: divisionName, value: data[divisionName].budget[1] })
  // }
  data.forEach((i) => {
    seriesData1.push({ name: i[yname], value: formatNum(i.cost, unit, 0) })
  })
  // const getInlabeldata = (name) => {
  //   const budget = data[name].budget
  //   let sum = 0
  //   for (let i in data) {
  //     sum = sum + data[i].budget[1]
  //   }
  //   const percent = (budget[1] / sum) * 100
  //   const value = budget[1]
  //   // debugger
  //   return {
  //     percent,
  //     value,
  //   }
  // }
  return {
    color: color ? color : themecolor,
    animation: false,
    // title: {
    //   subtext: 'Mil(RMB)',
    //   left: 'left',
    // },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var series = opt.series
          // 表格头  先name再data
          var table =
            '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${dataViewHeaderFirstTitle}</td>` +
            `<td>Cost</td>` +
            '</tr>'
          // 表格题  先name再data
          const namearr = series[0].data.map((i) => i.name)
          for (var i = 0, l = namearr.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              namearr[i] +
              '</td>' +
              `<td>${numberWithCommas(series[0].data[i].value)}</td>` +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    tooltip: {
      trigger: 'item',
      // formatter: '{a}: <br /> {b}: {c} ({d}%)',
      formatter: (param) => {
        let { name, value, percent } = param
        return `${name}:${numberWithCommas(value)}  (${percent}%)`
      },
    },
    legend: {
      // x: 'bottom',
      bottom: 0,
      animation: false,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '13%',
      containLabel: true,
      tooltip: {
        axisPointer: {
          animation: false,
        },
      },
    },
    series: [
      {
        // silent: true,
        itemStyle: {},
        emphasis: {
          disabled: false,
          scale: false,
          // itemStyle:{
          //   color:['#F1F1F1','#F00'],
          // }
        },
        // color: ['rgb(84,112,198)','rgb(145,204,117)','rgb(250,200,88)','rgb(238,102,102)'], //蓝 绿 黄
        // color: ['#557bd5','#99e086','#ffdc6d'],
        legendHoverLink: false,
        name: inlabel,
        type: 'pie',
        selectedMode: 'single',
        // radius: [0, '0%'],
        label: {
          position: 'inner',
          fontSize: 10,
        },
        labelLine: {
          show: false,
        },
        data: seriesData1,
      },
    ],
  }
}

export const getTrendBarChartsOption_hor = (data, config) => {
  const { year } = config //todo 外圈设定年限文案
  //内圈是大年 2022  外圈是小年 2021
  const [curyear, preyear] = config.years
  const yAxisData = data.filter((i) => i.year === curyear).map((i) => i.axis)
  const seriesData = []

  // seriesData[0] = data.filter((i) => i.year === preyear).map((i) => formatMillion(i.budget))
  seriesData[1] = data.filter((i) => i.year === curyear).map((i) => formatMillion(i.budget))
  seriesData[0] = new Array(yAxisData.length).fill(0)
  data
    .filter((i) => i.year === preyear)
    .forEach((i) => {
      const index = yAxisData.indexOf(i.axis)
      seriesData[0][index] = formatMillion(i.budget)
    })
  return {
    color: themecolor_two,
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var series = opt.series
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Year</td>' +
            `<td>${series[0].name} Budget</td>` +
            `<td>${series[0].name + ' Share'}</td>` +
            `<td>${series[1].name} Budget</td>` +
            `<td>${series[1].name + ' Share'}</td>` +
            '</tr>'
          // 表格题  先name再data
          const namearr = opt.yAxis[0].data
          const sum22 = series[0].data.reduce((pre, cur) => {
            pre += cur
            return pre
          }, 0)
          const sum21 = series[1].data.reduce((pre, cur) => {
            pre += cur
            return pre
          }, 0)
          for (var i = 0, l = namearr.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              namearr[i] +
              '</td>' +
              `<td>${series[0].data[i]}</td>` +
              `<td>${((series[0].data[i] / sum22) * 100)?.toFixed(1)}%</td>` +
              `<td>${series[1].data[i]}</td>` +
              `<td>${((series[1].data[i] / sum21) * 100)?.toFixed(1)}%</td>` +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    title: {
      subtext: 'Mil(RMB)',
      left: 'left',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      right: '50',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: 'category',
      data: yAxisData,
    },
    series: [
      {
        name: curyear,
        type: 'bar',
        barWidth: '16',
        data: seriesData[1],
      },
      {
        name: preyear,
        type: 'bar',
        barWidth: '16',
        data: seriesData[0],
      },
    ],
  }
}

export const createargs = (args) => {
  const { groups, filters } = args
  return {
    fields: ['budget'],
    groups,
    filters,
    sorts: [
      {
        field: 'year',
        type: 'ASC',
      },
      {
        field: 'divisioncorp',
        type: 'ASC',
      },
    ],
  }
}

export const argscreator = (baseconfig) => {
  return ({ yearVal, division, se }) => {
    const filters = baseconfig.filters

    if (yearVal) {
      const index = filters.findIndex((i) => i.field === 'year')
      filters[index].values = yearVal
    }
    if (division) {
      const index = filters.findIndex((i) => i.field === 'divisioncorp')
      filters[index].values = division
    }
    if (se) {
      const index = filters.findIndex((i) => i.field === 'se')
      if (Array.isArray(se)) {
        filters[index].values = se
      } else {
        filters[index].values = [se]
      }
    }
    return baseconfig
  }
}

export const pieconfig1 = {
  //division
  fields: ['budgetamount'],
  groups: ['mediagroup'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2023],
    },
    {
      field: 'month',
      operator: 'IN',
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    },
    {
      field: 'productline',
      operator: 'IN',
      values: [2023],
    },
    {
      field: 'divisioncorp',
      operator: 'IN',
      values: divisionOption,
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'divisioncorp',
      type: 'ASC',
    },
  ],
}
export const pieconfig2 = {
  //se
  fields: ['budget'],
  groups: ['year', 'se'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'divisioncorp',
      operator: 'IN',
      values: divisionOption,
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'se',
      type: 'ASC',
    },
  ],
}
export const pieconfig3 = {
  //product
  fields: ['budget'],
  groups: ['year', 'product'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'Product',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'se',
      operator: 'EQUALS',
      values: ['BAIDU'],
    },
    {
      field: 'divisioncorp',
      operator: 'IN',
      values: divisionOption,
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'product',
      type: 'ASC',
    },
  ],
}

export const pieconfig4 = {
  //fbp - axios
  fields: ['budget'],
  groups: ['year', 'mediaformat'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'divisioncorp',
      operator: 'EQUALS',
      values: ['FBP'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'categorycorp',
      type: 'ASC',
    },
  ],
}
export const pieconfig5 = {
  //fbp - product
  fields: ['budget'],
  groups: ['year', 'product'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'product',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'divisioncorp',
      operator: 'EQUALS',
      values: ['FBP'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'product',
      type: 'ASC',
    },
  ],
}
export const pieconfig6 = {
  //wfj - axis
  fields: ['budget'],
  groups: ['year', 'mediaformat'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'divisioncorp',
      operator: 'EQUALS',
      values: ['WFJ'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'categorycorp',
      type: 'ASC',
    },
  ],
}
export const pieconfig7 = {
  //wfj - product
  fields: ['budget'],
  groups: ['year', 'product'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'product',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'divisioncorp',
      operator: 'EQUALS',
      values: ['WFJ'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'product',
      type: 'ASC',
    },
  ],
}

export const pieconfig8 = {
  //fa
  fields: ['budget'],
  groups: ['year', 'product'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    {
      field: 'product',
      operator: 'NOT_IN',
      values: ['Display'],
    },
    {
      field: 'divisioncorp',
      operator: 'EQUALS',
      values: ['FA'],
    },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'product',
      type: 'ASC',
    },
  ],
}
export const barhorconfig = {
  // axios
  fields: ['budget'],
  groups: ['year', 'mediaformat'],
  filters: [
    {
      field: 'year',
      operator: 'IN',
      values: [2021, 2022],
    },
    // {
    //   field: 'division',
    //   operator: 'EQUALS',
    //   values: ['FBP'],
    // },
  ],
  sorts: [
    {
      field: 'year',
      type: 'ASC',
    },
    {
      field: 'categorycorp',
      type: 'ASC',
    },
  ],
}

import styles from './index.module.less'
import { Radio, Button, Select, Checkbox, message } from 'antd'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { formatUnit, formatNum, numberWithCommas } from '@/common/util'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import LineArea from '@/view/cross/line-area'
import { getSum } from '@/api/home'
import { last } from 'lodash-es'
const CrossFilter = (props) => {
  const { wrap } = styles
  const {
    children,
    months,
    years,
    setmonths,
    resetting,
    setyears,
    unit,
    setDivision,
    division,
    yearsopt,
    labelWidth,
    updatetime,
    cost,
    lastcost,
    amountname = 'Budget',
    collapsed,
    // setProductLine
  } = props

  const [divHeight, setdivHeight] = useState()
  const [scrollTop, setScrollTop] = useState()

  useEffect((e) => {
    const box = document.getElementsByClassName('yearmonthbudget')
    if (box[0]) setdivHeight(box[0].offsetHeight)
  }, [])

  const scrollChange = () => {
    // 监听滚动条距离顶部距离
    if (document.getElementsByClassName('ant-layout-content')?.[0]) {
      setScrollTop(document.getElementsByClassName('ant-layout-content')[0].scrollTop)
    }
    //  console.log((document.getElementsByClassName('ant-layout-content')?.[0]))
    // console.log(document.documentElement.scrollTop,document.body.scrollTop)
    // 监听滚动条距离左部距离

    //这里有一个坑如果在页面内给某个div添加了滚动条用上面方法获取的数据会一直为0 可以用下边方法
    // 监听div内滚动条距离顶部距离
    // console.log(document.getElementById('bodybox')?.scrollTop)
    // 监听div内滚动条距离左部距离
    //  console.log(document.getElementById('bodybox')?.scrollLeft)
  }

  useEffect(() => {
    // 滚动条滚动时触发
    window.addEventListener('scroll', scrollChange, true)
    scrollChange()
    return () => {
      window.removeEventListener('scroll', scrollChange, false)
    }
  }, [])

  const onMonthChange = (val) => {
    if (val.length) {
      const index = val.indexOf('all')
      if (index === 0 && val.length > 0) return setmonths(val.slice(1)) //由默认转为其他
      if (index === val.length - 1 && val.length > 0) return setmonths(['all']) //由其他转为默认
      setmonths(val)
      //setCheckAll(checkedValues.length === monthOptions.length)
    } else {
      message.info('至少保留一个月份')
    }
  }
  const onYearChange = (val) => {
    if (val.length) {
      const index = val.indexOf('all')

      if (index === 0 && val.length > 0) return setyears(val.slice(1)) //由默认转为其他
      if (index === val.length - 1 && val.length > 0) return setyears(['all']) //由其他转为默认
      return setyears(val)
    } else {
      message.info('至少保留一个年份')
    }
  }
  const [checkAll, setCheckAll] = useState(true)
  const [checkYearAll, setCheckYearAll] = useState(yearsopt.length === 1 ? true : false)

  const monthOptions = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  // const getProductLine = ()=>{
  //   getSum({
  //     groups: ['productline'],
  //   })
  //   con
  // }
  // getProductLine()

  // const onCheckAllChange = (e) => {
  //   setmonths(e.target.checked ? monthOptions : ['Jan'])
  //   setCheckAll(e.target.checked)
  // }
  // const onCheckAllYearChange = (e) => {
  //   setyears(e.target.checked ? yearsopt : [yearsopt[0]])
  //   setCheckYearAll(e.target.checked)
  // }
  const [close, setClose] = useState(false)
  const changeClose = () => {
    setClose(!close)
  }
  return (
    <div style={{ backgroundColor: '#fff' }}>
      {/* (scrollTop>100?0:-divHeight)+'px'marginTop:-divHeight+'px' */}
      <div
        className={wrap}
        style={{
          position: 'fixed',
          left: collapsed ? '94px' : '269px',
          top: scrollTop > 100 ? '58px' : 'auto',
          right: '26px',
          backgroundColor: '#fff',
          zIndex: 90,
        }}
      >
        <div className="closeTool" onClick={changeClose}>
          <ArrowUpOutlined style={{ display: close ? 'none' : 'block' }} />
          <ArrowDownOutlined style={{ display: !close ? 'none' : 'block' }} />
        </div>
        {/* style={{ position: 'fixed',left:'269px',top:'58px',right:'26px',backgroundColor:'#fff',zIndex:90 }}  */}
        {/* <div className="content">{children}</div> */}
        {/* <div className="division-filter"> */}
        {/* <div>
          Division：
          <Radio.Group value={division} onChange={(e) => setDivision(e.target.value)}>
            <Radio.Button value="all">全部</Radio.Button>
            <Radio.Button value="FA">FA</Radio.Button>
            <Radio.Button value="FBP">FBP</Radio.Button>
            <Radio.Button value="WFJ">WFJ</Radio.Button>
            <Radio.Button value="CORPORATE">Corp</Radio.Button>
          </Radio.Group>
        </div> */}

        {/* </div> */}
        <div className="yearmonthbudget" style={{ display: close ? 'none' : 'block' }}>
          <div className="yearmonthbudget2">
            <div className="toolLine">
              <div className="label" style={{ width: labelWidth ? labelWidth : null }}>
                Year
              </div>
              <Select
                maxTagCount={3}
                options={[{ label: '全部', value: 'all' }].concat(
                  yearsopt.map((i) => {
                    return { label: i, value: i }
                  })
                )}
                value={years}
                onChange={onYearChange}
                style={{ width: '240px' }}
                mode={'multiple'}
              />
            </div>
            <div className="toolLine">
              <div className="label" style={{ width: labelWidth ? labelWidth : null }}>
                Month
              </div>
              <Select
                maxTagCount={3}
                options={[{ label: '全部', value: 'all' }].concat(
                  monthOptions.map((i) => {
                    return { label: i, value: i }
                  })
                )}
                value={months}
                onChange={onMonthChange}
                style={{ width: '240px' }}
                mode={'multiple'}
              />
              {/* <Checkbox onChange={onCheckAllChange} checked={checkAll} defaultChecked={true}>
              全选
            </Checkbox>
            <Checkbox.Group
              options={monthOptions}
              value={months}
              onChange={onMonthChange}
              className="monthwrap"
            /> */}
            </div>
            {children}
          </div>
          <div className="bottomTool">
            <Button onClick={resetting}>重置</Button>{' '}
            <span className="time">Data update to {updatetime}</span>{' '}
          </div>
        </div>
      </div>
      <div style={{ height: close ? '2px' : divHeight + 'px' }}></div>
    </div>
  )
}

export default connect((state) => ({
  yearsopt: state.years.slice().reverse(),
  unit: state.unit,
  collapsed: state.collapsed,
}))(CrossFilter)

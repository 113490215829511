import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
const globalState = {
  token: '',
  years: [], //存在本地 路由变动的时候调接口更新
  auth: {},
  division: ['all'],
  unit: 'n',
}
const persistConfig = {
  key: 'root',
  storage,
}
function rootReducer(state = globalState, action) {
  switch (action.type) {
    case 'SETDIVISION':
      return {
        ...state,
        division: action.division,
      }
    case 'SETCOLLAPSED':
      return {
        ...state,
        collapsed: action.collapsed,
      }
    case 'SETTOKEN':
      return {
        ...state,
        token: action.token,
      }
    case 'REFRESH':
      return {
        token: '',
        years: [],
        auth: {},
        division: ['all'],
        unit: 'n',
        collapsed: false,
      }
    case 'SETYEARS':
      return {
        ...state,
        years: action.years,
      }
    case 'SETAUTH':
      return {
        ...state,
        auth: action.auth,
      }
    case 'SETUNIT':
      return {
        ...state,
        unit: action.unit,
      }
    default:
      return state
  }
}

let store = createStore(persistReducer(persistConfig, rootReducer), applyMiddleware(thunk, logger))
let persistor = persistStore(store)
export { persistor, store }

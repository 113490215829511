import Card from '../../components/Card'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styles from './index.module.less'
import { Radio, Select, Tabs } from 'antd'
import CrossFilter from '@/components/CrossFilter'
import { wechatsum, getSum1_2 } from '@/api/home'
import Crossbar2 from './Crossbar2-webchat'
import TrendPie from './trend-pie-wechat'
import TrendDivisionAndYear from './trendDivisionAndYear'
import TrendDivisionAndYear1 from './trendDivisionAndYear1'
import TrendDivisionAndYear2 from './trendDivisionAndYear2'
import { themecolor, themecolor_pie1 } from '@/common/vars'
import CrossPerformance from '@/view/cross/performance'
import { setDivision } from '@/redux/actions'
import WechatTable from './WechatTable.jsx'
const { wrap } = styles
const cardstyle = {
  // marginTop: '16px',
}
const linestyle = {
  height: '500px',
  backGroundColor: 'red',
}
const Budget = (props) => {
  const onChange = () => {}
  const { currentYear, division, setDivision, unit, yearsopt } = props
  //const [unit, setUnit] = useState('K')
  const [months, setmonths] = useState([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ])
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const [checkDivision, setCheckDivision] = useState()
  const [years, setyears] = useState([currentYear])
  const [ysval, setysval] = useState(0)
  // const [division, setDivision] = useState('all')
  const [campaigndashboard, setCampaigndashboard] = useState(['all'])
  const [campaigndashboardOpt, setCampaigndashboardOpt] = useState([])

  const [axis, setAxis] = useState(['all'])
  const opt = {
    FA: ['FA'],
    WFJ: ['FJ', 'WAT'],
    FBP: ['FR', 'MU', 'SK'],
    CORPORATE: ['Corp'],
  }
  const [axisOpt, setAxisOpt] = useState([])
  useEffect(() => {
    setAxis(['all'])
    if (division.includes('all')) {
      setAxisOpt([
        { value: 'all', label: '全部' },
        ...Object.values(opt)
          .flat()
          .map((i) => ({ value: i, label: i })),
      ])
    } else {
      let newopt = {}
      for (let i = 0; i < division.length; i++) {
        let item = division[i]
        newopt[item] = opt[item]
      }
      setAxisOpt([
        { value: 'all', label: '全部' },
        ...Object.values(newopt)
          .flat()
          .map((i) => ({ value: i, label: i })),
      ])
    }
  }, [division])

  const [updatetime, setupdatetime] = useState('')
  const [cost, setCost] = useState(0)

  const tableData = []
  const getUpdateTime = () => {
    getSum1_2({
      title: 'updateTime',
      fields: ['maxyearandmonth'],
      groups: ['year'],
      sorts: [{ field: 'maxyearandmonth', type: 'DESC' }],
    }).then((res) => {
      setupdatetime(res[0].maxyearandmonth)
    })
  }
  const resetting = (val) => {
    setyears([currentYear])
    setmonths(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])
    setAxis(['all'])
  }
  const handleChange = (val, setfn) => {
    //全部交互需要编写受控逻辑才能完成  全部与其他互斥
    if (!val.length) return setfn(['all'])
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) return setfn(val.slice(1)) //由默认转为其他
    if (index === val.length - 1 && val.length > 0) return setfn(['all']) //由其他转为默认
    return setfn(val)
  }
  const getopt = (name, setfn) => {
    wechatsum({
      groups: [name],
    }).then((res) => {
      setfn([
        {
          label: '全部',
          value: 'all',
        },
        ...res.map((i) => ({ label: i[name], value: i[name] })),
      ])
    })
  }
  const getOverviewCost = () => {
    if (!axisOpt.length) return
    wechatsum({
      fields: ['cost'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years.includes('all') ? yearsopt : years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.includes('all')
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            : months.map((i) => map.indexOf(i)),
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
        },
        {
          field: 'mediagroup',
          operator: 'IN',
          values: ['Tencent'],
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: ['Channel Feeds'],
        },
      ],
    }).then((res) => {
      // debugger
      const cost = res[0]?.cost
      setCost(cost)
      //  setUnit(cost >= 100000 ? 'M' : 'K')
    })
  }
  useEffect(() => {
    // getopt('axis', setAxisOpt)
    getUpdateTime()
  }, [])
  useEffect(() => {
    getOverviewCost()
  }, [axisOpt, axis, years, months])

  return (
    <Tabs defaultActiveKey="1" style={{ backgroundColor: '#fff', padding: '0px 12px' }}>
      <Tabs.TabPane tab="view" key="1">
        <div className={wrap}>
          <CrossFilter
            updatetime={updatetime}
            resetting={resetting}
            cost={cost}
            division={division}
            setDivision={setDivision}
            months={months}
            setmonths={setmonths}
            setyears={setyears}
            years={years}
            amountname="Cost"
            // setProductLine={setProductLine}
          >
            <div className="toolLine">
              <span className="label">Axis：</span>
              <Select
                maxTagCount={1}
                options={axisOpt}
                value={axis}
                onChange={(val) => handleChange(val, setAxis)}
                style={{ width: '240px' }}
                mode={'multiple'}
              />
            </div>
            {/* <div className="line">
          <span className="label">Product：</span>
          <Select
            maxTagCount={5}
            options={productOpt}
            value={product}
            onChange={(val) => handleChange(val, setProduct)}
            style={{ width: '450px' }}
            mode={'multiple'}
          />
        </div> */}
          </CrossFilter>
          <Card title="Channel Feeds- Wechat" style={cardstyle} headerLine={true}>
            <div className="line" style={linestyle}>
              <div className="line-1-left" style={{ height: '500px' }}>
                <TrendPie
                  unit={unit}
                  title="Cost By Division"
                  division={division}
                  yearsopt={yearsopt}
                  years={years}
                  months={months}
                  setClickbrand2={setCheckDivision}
                  axis={axis}
                  axisOpt={axisOpt}
                  dataViewHeaderFirstTitle="Axis"
                  yname="divisioncorp"
                  // argsfn={argscreator(pieconfig1)}
                  color={themecolor_pie1}
                  // color1={themecolor_pie11}
                ></TrendPie>
              </div>
              <div className="line-1-right">
                {/* <Card title="Cost By campaign" style={cardstyle}> */}
                <Crossbar2
                  hor={true}
                  title="Cost By Campaign"
                  unit={unit}
                  checkDivision={checkDivision}
                  xname="campaignname"
                  yname="cost"
                  dataViewHeaderFirstTitle="Campaign-Dashboard"
                  division={division}
                  yearsopt={yearsopt}
                  years={years}
                  months={months}
                  axis={axis}
                  axisOpt={axisOpt}
                  style={{ width: '100%' }}
                  color={['#0f4e77']}
                  campaigndashboard={campaigndashboard}
                  cb={(opt) => {
                    setCampaigndashboardOpt(opt)
                  }}
                ></Crossbar2>
                {/* </Card> */}
              </div>
            </div>
          </Card>
          {/* <Card
            title={
              <div>
                <span className="label">Campain Name：</span>
                <Select
                  maxTagCount={1}
                  options={campaigndashboardOpt}
                  value={campaigndashboard}
                  onChange={(val) => handleChange(val, setCampaigndashboard)}
                  style={{ width: '300px' }}
                  mode={'multiple'}
                />
              </div>
            }
            headerLine={false}
          >
            <div style={{ marginTop: '24px' }}>
              <WechatTable
                campaigndashboard={campaigndashboard}
                division={division}
                years={years}
                axis={axis}
                axisOpt={axisOpt}
                months={months}
              ></WechatTable>
            </div>
          </Card> */}
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Overview" key="2">
        <CrossPerformance mark={'channelFeedsWechat'} headertitle={'Overview Wechat'} />
      </Tabs.TabPane>
    </Tabs>
  )
}
export default connect(
  (state) => ({
    currentYear: state.years[state.years.length - 1],
    division: state.division,
    yearsopt: state.years.slice().reverse(),
    unit: state.unit,
    // preYear: state.years[state.years.length - 2],
  }),
  { setDivision }
)(Budget)

import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

// import { getIndustrySum, getIndustryProduct } from '../../api/home'
import { getPerformanceSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendLineOption } from './util'
import { divisionOption } from '@/common/vars'
import { map } from '@/common/util'
import axios from 'axios'
import { Empty } from 'antd'
import { Radio } from 'antd'
export default function TrendLineProd(props) {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const { title, years, product, division, formatpercent } = props
  const trend = useRef(null)

  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend)
  const [average, setAverage] = useState([])
  const [field, setField] = useState('ctr')
  const radioOption = [
    {
      label: 'CTR',
      value: 'ctr',
    },

    {
      label: 'CPC',
      value: 'cpc',
    },
    {
      label: 'CVR',
      value: 'cvr_search',
    },
  ]
  const onChange2 = ({ target: { value } }) => {
    setField(value)
  }
  const getma = async () => {
    return getPerformanceSum({
      fields: [field],
      groups: ['year'],
      title: '图表数据',
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years,
        },
        {
          field: 'divisioncorp',
          operator: 'IN',
          // values: division,
          values: division.includes('all') ? divisionOption : division,
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          // values: division,
          values: product,
        },
        {
          field: 'mediachannel',
          operator: 'IN',
          values: ['Paid Search'],
        },
      ],
    })
  }
  useEffect(() => {
    // if (!brand) return
    if (!product || !product.length) {
      setAverage([])
      return setEmpty(true)
    }
    getPerformanceSum({
      fields: [field],
      title: '均值',
      groups: ['year', 'month'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years,
        },
        {
          field: 'divisioncorp',
          operator: 'IN',
          // values: division,
          values: division.includes('all') ? divisionOption : division,
        },
        {
          field: 'mediachannel',
          operator: 'IN',
          values: ['Paid Search'],
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          // values: division,
          values: product,
        },
      ],
      sorts: [
        {
          field: 'year',
          type: 'ASC',
        },
        {
          field: 'month',
          type: 'DESC',
        },
      ],
    }).then(async (res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      const ma = await getma()

      const avetext = ma.map(
        (i) => `
        ${i.year} ${field.replace('_search', '').toLocaleUpperCase()}  YTD均值：${
          formatpercent ? i[field] + '%' : i[field]
        }
        `
      )
      setAverage(avetext)

      const preyear = years[1]
      const curyear = years[0]
      const curyeardata = res.filter((i) => i.year === curyear)
      curyeardata.sort((a, b) => map[a.month] - map[b.month])
      const preyeardata = res.filter((i) => i.year === preyear)
      preyeardata.sort((a, b) => map[a.month] - map[b.month])
      // const preyeardata = _preyeardata.slice(0, curyeardata.length)

      let data = getTrendLineOption({
        preyeardata,
        curyeardata,
        preyear,
        curyear,
        field,
        formatpercent,
      })

      updateCharts(data)
    })
    return () => {
      console.log('Operation canceled by the user')
      source.cancel('Operation canceled by the user')
    }
  }, [years, product, division, field]) //division,

  return (
    <div className="trend-wrap" style={{ position: 'relative' }}>
      {average.length ? (
        <div
          className="average"
          style={{
            position: 'absolute',
            top: '60px',
            left: '15px',
            fontSize: '10px',
            color: '#333',
          }}
        >
          {average.join(',')}
        </div>
      ) : null}
      <div className="tool2">
        <div className="title">
          <div>
            {
              radioOption.filter((it) => {
                return it.value === field
              })[0].label
            }{' '}
          </div>
        </div>
        <div>
          <Radio.Group
            options={radioOption}
            onChange={onChange2}
            value={field}
            size="small"
            optionType="button"
          />
        </div>
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{ display: empty ? 'none' : 'block', width: '100%', height: '330px' }}
      ></div>
    </div>
  )
}

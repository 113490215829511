import { Navigate, useRoutes } from 'react-router-dom'
import Login from '../view/login/login'
import Home from '../view/home'
import NotFound from '../components/ErrorMessage/404'
import LayoutIndex from '../layout/index'
import Fbp from '@/view/home/fbp'
import Fa from '@/view/home/fa'
import Wfj from '@/view/home/wfj'
import Performance from '@/view/performance'
import Searchshare from '@/view/performance/searchshare'
import Upload from '@/view/upload'
import Industry from '@/view/industry'
import Division from '@/view/division'
import Budget from '@/view/cross/budget'
import Cost from '@/view/detail/Cost'
import CrossPerformance from '@/view/cross/performance'
import Moment from '@/view/products/moment'
import Wechat from '@/view/products/Wechat'
import Red from '@/view/products/Red'
export const rootRouter = [
  {
    path: '/',
    element: <Navigate to="/login" />,
  },
  {
    path: '/login',
    element: <Login />,
    meta: {
      title: '登录页',
      key: 'login',
    },
  },
  {
    element: <LayoutIndex />,
    path: '/home',
    children: [
      {
        path: '/home/index',
        element: <Home />,
        meta: {
          requiresAuth: true,
          title: '主页',
          key: 'home',
        },
      },
      {
        path: '/home/fa',
        element: <Fa />,
      },
      {
        path: '/home/wfj',
        element: <Wfj />,
      },
      {
        path: '/home/fbp',
        element: <Fbp />,
      },
    ],
  },
  {
    element: <LayoutIndex />,
    path: '/performance',
    children: [
      {
        path: '/performance/index',
        element: <Performance />,
      },
      {
        path: '/performance/searchshare',
        element: <Searchshare />,
      },
    ],
  },
  {
    element: <LayoutIndex />,
    path: '/cross',
    children: [
      {
        path: '/cross/budget',
        element: <Budget />,
      },
      {
        path: '/cross/performance',
        element: <CrossPerformance mark={'crossPerformance'} />,
      },
    ],
  },
  {
    element: <LayoutIndex />,
    path: '/detail',
    children: [
      {
        path: '/detail/cost',
        element: <Cost />,
      },
      {
        path: '/detail/performance',
        element: <CrossPerformance mark={'crossPerformance'} />,
      },
    ],
  },
  {
    element: <LayoutIndex />,
    path: '/products',
    children: [
      {
        path: '/products/moment',
        element: <Moment />,
      },
      {
        path: '/products/wechat',
        element: <Wechat />,
      },
      {
        path: '/products/red',
        element: <Red />,
      },
    ],
  },
  {
    element: <LayoutIndex />,
    path: '/industry',
    children: [
      {
        path: '/industry/index',
        element: <Industry />,
      },
    ],
  },
  {
    element: <LayoutIndex />,
    path: '/division',
    children: [
      {
        path: '/division/index',
        element: <Division />,
      },
    ],
  },
  {
    element: <LayoutIndex />,
    path: '/upload',
    children: [
      {
        path: '/upload/index',
        element: <Upload />,
      },
    ],
  },
  {
    path: '/404',
    element: <NotFound />,
  },
  {
    path: '*',
    element: <Navigate to="/404" />,
  },
]
const Router = () => {
  const routes = useRoutes(rootRouter)
  return routes
}

export default Router

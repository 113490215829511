import Card from '../../components/Card'
import { useEffect, useState } from 'react'
import { store } from '@/redux'
import { connect } from 'react-redux'
import styles from './index.module.less'
import Filter from '@/components/Filter'
import { dimensionOption, indexOption, divisionOption } from '@/common/vars'

import { getSum3 } from '@/api/home'
import Child from './Child'
import TreeTable2 from './treeTable2'
import { setDivision } from '@/redux/actions'
const { wrap } = styles

const cardstyle = {
  marginTop: '-40px',
}

const CrossPerformance = (props) => {
  const [updatetime, setupdatetime] = useState('')
  const [cost, setCost] = useState(0)
  const getUpdateTime = () => {
    getSum3({
      title: 'updateTime',
      fields: ['maxyearandmonth'],
      groups: ['year'],
    }).then((res) => {
      console.log(res)
      setupdatetime(res[0].maxyearandmonth)
    })
  }

  const onChange = () => {}
  const { currentYear, division, setDivision, mark, headertitle, unit } = props
  const [dates, setDates] = useState(null)

  const [months, setmonths] = useState([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ])

  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const [years, setyears] = useState([currentYear])
  // const [division, setDivision] = useState('all')
  const [campaignname, setCampaignname] = useState(['all'])
  const [campaignnameOpt, setCampaignnameOpt] = useState([])

  //const mark='GDFeedsRed'//'channelFeedsWechat'//'momentsRTBWechat'//'crossPerformance'
  const [fields, setFields] = useState([]) //自定义选项（维度与指标）
  const [sorter, setSorter] = useState(undefined)

  const [defaultOption, setDefaultOption] = useState([])
  const [myDimensionOption, mySetDimensionOption] = useState([])
  const [myIndexOption, mySetindexOption] = useState([])
  const [data, setData] = useState([])
  const handleCampaignnameChange = (val) => {
    //全部交互需要编写受控逻辑才能完成  全部与其他互斥
    if (!val.length) return setCampaignname(['all'])
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) return setCampaignname(val.slice(1)) //由默认转为其他
    if (index === val.length - 1 && val.length > 0) return setCampaignname(['all']) //由其他转为默认
    return setCampaignname(val)
  }
  const getCampaignname = () => {
    getSum3({
      groups: ['campaignname'],
      // groups: ['campaigndashboard'],
    }).then((res) => {
      setCampaignnameOpt([
        {
          label: '全部',
          value: 'all',
        },
        ...res.map((i) => ({ label: i.campaignname, value: i.campaignname })),
      ])
    })
  }

  const [initState, setinitState] = useState(-1)
  useEffect(() => {
    // localStorage.removeItem(mark+'indicators'+store.getState().auth.name)
    //----------自定义维度与指标数据--------------
    const dimensionlist = dimensionOption.filter((it) => {
      return it.mark && it.mark[mark] && it.mark[mark].option
    })
    mySetDimensionOption(dimensionlist)
    const indexlist = indexOption.filter((it) => {
      return it.mark && it.mark[mark] && it.mark[mark].option
    })
    mySetindexOption(indexlist)
    setDefaultOption(
      dimensionlist
        .filter((it) => {
          return it.mark && it.mark[mark] && it.mark[mark].default
        })
        .concat(
          indexlist.filter((it) => {
            return it.mark && it.mark[mark] && it.mark[mark].default
          })
        )
    )
    const myindicators = localStorage.getItem(mark + 'indicators' + store.getState().auth.name)
    setFields(myindicators ? JSON.parse(myindicators) : defaultOption) //(defaultOption)
    //--------------------------------------
    setinitState(0)
  }, [initState])
  useEffect(() => {
    if (fields.length > 0)
      localStorage.setItem(mark + 'indicators' + store.getState().auth.name, JSON.stringify(fields))
  }, [fields])
  return (
    <div className={wrap}>
      <Filter
        title={headertitle || 'Overview Performance'}
        headerLine={true}
        updatetime={updatetime}
        cost={cost}
        division={division}
        setDivision={setDivision}
        months={months}
        years={years}
        setmonths={setmonths}
        setyears={setyears}
        labelWidth={120}
        dates={dates}
        setDates={setDates}
        fields={fields}
        setFields={setFields}
        data={data}
        mark={mark}
        sorter={sorter}
        setData={setData}
        defaultOption={defaultOption}
        myDimensionOption={myDimensionOption}
        myIndexOption={myIndexOption}
        // setProductLine={setProductLine}
      ></Filter>

      <Card title="" style={cardstyle} headerLine={true}>
        <TreeTable2
          dates={dates}
          fields={fields}
          months={months}
          division={division}
          years={years}
          data={data}
          unit={unit}
          sorter={sorter}
          setSorter={setSorter}
          myDimensionOption={myDimensionOption}
          myIndexOption={myIndexOption}
        ></TreeTable2>
      </Card>
    </div>
  )
}
export default connect(
  (state) => ({
    currentYear: state.years[state.years.length - 1],
    division: state.division,
    unit: state.unit,
    // preYear: state.years[state.years.length - 2],
  }),
  { setDivision }
)(CrossPerformance)

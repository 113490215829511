import { getBudgetSum, getYear } from '@/api/home'
const setDivision = (division) => ({
  type: 'SETDIVISION',
  division,
})
const setUnit = (unit) => ({
  type: 'SETUNIT',
  unit,
})
const setCollapsed = (collapsed) => ({
  type: 'SETCOLLAPSED',
  collapsed,
})
const setToken = (token) => ({
  type: 'SETTOKEN',
  token,
})
const setAuth = (auth) => ({
  type: 'SETAUTH',
  auth,
})
const setYears = (years) => ({
  type: 'SETYEARS',
  years,
})
const refresh = () => ({
  type: 'REFRESH',
})
const requestYears =
  (apiname = 'newbudget') =>
  (dispatch) => {
    return getYear(apiname)({
      groups: ['year'],
      sorts: [
        {
          field: 'year',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      const years = res.map((i) => i.year)
      dispatch(setYears(years))
    })
  }

export { setToken, requestYears, setAuth, refresh, setDivision, setUnit, setCollapsed }

import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import SeSelector from './seSelector'
import { getBudgetSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendPieChartsOption } from './util'
import { formatUnit, formatNum } from '@/common/util'
import { Empty } from 'antd'

export default function TrendPie(props) {
  const {
    title,
    years,
    showSeSelector,
    argsfn,
    clickjump,
    division,
    setClickbrand,
    se,
    unit,
    dataViewHeaderFirstTitle = 'Division',
    color,
    color1,
  } = props
  // const [se, setSe] = useState(divisionOption) //选择项
  const trend = useRef(null)
  const { updateCharts } = useCharts(trend, { clickjump, setClickbrand })
  const [empty, setEmpty] = useState(false)
  const formatPieData = (data, key) => {
    const res = {}
    data.forEach((i) => {
      const name = i[key]
      if (!res[name]) res[name] = { year: [], budgetamount: [] }
      if (i.year === years[0] && res[name].year.length === 0) {
        // 补一下前年没值的问题
        res[name].year.push(years[1])
        res[name].budgetamount.push(0)
      }
      res[name].year.push(i.year)
      res[name].budgetamount.push(formatNum(i.budgetamount, unit, 0))
    })
    return res
  }
  // const sechange = (val) => {
  //   setSe(val)
  // }
  // se或者year变化会重新请求数据
  useEffect(() => {
    getBudgetSum(argsfn({ yearVal: years, se: se })).then((res) => {
      // debugger
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      const keyname = ['mediaformat', 'division', 'media', 'categorycorp'] //可能的keyname
      for (let key in res[0]) {
        if (keyname.includes(key)) {
          const formatdata = formatPieData(res, key)
          // setTimeout(() => {
          //   console.log('render echarts')
          updateCharts(
            getTrendPieChartsOption(
              formatdata,
              {
                outlabel: years[1],
                inlabel: years[0],
                dataViewHeaderFirstTitle,
                color,
                color1,
              },
              unit
            )
          )
          // }, 1000);
        }
      }
    })
  }, [years, se, unit])

  return (
    <div className="trend-wrap">
      <div className="title">
        <div>{title}</div>
        {/* {showSeSelector ? (
          <div style={{ marginRight: '60px' }}>
            <SeSelector onChange={sechange}></SeSelector>
          </div>
        ) : null} */}
      </div>
      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{
          position: 'relative',
          width: '100%',
          height: '300px',
          display: empty ? 'none' : 'block',
        }}
      ></div>
    </div>
  )
}

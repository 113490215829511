import { dataViewConfigCreator, formatUnit, numberWithCommas } from '@/common/util'
import { themecolor, themecolor_pie, themecolor_pie1, themecolor_two } from '@/common/vars'

export const addtablekey = (tree) => {
  const fn = (node, lv = 1) => {
    node.key = lv
    if (!node.children) return
    if (!node.children.length) return
    node.children.forEach((i, index) => {
      fn(i, lv + `-${index}`)
    })
  }
  tree.forEach((i, index) => {
    fn(i, index)
  })
}
export const formatmonth = (i) => {
  const monthname = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return monthname[i]
}
export const formatNum = (num, unit = 'K') => {
  if (!num) return 0
  let res
  if (unit === 'M') {
    res = (num / (1000 * 1000))?.toFixed(2)
  } else {
    res = (num / 1000)?.toFixed(2)
  }
  // debugger

  return parseFloat(res)
}
export const getMonthTrendBarChartsOption = ({ xAxisData, series }) => {
  let seriesData = []
  for (let keyname in series) {
    seriesData.push({
      name: keyname,
      type: 'bar',
      //   stack: 'ad',
      barWidth: '12',
      emphasis: {
        focus: 'series',
      },
      data: series[keyname],
    })
    seriesData.reverse()
  }
  return {
    color: themecolor_two,
    legend: { right: '50' }, //颜色提示
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = opt.xAxis[0].data
          var series = opt.series
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Month</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series
                .map((j) => `<td>${j.data[i] ? numberWithCommas(j.data[i]) : 0}</td>`)
                .join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
      {
        type: 'value',
      },
    ],
    series: seriesData,
  }
}

export const getMonthTrendBarChartsOption2 = ({ xAxisData, barseries, lineseries }) => {
  console.log(xAxisData, barseries, lineseries)
  // barseries.sort((a,b)=>{
  //   if(a.)
  // })
  let seriesData1 = []
  let seriesData2 = []
  for (let keyname in barseries) {
    seriesData1.push({
      name: keyname,
      type: 'bar',
      stack: 'ad',
      barMaxWidth: '12',
      emphasis: {
        focus: 'series',
      },
      data: barseries[keyname],
    })
  }
  // v1.2 Paid Search需求放到最下面
  const index = seriesData1.findIndex((i) => i.name === 'Paid Search')
  if (index >= 0) {
    const delItem = seriesData1[index]
    seriesData1.splice(index, 1)
    seriesData1.unshift(delItem)
  }
  for (let keyname in lineseries) {
    const l_keyname = keyname.toLocaleLowerCase()
    seriesData1.push({
      name: keyname + ' Share',
      type: 'line',
      smooth: true,
      yAxisIndex: 1,
      emphasis: {
        focus: 'series',
      },
      data: lineseries[keyname],
      tooltip: {
        valueFormatter: function (value) {
          return value + ' %'
        },
      },
    })
  }
  // debugger
  return {
    color: themecolor,
    legend: { right: '50' }, //颜色提示
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = opt.xAxis[0].data
          var series = opt.series
          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Month</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series
                .map(
                  (j, index) =>
                    `<td>${j.data[i] ? numberWithCommas(j.data[i]) : 0}${
                      index === series.length - 1 ? '%' : ''
                    }</td>`
                )
                .join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    tooltip: {
      trigger: 'axis',
      // axisPointer: {
      //   type: 'shadow',
      // },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: '{value} ',
        },
      },
      {
        type: 'value',
        axisLabel: {
          formatter: '{value} %',
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: seriesData1,
  }
}

export const getTrendLineOption = ({ preyeardata, curyeardata, preyear, curyear, field }) => {
  // const series1 = preyeardata.map((i) => i.si)
  const series1 = new Array(12).fill(0)
  preyeardata.forEach((i) => {
    const index = i.month - 1
    series1[index] = i[field]
  })

  // debugger
  const series2 = curyeardata.map((i) => i[field])

  const xAxisData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => formatmonth(i))

  return {
    color: themecolor_two,
    tooltip: {
      trigger: 'axis',
    },
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = opt.xAxis[0].data
          var series = opt.series

          // 表格头  先name再data
          var table =
            '<table border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            '<td>Month</td>' +
            series.map((i) => `<td>${i.name}</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          for (var i = 0, l = axisData.length; i < l; i++) {
            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series
                .map((j) => `<td>${j.data[i] ? numberWithCommas(j.data[i]) : 0}</td>`)
                .join('') +
              '</tr>'
          }
          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: {
      data: [preyear + '', curyear + ''],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    // toolbox: {
    // feature: {
    //   saveAsImage: {},
    // },
    // },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: curyear,
        type: 'line',
        data: series2,
        smooth: true,
      },
      {
        name: preyear,
        type: 'line',
        smooth: true,
        data: series1,
      },
    ],
  }
}

export const getTrendBarChartsOption = (
  {
    xData,
    yData1,
    yData2,
    // ynames,
    formatpercent,
    dataViewHeaderFirstTitle,
  },
  unit
) => {
  let seriesData = [
    {
      // name: ynames[index],
      type: 'bar',
      barWidth: '16',
      // emphasis: {
      //   focus: 'series',
      // },
      data: yData1,
    },
    // {

    //   type: 'line',
    //   show:false,
    //  // yAxisIndex: 1,
    //   // tooltip: {
    //   //   valueFormatter: function (value) {
    //   //     return value + ' %'
    //   //   },
    //   // },
    //   data: yData2,
    // },
  ]
  const res = {
    toolbox: {
      feature: {
        dataView: dataViewConfigCreator((opt) => {
          var axisData = opt.xAxis[0].data // [1,2,3,4,5...12]

          var series = [{ data: yData1 }, { data: yData2 }] //opt.series // [tencent,red,baidu,non-baidu]

          // 表格头  先name再data
          var table =
            '<table id="cur_table" border="1" style="width:100%;text-align:center"><tbody><tr style="background-color:rgba(0,0,0,0.15);">' +
            `<td>${dataViewHeaderFirstTitle ? dataViewHeaderFirstTitle : 'Month-Year/Cost'}</td>` +
            // axisData.map((i) => `<td>${i} Budegt</td>`).join('') +
            '<td>si</td><td>MoM-/+</td>' +
            // series.map((i) => `<td>si</td>`).join('') +
            '</tr>'
          // 表格题  先name再data
          // const curyearsum = axisData.map((i, index) => {
          //   return series
          //     .map((j) => j.data[index])
          //     .reduce((pre, cur) => (pre += cur))
          //     ?.toFixed(1)
          // })
          // console.log(curyearsum)
          // debugger
          // let totalsum = 0
          for (var i = 0, l = axisData.length; i < l; i++) {
            // const sum = series[i].data.reduce((pre, cur) => (pre += cur))
            // totalsum += sum
            // const curyearsum = series.map(j=>j.data[i]).reduce((pre,cur)=>pre+=cur)
            // debugger

            table +=
              '<tr>' +
              '<td>' +
              axisData[i] +
              '</td>' +
              series
                .map(
                  (j, jindex) =>
                    // `<td>${j}</td> <td>${((j * 100) / curyearsum[jindex])?.toFixed(1)}%</td>`
                    `<td>${
                      jindex !== 1
                        ? j.data[i].value || j.data[i]
                        : (j.data[i].value || j.data[i]) + '%'
                    }</td>`
                )
                .join('') +
              '</tr>'
          }
          // table +=
          //   '<tr>' +
          //   '<td>' +
          //   'Total' +
          //   '</td>' +
          //   curyearsum.map((i) => `<td>${i}</td>`).join('') +
          //   '</tr>'

          table += '</tbody></table>'
          return table
        }),
      },
    },
    legend: { right: '50' }, //颜色提示
    tooltip: {
      trigger: 'axis',
      // formatter: (params) => {
      //   const year = params[0].axisValueLabel
      //   let res = `${year}<br/>`
      //   let sum = 0
      //   params.forEach((i) => {
      //     const { seriesName, value, marker } = i
      //     sum += value
      //     //"<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#5470c6;"></span>"
      //     res += `<div style="display:flex;width:150px;align-item:center;justify-content:space-between"><span>${marker}${seriesName}: </span><span style="display:inline-block;text-align:right;">${value}</span> </div>`
      //   })
      //   res += `<div style="display:flex;width:150px;align-item:center;justify-content:space-between"><span style="font-weight: 700">Total: </span>${sum?.toFixed(
      //     2
      //   )}</div>`

      //   return res
      // },
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: xData,
      },
    ],
    yAxis: [
      {
        name: formatUnit(unit) + '(RMB)',
        type: 'value',
        axisLabel: {
          formatter: formatpercent ? '{value} %' : '{value}',
        },
      },
      {
        type: 'value',
        show: false,
        // axisLabel: {
        //   formatter: '{value} %',
        // },
      },
    ],
    series: seriesData,
    color: themecolor_pie1,
  }
  return res
}

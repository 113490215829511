import { connect } from 'react-redux'
import { useEffect, useState, useImperativeHandle, useMemo } from 'react'
import { getIndustrySum, getIndustryProduct } from '@/api/home'
import CombineDate from '@/components/combineDate'
import { Divider, Checkbox, Select, Space, Button } from 'antd'
import { formatmonth } from './util'
import { divisionOption } from '@/common/vars'
const Filters = (props) => {
  const {
    onChange,
    val,
    brand,
    division,
    axis,
    media,
    currentYear,
    yearsOpt,
    product,
    preYear,
    defaultOpt,
    defaultSelectAllProd,
    getRank,
    years,
  } = props
  // 最新值
  let curdivision = val.division
  let curaxis = val.axis
  let curCag = val.category
  const [cagopt, setCagopt] = useState([])
  const [brandopt, setBrandopt] = useState([])
  const [productopt, setProductopt] = useState([])
  const [axisOpt, setAxisOpt] = useState([{ label: 'FA', value: 'FA' }])
  const divisionData = divisionOption
  const axisData = {
    CORPORATE: ['CORPORATE'],
    FA: ['FA'],
    FBP: ['FRA', 'MU', 'SK'],
    WFJ: ['JEWERY', 'WATCH'],
  }

  const getCategoryopt = () => {
    onChange('category', [])
    if (axis.length === 0 || brand.length === 0 || axis.length === 0 || media.length === 0) return
    const filters = [
      {
        field: 'brand',
        operator: 'IN',
        values: brand,
      },
      {
        field: 'division',
        operator: 'IN',
        values: [division],
      },
      {
        field: 'category',
        operator: 'IN',
        values: axis,
      },
      {
        field: 'media',
        operator: 'IN',
        values: media,
      },
    ]
    return getIndustryProduct({
      groups: ['subcategory'],
      sorts: [
        {
          field: 'subcategory',
          type: 'ASC',
        },
      ],
      filters,
    }).then((res) => {
      setCagopt(res.map((i) => ({ label: i.subcategory, value: i.subcategory })))
      setTimeout(() => {
        let newCag = res.map((i) => i.subcategory)
        handleCagChange(newCag)
      })
    })
  }

  const getproductopt = (brand) => {
    onChange('product', [])
    if (brand.length === 0) return
    const ignoreCategory = curCag.length === cagopt.length || curCag.length === 0 //category全选或者不选
    const filters = [
      {
        field: 'brand',
        operator: 'IN',
        values: brand,
      },
      {
        field: 'division',
        operator: 'IN',
        values: [division],
      },
      {
        field: 'category',
        operator: 'IN',
        values: axis,
      },
      {
        field: 'media',
        operator: 'IN',
        values: media,
      },
    ]
    if (!ignoreCategory) {
      filters.push({
        field: 'subcategory',
        operator: 'IN',
        values: curCag,
      })
    }
    return getIndustryProduct({
      groups: ['product'],
      filters,
      sorts: [
        {
          field: 'product',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      setProductopt(res.map((i) => ({ label: i.product, value: i.product })))
      // 这里做下特殊处理因为种类太多了 默认全部选上
      if (defaultSelectAllProd) {
        onChange(
          'product',
          res.map((i) => i.product)
        )
      }
    })
  }
  const selectallCag = (e) => {
    if (e.target.checked) {
      handleCagChange(cagopt.map((i) => i.value))
    } else {
      handleCagChange([])
    }
  }

  const selectallprod = (e) => {
    if (e.target.checked) {
      onChange(
        'product',
        productopt.map((i) => i.value)
      )
    } else {
      onChange('product', [])
    }
  }
  // 初始化 brand列表
  useEffect(() => {
    getCategoryopt()
    // getbrandopt(['FA'])
  }, [media, brand, division, axis])

  useEffect(() => {
    getproductopt(brand)
    // getbrandopt(['FA'])
  }, [media, brand, division, axis])

  // const getAxisOpt = (division) => {
  //   onChange('axis', [])
  //   setTimeout(() => {
  //     handleAxisChange(axisData[division])
  //   })
  //   return axisData[division].map((i) => {
  //     return {
  //       value: i,
  //       label: i,
  //     }
  //   })
  // }
  const handleCagChange = (cag) => {
    onChange('category', cag) //通知父组件更改brand
    curCag = cag
    // getbrandopt()

    // getproductopt(brand)
  }

  const handleProdChange = (product) => {
    onChange('product', product)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          minHeight: '56px',
          flexWrap: 'wrap',
          padding: '4px 0',
        }}
      >
        SubCategory:
        <Select
          getPopupContainer={(n) => n.parentNode}
          allowClear
          showArrow
          maxTagCount={0}
          options={cagopt}
          mode="multiple"
          value={val.category}
          style={{ minWidth: '150px', marginLeft: '8px', marginRight: '8px' }}
          onChange={handleCagChange}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: '2px 0',
                }}
              />
              <Checkbox
                checked={val.category.length === cagopt.length || val.category.length === 0}
                onChange={selectallCag}
                style={{ marginLeft: '12px' }}
              >
                全选
              </Checkbox>
            </>
          )}
        ></Select>
        Product:
        <Select
          getPopupContainer={(n) => n.parentNode}
          allowClear
          showArrow
          maxTagCount={0}
          options={productopt}
          mode="multiple"
          value={val.product}
          style={{ minWidth: '150px', marginLeft: '8px' }}
          onChange={handleProdChange}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: '2px 0',
                }}
              />
              <Checkbox
                checked={val.product.length === productopt.length}
                onChange={selectallprod}
                style={{ marginLeft: '12px' }}
              >
                全选
              </Checkbox>
            </>
          )}
        ></Select>
      </div>
    </>
  )
}
export default connect((state) => ({
  yearsOpt: state.years.map((i) => ({ label: i, value: i })),
  currentYear: state.years[state.years.length - 1],
  preYear: state.years[state.years.length - 2],
}))(Filters)

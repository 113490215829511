import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { getBudgetSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { getTrendBarChartsOption_hor, barhorconfig } from './util'
import { Empty } from 'antd'

export default function TrendPieHor(props) {
  const { title, years, argsfn, setShowEmpty, unit } = props
  const [se, setSe] = useState(['Baidu']) //选择项
  const [empty, setEmpty] = useState(false)
  const trend = useRef(null)
  const { updateCharts } = useCharts(trend)
  useEffect(() => {
    const config = JSON.parse(JSON.stringify(barhorconfig))
    const index = config.filters.findIndex((i) => i.field === 'year')
    config.filters[index].values = years
    config.title = title
    getBudgetSum(config).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      updateCharts(getTrendBarChartsOption_hor(res, { years }, unit))
    })
  }, [years, se, unit])

  return (
    <div className="trend-wrap">
      <div className="title">
        <div>{title}</div>
      </div>
      {empty ? (
        <Empty style={{ marginTop: '16px' }}></Empty>
      ) : (
        <div ref={trend} style={{ width: '', height: '360px', position: 'relative' }}></div>
      )}
    </div>
  )
}

import { useEffect, useRef, useState } from 'react'
import * as echarts from 'echarts'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'

export const useCharts = (ref, config = {}) => {
  let [myChart, setMyChart] = useState(null)
  const [option, setOption] = useState({})
  const updateCharts = (newOpt) => {
    setOption(newOpt)
  }

  const map = {
    WFJ: 'wfj',
    FBP: 'fbp',
    FA: 'fa',
  }
  const monthMap = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  }
  const t = (name) => `/home/${map[name]}/${config.clickjump}`

  const navigate = useNavigate()
  const initChart = () => {
    const chart = echarts.init(ref.current)
    if (config.clickjump) {
      chart.on('click', ({ name }) => {
        if (name !== 'Corp') {
          navigate(t(name))
        } else {
          message.warning('该指标不能点击')
        }
      })
    }
    if (config.clickjump2) {
      chart.on('click', (params) => {
        let { name } = params
        console.log(params, '//////')
        //拆解name 获取年月
        const date = name.split('-')

        navigate(config.clickjump2, {
          state: {
            field: config.type,
            year: 2000 + Number(date[1]),
            month: monthMap[date[0]],
            channel: config.channel,
          },
        })
      })
    }

    if (config.changePieName) {
      // { name }
      chart.on('click', ({ name, seriesType, value }) => {
        config.changePieName(seriesType === 'scatter' ? value[value.length - 1] : name)
      })
    }
    if (config.setClickbrand) {
      chart.on('click', (args) => {
        if (/\-\d\d$/.test(args.name)) {
          //如果类似xxx-22这种用seriesName
          config.setClickbrand(args.seriesName)
        } else {
          config.setClickbrand(args.name)
        }
        // config.setClickbrand(args.name)
      })
    }
    if (config.setClickbrand2) {
      chart.on('click', (args) => {
        if (/\-\d\d$/.test(args.name)) {
          console.log(args)
          //如果类似xxx-22这种用seriesName
          config.setClickbrand2(args.event.target.selected ? args.seriesName : undefined)
        } else {
          config.setClickbrand2(args.event.target.selected ? args.name : undefined)
        }
        // config.setClickbrand(args.name)
      })
    }
    if (config.legendselectchanged) {
      chart.on('legendselectchanged', ({ selected }) => {
        let temp = []
        for (var i in selected) {
          if (selected[i]) {
            temp.push(i)
          }
        }
        config.legendselectchanged(temp)
      })
    }
    setMyChart(chart)
  }
  // 这里由于antd的layout-content宽度是js控制的，导致刚渲染宽度是100%，然后js控制减去侧边栏80px
  // 这就造成了一个bug，如果侧边栏已经出来了，跳转路由的时候，content此时宽度是对的（100vw-侧边栏），但是再刷新，宽度会变成全部100vw
  // 做个延迟去获取js执行后的content就对了
  useEffect(() => {
    // debugger
    if (myChart) {
      myChart.setOption(option, true)
      myChart.resize()
    } else {
      setTimeout(initChart, 50)
    }
  }, [option, config.clickjump, config.clickjump2])

  window.onresize = function () {
    // 重新设置图表的宽度和高度
    myChart.resize()
  }
  return {
    updateCharts,
  }
}

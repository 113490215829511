import React, { ReactElement, useEffect, useRef, useState, require } from 'react'
import { getPerformanceSum, getPerformanceSumx } from '@/api/home'
import { Table, Radio, Button } from 'antd'
import { addtablekey } from './util'
import { formatNum } from '@/common/util'
import { divisionOption } from '@/common/vars'

import ExportJsonExcel from 'js-export-excel'

import { VerticalAlignBottomOutlined, QuestionCircleOutlined } from '@ant-design/icons'
export default function TreeTable(props) {
  const {
    unit,
    division,
    years,
    get,
    category,
    months,
    productLine,
    campaignname,
    mediaformat,
    media,
    asset,
    channel,
    scattersGroup,
    // mediaGroup,
  } = props
  const [data, setData] = useState([])
  const formatTableNum = (num) => {
    if (!num) return 0
    return numberWithCommas(num)
  }
  const numberWithCommas = (x) => {
    let y = x.toString().split('.')

    return y[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (y.length > 1 ? '.' + y[1] : '')
  }
  const special1 = [
    'ctr',
    'er_wechat',
    'er_post',
    'cvr_wechat',
    'cvr_search',
    'webaddtocart_click',
    't0ordershare',
  ] //保留两位小数带百分比
  const special2 = [
    'cpm',
    'cpc',
    'roi',
    't0ysrevenuema',
    't7ysrevenuema',
    't15ysrevenuema',
    'cpe_post',
  ] //保留两位小数
  useEffect(() => {
    if (
      group === 'skuname' &&
      !(scattersGroup !== 'assets' && channel.length == 1 && channel[0] == 'WeChat Moments RTB')
    ) {
      setGroup('assets')
    }
  }, [channel, scattersGroup])
  const exportToExcel = () => {
    let c = columns()

    let sheetFilter = c.map((i) => {
      return i.dataIndex
    })
    let option = {}
    option.fileName =
      (group === 'target1' ? 'TA' : group === 'assets' ? 'Asset' : 'SKU') +
      '(Core/Retargeting/Potential)'
    option.datas = [
      {
        sheetData: data,
        sheetName: '-', //'unit(' + unit + ')',
        sheetFilter: sheetFilter,
        sheetHeader: c.map((i) => {
          return i.title
        }),
        columnWidths: c.map((i) => {
          return i.width / 10
        }),
      },
    ]
    var toExcel = new ExportJsonExcel(option) //new
    toExcel.saveExcel() //保存
  }
  const specialNumber = (fieldName, v, unit) => {
    let v_ = v

    if (special1.includes(fieldName)) return Number(v_) ? v_.toFixed(2) + '%' : '-'
    else if (special2.includes(fieldName)) return Number(v_) ? v_.toFixed(2) : '-'
    else return unit ? numberWithCommas(formatNum(v_, unit, 0)) : numberWithCommas(v)
  }
  const columnFieldList = {
    headList: {
      target1: [{ title: 'TA定向', dataIndex: 'target1', key: 'target1', width: 200 }],
      skuname: [
        { title: 'SKU Name', dataIndex: 'skuname', key: 'skuname', width: 150 },
        {
          title: 'T+0 Order',
          dataIndex: 't0order',
          key: 't0order',
          align: 'right',
          width: 150,
          sorter: (a, b, order) => {
            if (a.key === -1 || b.key === -1) {
              return order === 'ascend' ? 99999999 : -99999999
            } else return a.t0order - b.t0order
          },
          render(val) {
            return formatTableNum(formatNum(val, unit, 0))
          },
        },
        {
          title: 'T+0 Revenue',
          dataIndex: 't0revenue',
          key: 't0revenue',
          align: 'right',
          width: 150,
          sorter: (a, b, order) => {
            if (a.key === -1 || b.key === -1) {
              return order === 'ascend' ? 99999999 : -99999999
            } else return a.t0revenue - b.t0revenue
          },
          render(val) {
            return formatTableNum(formatNum(val, unit, 0))
          },
        },
        {
          title: 'T+0 Order Share',
          dataIndex: 't0ordershare',
          key: 't0ordershare',
          align: 'right',
          width: 150,
          sorter: (a, b, order) => {
            if (a.key === -1 || b.key === -1) {
              return order === 'ascend' ? 99999999 : -99999999
            } else return a.t0ordershare - b.t0ordershare
          },
          render(val, row) {
            if (row.key === -1) return '100%'
            return specialNumber('t0ordershare', val * 100)
          },
        },
      ],
      assets: [
        { title: 'Asset', dataIndex: 'assets', key: 'assets', width: 100 },
        {
          title: 'Previewurl',
          dataIndex: 'previewurl',
          width: 100,
          key: 'previewurl',
          render(val) {
            // let fun =fu((val)=>{

            // })
            return val ? (
              <a target="_blank" style={{ color: 'blue' }} href={val} rel="noreferrer">
                预览
              </a>
            ) : (
              '--'
            )
            // <span onClick={fun(val)}>预览</span>
          },
        },
      ],
    },
    otherList: [
      {
        title: 'Impression',
        dataIndex: 'impression',
        key: 'impression',
        tag: 'all|Red Feeds',
        align: 'right',
        width: 120,
        sorter: (a, b, order) => {
          if (a.key === -1 || b.key === -1) {
            return order === 'ascend' ? 99999999 : -99999999
          } else return a.impression - b.impression
        },
        render(val) {
          return specialNumber('impression', formatNum(val, unit, 0))
        },
      },
      {
        title: 'Click',
        dataIndex: 'click',
        align: 'right',
        key: 'click',
        tag: 'all|Red Feeds',
        width: 120,
        sorter: (a, b, order) => {
          if (a.key === -1 || b.key === -1) {
            return order === 'ascend' ? 99999999 : -99999999
          } else return a.click - b.click
        },
        render(val) {
          return formatTableNum(formatNum(val, unit, 0))
        },
      },
      {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
        align: 'right',
        tag: 'all|WeChat Moments RTB',
        width: 120,
        sorter: (a, b, order) => {
          if (a.key === -1 || b.key === -1) {
            return order === 'ascend' ? 99999999 : -99999999
          } else return a.cost - b.cost
        },
        render(val) {
          return specialNumber('cost', formatNum(val, unit, 0))
        },
      },
      {
        title: 'CTR',
        dataIndex: 'ctr',
        key: 'ctr',
        align: 'right',
        tag: 'all|WeChat Moments RTB|Red Feeds',
        width: 120,
        sorter: (a, b, order) => {
          if (a.key === -1 || b.key === -1) {
            return order === 'ascend' ? 99999999 : -99999999
          } else return (parseFloat(a.ctr) || 0) - (parseFloat(b.ctr) || 0)
        },
        render(val) {
          return specialNumber('ctr', val)
        },
      },
      {
        title: 'Order ROI',
        dataIndex: 'roi',
        key: 'roi',
        align: 'right',
        tag: 'all|WeChat Moments RTB',
        width: 150,
        sorter: (a, b, order) => {
          if (a.key === -1 || b.key === -1) {
            return order === 'ascend' ? 99999999 : -99999999
          } else return (parseFloat(a.roi) || 0) - (parseFloat(b.roi) || 0)
        },
        render(val) {
          return specialNumber('roi', val)
        },
      },
      {
        title: 'ATC%',
        dataIndex: 'webaddtocart_click',
        key: 'webaddtocart_click',
        align: 'right',
        tag: 'WeChat Moments RTB',
        width: 120,
        sorter: (a, b, order) => {
          if (a.key === -1 || b.key === -1) {
            return order === 'ascend' ? 99999999 : -99999999
          } else return a.webaddtocart_click - b.webaddtocart_click
        },
        render(val) {
          return specialNumber('webaddtocart_click', val)
        },
      },
      {
        title: 'ER-POST',
        dataIndex: 'er_post',
        key: 'er_post',
        tag: 'Red Feeds',
        align: 'right',
        width: 120,
        sorter: (a, b, order) => {
          if (a.key === -1 || b.key === -1) {
            return order === 'ascend' ? 99999999 : -99999999
          } else return a.er_post - b.er_post
        },
        render(val) {
          return specialNumber('er_post', val)
        },
      },
    ],
  }
  const [group, setGroup] = useState('assets')
  const onChange2 = ({ target: { value } }) => {
    setGroup(value)
  }
  const radioOption = [
    {
      label: 'Asset',
      value: 'assets',
    },
    {
      label: 'TA(Target1)',
      value: 'target1',
    },
    {
      label: 'SKU',
      value: 'skuname',
    },
  ]

  const columns = () => {
    let m = channel
    let columnsList = []
    if (group !== 'skuname') {
      columnsList = columnFieldList.headList[group].concat(
        columnFieldList.otherList.filter((i) => {
          if (m.length === 1 && ['WeChat Moments RTB', 'Red Feeds'].includes(m[0]))
            return i.tag.indexOf(m) >= 0
          else return i.tag.indexOf('all') >= 0
        })
      )
    } else {
      columnsList = columnFieldList.headList[group]
    }
    return columnsList
  }
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const handleData = (res) => {
    let row = { key: -1 }
    let res_ = res
    let special3 = ['skuname', 'target1', 'assets']
    for (let i = 0; i < res_.length; i++) {
      for (let keyname in res[i]) {
        row[keyname] = row[keyname] ? row[keyname] : 0
        if (special1.includes(keyname) || special2.includes(keyname)) row[keyname] = '--'
        else if (special3.includes(keyname)) row[keyname] = '汇总'
        else row[keyname] += Number(res_[i][keyname]) || 0
      }
      res_[i]['key'] = i
    }

    if (row['ctr']) {
      row['ctr'] = (Number(row['click']) * 100) / Number(row['impression'])
    }
    if (row['er_post']) {
      row['er_post'] = (Number(row['engagementpost']) * 100) / Number(row['click'])
    }
    if (row['roi']) {
      row['roi'] = Number(row['revenue']) / Number(row['cost'])
    }
    if (row['webaddtocart_click']) {
      row['webaddtocart_click'] = (Number(row['webaddtocart']) * 100) / Number(row['click'])
    }
    if (row['t0ordershare']) {
      row['t0ordershare'] = 1 //Number(row['t0order']) /// Number(row['t0ordershare'])
    }
    console.log(row)
    res_.unshift(row)
    return res_
  }
  useEffect(() => {
    let filters = [
      {
        field: 'mediachannel',
        operator: 'NOT_IN',
        values: ['Paid Search'],
      },
      {
        field: group,
        operator: 'NOT_IN',
        values: ['', ' ', '-'],
      },
    ]
    if (!years.includes('all'))
      filters.push({
        field: 'year',
        operator: 'IN',
        values: years,
      })
    if (!months.includes('all'))
      filters.push({
        field: 'month',
        operator: 'IN',
        values: months.map((i) => map.indexOf(i)),
      })
    if (!mediaformat.includes('all'))
      filters.push({
        field: 'mediaformat',
        operator: 'IN',
        values: mediaformat,
      })
    if (!channel.includes('all'))
      filters.push({
        field: 'mediachannel',
        operator: 'IN',
        values: channel,
      })
    // if (!mediaGroup.includes('all'))
    //   filters.push({
    //     field: 'mediagroup',
    //     operator: 'IN',
    //     values: mediaGroup,
    //   })
    if (!media.includes('all'))
      filters.push({
        field: 'media',
        operator: 'IN',
        values: media,
      })
    if (!productLine.includes('all'))
      filters.push({
        field: 'productline',
        operator: 'IN',
        values: productLine,
      })
    if (!campaignname.includes('all'))
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: campaignname,
      })
    if (!division.includes('all'))
      filters.push({
        field: 'divisioncorp',
        operator: 'IN',
        values: division,
      })
    if (!category.includes('all'))
      filters.push({
        field: 'categorycorp',
        operator: 'IN',
        values: category,
      })
    if (!asset.includes('all'))
      filters.push({
        field: 'assets',
        operator: 'IN',
        values: asset,
      })
    let f = columns().map((i) => {
      return i.dataIndex
    })
    let fun = group === 'skuname' ? getPerformanceSumx : getPerformanceSum
    let bc_f = ['click', 'impression']

    if (channel.length === 1 && channel[0] == 'Red Feeds') {
      bc_f.push('engagementpost')
    }
    if (channel.length === 1 && channel[0] == 'WeChat Moments RTB') {
      bc_f = bc_f.concat(['revenue', 'webaddtocart'])
    }
    console.log(channel[0], group, bc_f, channel.length === 1 && channel[0] == 'WeChat Moments RTB')
    fun({
      fields: f.concat(group !== 'skuname' ? bc_f : []),
      groups: group == 'assets' ? [group, 'previewurl'] : [group],
      filters,
      sorts: [
        {
          field: group,
          type: 'ASC',
        },
      ],
    }).then((res) => {
      let data2 = handleData(res)

      setData(data2)
    })
  }, [years, months, get, group, unit])

  return (
    <div className="trend-wrap">
      <div className="title">
        {(group === 'target1' ? 'TA' : group === 'assets' ? 'Asset' : 'SKU') +
          '(Core/Retargeting/Potential)'}
      </div>
      <div className="tool">
        <div style={{ width: '270px' }}>
          <Radio.Group
            options={
              scattersGroup !== 'assets' &&
              channel.length == 1 &&
              channel[0] == 'WeChat Moments RTB'
                ? radioOption
                : radioOption.filter((it) => {
                    return it.value != 'skuname'
                  })
            }
            onChange={onChange2}
            value={group}
            size="small"
            optionType="button"
          />
        </div>
        <div>
          <Button type="text" onClick={exportToExcel} className="downBtn">
            <VerticalAlignBottomOutlined style={{ fontSize: '18px' }} />
          </Button>
        </div>
      </div>
      <Table columns={columns()} dataSource={data} scroll={{ y: 400 }} pagination={false} />
    </div>
  )
}

import { Radio, Button } from 'antd'
import styles from './index.module.less'
import { message, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { upload } from '@/api/home'
import { useState } from 'react'

const { Dragger } = Upload
const Load = () => {
  const { wrap } = styles
  const onChange = () => {}
  const [oper, setOper] = useState(1)
  const handleBeforeUpload = (file) => {
    let index = file.name.lastIndexOf('.')
    let arr = ['xlsx']
    let ext = file.name.substr(index + 1)
    if (!arr.includes(ext)) {
      message.error(`${file.name} 上传失败：文件格式错误,请重新上传`)
      return Upload.LIST_IGNORE
    }
    return true
  }
  const props = {
    name: 'file',
    multiple: true,
    action: '',
    beforeUpload: handleBeforeUpload,
    customRequest({ file, onProgress, onSuccess, onError }) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('oper', oper)
      upload(formData, { setProgress: onProgress })
        .then((response) => {
          onSuccess(response, file)
          message.success(`${file.name} 上传成功.`)
        })
        .catch(onError)
    },
    // onSuccess() {},
    // progress: {
    //     strokeColor: {
    //       '0%': '#108ee9',
    //       '100%': '#87d068',
    //     },
    //     strokeWidth: 3,
    //     format: (percent) => percent && `${parseFloat(percent.toFixed(1))}%`,
    //   },
    // onChange(info) {
    //   const { status } = info.file

    //   if (status !== 'uploading') {
    //     console.log(info.file, info.fileList)
    //   }

    //   if (status === 'done') {
    //     message.success(`${info.file.name} file uploaded successfully.`)
    //   } else if (status === 'error') {
    //     message.error(`${info.file.name} file upload failed.`)
    //   }
    // },
  }
  return (
    <div className={wrap}>
      <div className="title">录入方式</div>
      <div className="type">
        <Radio.Group
          onChange={(e) => {
            console.log(e.target.value)
            setOper(e.target.value)
          }}
          defaultValue={oper}
          className="type"
        >
          <Radio.Button value={1}>add</Radio.Button>
          <Radio.Button value={2}>edit</Radio.Button>
        </Radio.Group>
        <p
          style={{
            fontSize: '10px',
            fontWeight: '400',
            marginTop: '8px',
            color: 'rgba(0,0,0,.45)',
          }}
        >
          新增数据: 仅添加缺少数据, 已有数据不覆盖. 覆盖数据: 将数据库数据更新与上传文件相同
        </p>
      </div>
      <div className="uploadwrap">
        <div className="des">File: </div>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">supported formats include .xlsx</p>
          {/* <p className="ant-upload-hint">支持扩展名: .xlsx</p> */}
        </Dragger>
      </div>
    </div>
  )
}
export default Load

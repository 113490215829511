import Card from '../../components/Card'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styles from './index.module.less'
import { Radio, Select, Tabs, Checkbox, Table } from 'antd'
import CrossFilter from '@/components/CrossFilter3'
import { getSum1, getSum1_2 } from '@/api/home'
import Crossbar2 from './Crossbar2'
import Crossbar3 from './Crossbar3'
import TrendPie from './trend-pie'
import TrendPie2 from './trend-pie2'
import TrendDivisionAndYear from './trendDivisionAndYear'
import TrendDivisionAndYear1 from './trendDivisionAndYear1'
import TrendDivisionAndYear2 from './trendDivisionAndYear2'
import Scatters from './scatters'
import CrossPerformance from '@/view/cross/performance'
import { themecolor, themecolor_pie1 } from '@/common/vars'
import { setDivision } from '@/redux/actions'
import MomentTable from './MomentTable'
const { wrap } = styles
const cardstyle = {
  // marginTop: '16px',
}
const linestyle = {}
const Budget = (props) => {
  const onChange = () => {}
  const { currentYear, division, setDivision, unit, yearsopt } = props
  const [checkProductLine, setLegendProductLine] = useState([])
  const [months, setmonths] = useState([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ])
  const map = [
    '',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const [years, setyears] = useState([currentYear])
  const [ysval, setysval] = useState(0)
  // const [division, setDivision] = useState('all')
  const [product, setProduct] = useState(['all'])
  const [productOpt, setProductOpt] = useState([])

  const [cag, setCag] = useState(['all'])
  const [cagopt, setCagOpt] = useState([])

  const [ta, setTa] = useState(['all'])
  const [taOpt, setTaOpt] = useState([])
  const [ta2, setTa2] = useState(['all'])
  const [ta2Opt, setTa2Opt] = useState([])

  const [axis, setAxis] = useState(['all'])
  const opt = {
    FBP: ['FR', 'SK', 'MU'],
    FA: ['FA'],
    WFJ: ['WAT', 'FJ'],
    CORPORATE: ['Corp'],
  }
  const [axisOpt, setAxisOpt] = useState([])
  useEffect(() => {
    if (division.includes('all')) {
      setAxisOpt([
        { value: 'all', label: '全部' },
        ...Object.values(opt)
          .flat()
          .map((i) => ({ value: i, label: i })),
      ])
    } else {
      let newopt = {}
      for (let i = 0; i < division.length; i++) {
        let item = division[i]
        newopt[item] = opt[item]
      }
      setAxisOpt([
        { value: 'all', label: '全部' },
        ...Object.values(newopt)
          .flat()
          .map((i) => ({ value: i, label: i })),
      ])
    }
  }, [division])
  const [cost, setCost] = useState(0)
  const [updatetime, setupdatetime] = useState('')
  const getUpdateTime = () => {
    getSum1_2({
      title: 'updateTime',
      fields: ['maxyearandmonth'],
      groups: ['year'],
      sorts: [{ field: 'maxyearandmonth', type: 'DESC' }],
    }).then((res) => {
      setupdatetime(res[0].maxyearandmonth)
    })
  }
  const handleChange = (val, setfn) => {
    //全部交互需要编写受控逻辑才能完成  全部与其他互斥
    if (!val.length) return setfn(['all'])
    const index = val.indexOf('all')
    if (index === 0 && val.length > 0) return setfn(val.slice(1)) //由默认转为其他
    if (index === val.length - 1 && val.length > 0) return setfn(['all']) //由其他转为默认
    return setfn(val)
  }
  const getopt = (name, setfn) => {
    getSum1({
      groups: [name],
    }).then((res) => {
      setfn([
        {
          label: '全部',
          value: 'all',
        },
        ...res
          .filter((i) => {
            return i[name] !== '' && i[name] !== '-'
          })
          .map((i) => ({ label: i[name], value: i[name] })),
      ])
    })
  }
  const resetting = (val) => {
    setyears([currentYear])
    setmonths(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'])
    setAxis(['all'])
    setProduct(['all'])
  }
  useEffect(() => {
    if (!axisOpt.length) return
    if (!productOpt.length) return
    const filters = [
      {
        field: 'year',
        operator: 'IN',
        values: years.includes('all') ? yearsopt : years,
      },
      {
        field: 'month',
        operator: 'IN',
        values: months.includes('all')
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
          : months.map((i) => map.indexOf(i)),
      },
      {
        field: 'productline',
        operator: 'IN',
        values: product.includes('all') ? productOpt.slice(1).map((i) => i.value) : product,
      },
      {
        field: 'categorycorp',
        operator: 'IN',
        values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
      },
      {
        field: 'mediagroup',
        operator: 'IN',
        values: ['Tencent'],
      },

      {
        field: 'mediaformat',
        operator: 'IN',
        values: ['Moments RTB'],
      },
    ]
    if (cag) {
      filters.push({
        field: 'campaignname',
        operator: 'IN',
        values: cag.includes('all') ? cagopt.slice(1).map((i) => i.value) : cag,
      })
    }
    getSum1({
      fields: ['cost'],
      groups: ['target1'],
      filters: filters.concat([
        {
          field: 'adq',
          operator: 'IN',
          values: ['前端'],
        },
      ]),
    }).then((res) => {
      setTaOpt &&
        setTaOpt([
          { label: '全部', value: 'all' },
          ...res
            .filter((i) => {
              return i.target1 !== '' && i.target1 !== '-'
            })
            .map((i) => ({ value: i.target1, label: i.target1 })),
        ])
    })
    getSum1({
      fields: ['cost'],
      groups: ['ta2'],
      filters,
    }).then((res) => {
      setTa2Opt &&
        setTa2Opt([
          { label: '全部', value: 'all' },
          ...res
            .filter((i) => {
              return i.ta2 !== '' && i.ta2 !== '-'
            })
            .map((i) => ({ value: i.ta2, label: i.ta2 })),
        ])
    })
  }, [years, months, division, axis, axisOpt, product, productOpt, cag, cagopt, unit])

  const getOverviewCost = () => {
    if (!axisOpt.length) return
    if (!productOpt.length) return
    getSum1({
      fields: ['cost'],
      title: '获取右上角cost',
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years.includes('all') ? yearsopt : years,
        },
        {
          field: 'month',
          operator: 'IN',
          values: months.includes('all')
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            : months.map((i) => map.indexOf(i)),
        },
        {
          field: 'productline',
          operator: 'IN',
          values: product.includes('all') ? productOpt.slice(1).map((i) => i.value) : product,
        },
        {
          field: 'categorycorp',
          operator: 'IN',
          values: axis.includes('all') ? axisOpt.slice(1).map((i) => i.value) : axis,
        },

        {
          field: 'mediagroup',
          operator: 'IN',
          values: ['Tencent'],
        },

        {
          field: 'mediaformat',
          operator: 'IN',
          values: ['Moments RTB'],
        },
      ],
    }).then((res) => {
      // debugger
      const cost = res[0]?.cost
      setCost(cost)
      //  setUnit(cost >= 100000 ? 'M' : 'K')
    })
  }
  useEffect(() => {
    // getopt('axis', setAxisOpt)
    getopt('productline', setProductOpt)
    getopt('campaignname', setCagOpt)
    getUpdateTime()
  }, [])
  useEffect(() => {
    getOverviewCost()
  }, [axisOpt, productOpt, axis, product, years, months])

  return (
    <Tabs defaultActiveKey="1" style={{ backgroundColor: '#fff', padding: '0px 12px' }}>
      <Tabs.TabPane tab="view" key="1">
        <div className={wrap}>
          <CrossFilter
            style={{ backgroundColor: 'red', position: 'absolute' }}
            updatetime={updatetime}
            resetting={resetting}
            cost={cost}
            division={division}
            setDivision={setDivision}
            months={months}
            setmonths={setmonths}
            setyears={setyears}
            years={years}
            amountname="Cost"
            // setProductLine={setProductLine}
          >
            <div className="toolLine">
              <span className="label">Axis：</span>
              <Select
                maxTagCount={1}
                options={axisOpt}
                value={axis}
                onChange={(val) => handleChange(val, setAxis)}
                style={{ width: '240px' }}
                mode={'multiple'}
              />
            </div>
            <div className="toolLine">
              <span className="label">Product Line：</span>
              <Select
                maxTagCount={1}
                options={productOpt}
                value={product}
                onChange={(val) => handleChange(val, setProduct)}
                style={{ width: '240px' }}
                mode={'multiple'}
              />
            </div>
            <div className="momentCostByAxis">
              <TrendPie2
                yname="categorycorp"
                unit={unit}
                title=""
                yearsopt={yearsopt}
                division={division}
                years={years}
                months={months}
                axis={axis}
                axisOpt={axisOpt}
                product={product}
                productOpt={productOpt}
                dataViewHeaderFirstTitle="Axis"
                color={themecolor_pie1}
              ></TrendPie2>
            </div>
          </CrossFilter>
          <Card title="Cost" style={cardstyle} headerLine={true}>
            <div className="line" style={linestyle}>
              {/* <div className="line-1-left">
            <TrendPie
              yname="axis"
              unit={unit}
              title="Cost By Axis"
              division={division}
              years={years}
              months={months}
              axis={axis}
              axisOpt={axisOpt}
              product={product}
              productOpt={productOpt}
              dataViewHeaderFirstTitle="Axis"
              color={themecolor_pie1}       
            ></TrendPie>
          </div> */}
              <div className="line-1-right">
                <Crossbar3
                  title="Cost By Campaign"
                  unit={unit}
                  xname="campaignname"
                  yname="cost"
                  dataViewHeaderFirstTitle="Campaign-Name"
                  division={division}
                  yearsopt={yearsopt}
                  years={years}
                  months={months}
                  axis={axis}
                  axisOpt={axisOpt}
                  product={product}
                  productOpt={productOpt}
                  style={{ width: '100%' }}
                  color={['#0f4e77']}
                ></Crossbar3>
                {/* </Card> */}
              </div>
            </div>
            <div className="line" style={linestyle}>
              <div className="line-1-left">
                {/* <Card title="Cost By Month & productling" style={cardstyle}> */}
                <TrendDivisionAndYear
                  unit={unit}
                  title="Cost By Month & Productline"
                  dataViewHeaderFirstTitle="Month-Year"
                  division={division}
                  yearsopt={yearsopt}
                  years={years}
                  months={months}
                  axis={axis}
                  axisOpt={axisOpt}
                  product={product}
                  productOpt={productOpt}
                ></TrendDivisionAndYear>
                {/* </Card> */}
              </div>
              <div className="line-1-right">
                <TrendDivisionAndYear
                  unit={unit}
                  title="Cost share By Month & Productline"
                  dataViewHeaderFirstTitle="Month-Year"
                  formatpercent={true}
                  division={division}
                  yearsopt={yearsopt}
                  years={years}
                  months={months}
                  axis={axis}
                  axisOpt={axisOpt}
                  product={product}
                  productOpt={productOpt}
                ></TrendDivisionAndYear>
              </div>
            </div>
          </Card>
          <Card title="Performance" style={cardstyle} headerLine={true}>
            <Scatters
              mafield={['roi', 'atc']}
              formatpercent={true}
              title={
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <div>Order ROI ATC% &Cost</div>
                  <div
                    className="line"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span className="label">Campaign Name：</span>
                    <Select
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      maxTagCount={1}
                      options={cagopt}
                      value={cag}
                      onChange={(val) => handleChange(val, setCag)}
                      style={{ width: '320px' }}
                      mode={'multiple'}
                    />
                  </div>
                </div>
              }
              unit={unit}
              division={division}
              yearsopt={yearsopt}
              years={years}
              months={months}
              axis={axis}
              axisOpt={axisOpt}
              product={product}
              productOpt={productOpt}
              cag={cag}
              cagopt={cagopt}
            ></Scatters>

            <div className="line" style={linestyle}>
              <div className="line-1-left">
                <TrendDivisionAndYear
                  fields="traffic"
                  title="Traffic By Month & Productline"
                  unit={unit}
                  dataViewHeaderFirstTitle="Month-Year"
                  division={division}
                  yearsopt={yearsopt}
                  years={years}
                  months={months}
                  axis={axis}
                  axisOpt={axisOpt}
                  product={product}
                  productOpt={productOpt}
                ></TrendDivisionAndYear>
              </div>
              <div className="line-1-right">
                {/* <Card title="Cost per Traffic" style={cardstyle}>
             <div style={{width: '100%',backgroundColor: 'lightblue'}}>213</div>
              </Card> */}
                {/* <TrendDivisionAndYear1
              mafield={['cost', 'traffic']}
              formatdatafn={(i) => {
                if (!i.traffic) {
                  i.p = 0
                } else {
                  i.p = parseFloat((i.cost / i.traffic).toFixed(2))
                }
              }}
              unit={unit}
              fields="cost,traffic"
              title="Cost per Traffic"
              dataViewHeaderFirstTitle="Month-Year"
              division={division}
              years={years}
              months={months}
              axis={axis}
              axisOpt={axisOpt}
              product={product}
              productOpt={productOpt}
            ></TrendDivisionAndYear1> */}
                <TrendDivisionAndYear
                  fields="revenue"
                  unit={unit}
                  title="Order Revenue By Month & Productline"
                  dataViewHeaderFirstTitle="Month-Year"
                  // formatdatafn={i=>i.p = (i.cost/i.traffic)}
                  division={division}
                  yearsopt={yearsopt}
                  years={years}
                  months={months}
                  axis={axis}
                  axisOpt={axisOpt}
                  product={product}
                  productOpt={productOpt}
                ></TrendDivisionAndYear>
              </div>
            </div>

            <div className="line" style={linestyle}>
              {/*
           <div className="line-1-left">
             <Card title=" Order Revenue By Productline" style={cardstyle}>
             <div style={{width: '100%',backgroundColor: 'lightyellow'}}>213</div>
              </Card> 
            <Crossbar2
              yname="cost"
              xname="target1"
              hor={true}
              unit={'K'}
              title={
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <div>Cost by Ta</div>
                  <div
                    className="line"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span className="label">Ta：</span>
                    <Select
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      maxTagCount={5}
                      options={taOpt}
                      value={ta}
                      onChange={(val) => handleChange(val, setTa)}
                      style={{ width: '250px' }}
                      mode={'multiple'}
                    />
                  </div>
                </div>
              }
              dataViewHeaderFirstTitle="TA"
             
              division={division}
              years={years}
              months={months}
              axis={axis}
              axisOpt={axisOpt}
              product={product}
              productOpt={productOpt}
              barwidth={10}
              color={themecolor_pie1}
              setTaOpt={setTaOpt}
              ta={ta}
              scroll={true}
              cag={cag}
              cagopt={cagopt}
            ></Crossbar2>
          </div>
           */}
              <div className="line-1-right">
                <div>
                  <div
                    className="line"
                    style={{
                      display: 'flex',
                      padding: '0px 12px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className="title">TA(Core/Retargeting/Potential)</div>
                    <div>
                      <span className="label"> TA（Core/Retargeting/Potential)：</span>
                      <Select
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        maxTagCount={1}
                        options={ta2Opt}
                        value={ta2}
                        onChange={(val) => handleChange(val, setTa2)}
                        style={{ width: '150px' }}
                        mode={'multiple'}
                      />

                      <span className="label">TA：</span>
                      <Select
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        maxTagCount={1}
                        options={taOpt}
                        value={ta}
                        onChange={(val) => handleChange(val, setTa)}
                        style={{ width: '150px' }}
                        mode={'multiple'}
                      />
                    </div>
                  </div>
                </div>
                <MomentTable
                  ta={ta}
                  ta2={ta2}
                  division={division}
                  yearsopt={yearsopt}
                  years={years}
                  months={months}
                  axis={axis}
                  axisOpt={axisOpt}
                  product={product}
                  productOpt={productOpt}
                  cag={cag}
                  unit={unit}
                  cagopt={cagopt}
                ></MomentTable>
              </div>
            </div>
          </Card>
          <Card
            title="Youshu"
            style={cardstyle}
            headerLine={true}
            headerFilter={
              <Radio.Group value={ysval} onChange={(e) => setysval(e.target.value)}>
                <Radio.Button value={0}>YS T+0</Radio.Button>
                <Radio.Button value={7}>YS T+7</Radio.Button>
                <Radio.Button value={15}>YS T+15</Radio.Button>
              </Radio.Group>
            }
          >
            <div className="line" style={linestyle}>
              <div className="line-1-left">
                <TrendDivisionAndYear
                  fields={`t${ysval}ysrevenue`}
                  unit={unit}
                  title={`YS T+${ysval} Revenue By Month & Productline`}
                  dataViewHeaderFirstTitle="Month-Year"
                  // formatdatafn={i=>i.p = (i.cost/i.traffic)}
                  division={division}
                  yearsopt={yearsopt}
                  years={years}
                  months={months}
                  ysval={ysval}
                  axis={axis}
                  axisOpt={axisOpt}
                  product={product}
                  setLegendProductLine={setLegendProductLine}
                  productOpt={productOpt}
                ></TrendDivisionAndYear>
              </div>
              <div className="line-1-right">
                <TrendDivisionAndYear2
                  // formatpercent={true}
                  mafield={[`t${ysval}ysrevenuema`]}
                  ysval={ysval}
                  yearsopt={yearsopt}
                  fields={`t${ysval}ysrevenue,cost`}
                  title={`YS T+${ysval} ROI By Month & Productline  ${checkProductLine}`}
                  dataViewHeaderFirstTitle="Productline"
                  // dataViewHeaderFirstTitle="Media Group"
                  formatdatafn={(i) =>
                    (i.p = i.cost ? (i[`t${ysval}ysrevenue`] / i.cost).toFixed(2) : 0)
                  }
                  division={division}
                  years={years}
                  months={months}
                  axis={axis}
                  axisOpt={axisOpt}
                  product={product}
                  checkProductLine={checkProductLine}
                  productOpt={productOpt}
                ></TrendDivisionAndYear2>
              </div>
            </div>
          </Card>
        </div>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Overview" key="2">
        <CrossPerformance mark={'momentsRTBWechat'} headertitle={'Overview RTB'} />
      </Tabs.TabPane>
    </Tabs>
  )
}
export default connect(
  (state) => ({
    yearsopt: state.years.slice().reverse(),
    currentYear: state.years[state.years.length - 1],
    division: state.division,
    unit: state.unit,
    // preYear: state.years[state.years.length - 2],
  }),
  { setDivision }
)(Budget)

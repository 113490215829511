import React, { useEffect, useState } from 'react'

import { Table, Switch } from 'antd'
//import { columns } from '@/common/vars'
import { getMyColumns, preview, formatNum, specialNumber } from '@/common/util'
import { set } from 'lodash-es'
export default function TreeTable(props) {
  const { fields, dates, data, setSorter, unit } = props
  const [scrollX, setScrollX] = useState(100)
  const [myColumns, setMyColumns] = useState([])
  const [myData, setMydata] = useState([])
  const [mySunData, setMySunData] = useState({})

  useEffect(() => {
    let newColumns = []
    let columns = getMyColumns(unit)
    fields.forEach((element) => {
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].key === element.value) {
          newColumns.push(columns[i])
          if (element.value === 'assets') {
            newColumns.push(preview)
          }

          break
        }
      }
    })
    setMyColumns(newColumns)
  }, [fields, unit])
  useEffect(() => {
    setScrollX(
      myColumns.reduce((pre, cur) => {
        pre = pre + (cur.width || 150)
        return pre
      }, 0)
    )
  }, [myColumns])

  useEffect(() => {
    if (!data) return
    setMydata(data.list || [])
    setMySunData(data.sum || {})
  }, [data])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const downLoadTable = () => {}
  const onChange = (page, pageSize) => {
    setPage(page)
    setPageSize(pageSize)
  }
  const onTableChange = (pagination, filters, sorter, extra) => {
    setSorter({
      field: sorter.field,
      type: sorter && sorter.order ? sorter.order.replace('end', '').toUpperCase() : undefined,
    })
  }

  const handleSumData = (val, fieldName) => {
    if (val) {
      return specialNumber(fieldName, val, unit)
    } else return '--'
  }

  // useEffect(() => {
  // if (!campaignnameOpt.length) return
  // getTablePerformanceSum({
  //   filters: [
  //     { field: 'year', operator: 'IN', values: years },
  //     // { field: 'month', operator: 'IN', values: months.map((i) => map.indexOf(i)) },
  //     {
  //       field: 'campaignname',
  //       operator: 'IN',
  //       values: campaignname.includes('all')
  //         ? campaignnameOpt.slice(1).map((i) => i.value)
  //         : campaignname,
  //     }
  //   ],
  //   // sorts: [
  //   //   {
  //   //     field: 'mediaformat',
  //   //     type: 'ASC',
  //   //   },
  //   // ],
  // }).then((res) => {
  //   //  res.forEach((i,index)=>{
  //   //   i.key = index
  //   //   if(i.children){
  //   //     i.children.forEach((c,index1)=>{
  //   //       c.key = `${index}_${index1}`
  //   //     })
  //   //   }
  //   // })

  //   setData(res.map((it)=>{ return {division:it.division}   }))
  //   console.log(res)

  // })
  // }, [months,years, campaignname, campaignnameOpt,dates])

  return (
    <div className="trend-wrap">
      <Table
        scroll={{
          x: scrollX,
        }}
        columns={myColumns}
        rowKey={(record, index) => index}
        dataSource={myData}
        onChange={onTableChange}
        pagination={{
          current: page,
          onChange: onChange,
          total: myData.length,
          showTotal: (total) => {
            return `共${total}条`
          },
        }}
        summary={() => (
          <Table.Summary fixed={'top'}>
            <Table.Summary.Row>
              {myColumns.map((item, index) => {
                return (
                  <Table.Summary.Cell
                    className="myCell"
                    index={index}
                    colSpan={1}
                    key={'cell' + index}
                  >
                    {handleSumData(mySunData[item.dataIndex], item.dataIndex)}
                  </Table.Summary.Cell>
                )
              })}
            </Table.Summary.Row>
          </Table.Summary>
        )}
        sticky
      />
    </div>
  )
}

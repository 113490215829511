import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { getBudgetSum } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { formatNum } from '@/common/util'

import { getMonthTrendBarChartsOption, formatmonth } from './util'
import { set } from 'lodash-es'

export default function TrendDivisionAndYear(props) {
  const { year, color, type = 'FBP', style, unit } = props
  const trend = useRef(null)
  const { updateCharts } = useCharts(trend)

  useEffect(() => {
    getBudgetSum({
      fields: ['budget'],
      groups: ['year', 'month', 'categorycorp'],
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: [year],
        },
        {
          field: 'division',
          operator: 'IN',
          values: [type],
        },
        {
          field: 'mediachannel',
          operator: 'IN',
          values: ['Paid Search'],
        },
        {
          field: 'mediaformat',
          operator: 'IN',
          values: ['PPC', 'Brandzone'],
        },
      ],
      sorts: [
        {
          field: 'year',
          type: 'ASC',
        },
        {
          field: 'month',
          type: 'ASC',
        },
        {
          field: 'categorycorp',
          type: 'ASC',
        },
      ],
    }).then((res) => {
      const xAxisData = [...new Set(res.map((i) => formatmonth(i.month)))]
      const series = res.reduce((pre, cur) => {
        let name = cur.categorycorp
        if (!pre[name]) pre[name] = []
        pre[name].push(formatNum(cur.budgetamount, unit, 0))
        return pre
      }, {})

      updateCharts(getMonthTrendBarChartsOption({ xAxisData, series, color }, unit))
    })
  }, [year, unit])

  return (
    <div className="trend-wrap">
      <div ref={trend} style={{ width: 'calc(100%)', height: '320px' }}></div>
    </div>
  )
}

import * as echarts from 'echarts'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { getIndustrySum2, getIndustryProduct } from '../../api/home'
import { useCharts } from '@/hooks/useCharts'
import { formatNum } from '@/common/util'
import { getTrendLineOption } from './util'
import axios from 'axios'
import { Empty } from 'antd'
export default function TrendLineProd(props) {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const { title, years, product, category, division, brand, axis, media, unit } = props
  const trend = useRef(null)
  const [empty, setEmpty] = useState(false)
  const { updateCharts } = useCharts(trend)

  useEffect(() => {
    if (
      !product ||
      !brand.length ||
      !category.length ||
      !axis.length ||
      !division.length ||
      !media.length
    ) {
      return setEmpty(true)
    }
    getIndustrySum2({
      fields: ['searchindex'],
      groups: ['year', 'month'],
      title,
      tips: '第二排第二图',
      filters: [
        {
          field: 'year',
          operator: 'IN',
          values: years,
        },
        {
          field: 'product',
          operator: 'IN',
          values: [product],
        },
        {
          field: 'division',
          operator: 'IN',
          values: [division],
        },
        {
          field: 'media',
          operator: 'IN',
          values: media,
        },
        {
          field: 'subcategory',
          operator: 'IN',
          values: category,
        },
        {
          field: 'brand',
          operator: 'IN',
          values: brand,
        },
        {
          field: 'category',
          operator: 'IN',
          values: axis,
        },
        {
          field: 'tag',
          operator: 'IN',
          values: ['Product Line'],
        },
      ],
      sorts: [
        {
          field: 'year',
          type: 'ASC',
        },
        {
          field: 'month',
          type: 'DESC',
        },
      ],
    }).then((res) => {
      if (res?.length) {
        setEmpty(false)
      } else {
        return setEmpty(true)
      }
      const preyear = years[1]
      const curyear = years[0]
      const curyeardata = res
        .filter((i) => i.year === curyear)
        .map((i) => {
          i.si = formatNum(i.si, unit, 0)
          return i
        })
      curyeardata.sort((a, b) => a.month - b.month)
      const preyeardata = res
        .filter((i) => i.year === preyear)
        .map((i) => {
          i.si = formatNum(i.si, unit, 0)
          return i
        })
      preyeardata.sort((a, b) => a.month - b.month)
      // const preyeardata = _preyeardata.slice(0, curyeardata.length)
      updateCharts(getTrendLineOption({ preyeardata, curyeardata, preyear, curyear }))
    })
    return () => {
      console.log('Operation canceled by the user')
      source.cancel('Operation canceled by the user')
    }
  }, [years, product, brand, axis, media, unit])

  return (
    <div className="trend-wrap">
      <div className="title">
        <div>{title}</div>
      </div>

      {empty ? <Empty style={{ marginTop: '16px' }}></Empty> : null}
      <div
        ref={trend}
        style={{ display: empty ? 'none' : 'block', width: '100%', height: '400px' }}
      ></div>
    </div>
  )
}
